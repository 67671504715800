import { gql } from '@apollo/client';

export const CREATE_HOSPITAL_NOTICE = gql`
    mutation CreateHospitalNotice($title: String!, $text: String, $noticeAttached: [Upload!]) {
        createHospitalNotice(title: $title, text: $text, noticeAttached: $noticeAttached)
    }
`;

export const UPDATE_HOSPITAL_NOTICE = gql`
    mutation UpdateHospitalNotice(
        $hnId: Int!
        $title: String
        $text: String
        $updateAttached: [Upload!]
        $deleteAttached: [Int]
    ) {
        updateHospitalNotice(
            hn_id: $hnId
            title: $title
            text: $text
            updateAttached: $updateAttached
            deleteAttached: $deleteAttached
        )
    }
`;

export const DELETE_HOSPITAL_NOTICE = gql`
    mutation DeleteHospitalNotice($hnId: Int!) {
        deleteHospitalNotice(hn_id: $hnId)
    }
`;

export const CREATE_HOSPITAL_NOTICE_COMMENT = gql`
    mutation CreateHospitalNoticeComment($hnId: Int!, $text: String) {
        createHospitalNoticeComment(hn_id: $hnId, text: $text)
    }
`;

export const DELETE_HOSPITAL_NOTICE_COMMENT = gql`
    mutation DeleteHospitalNoticeComment($hncId: Int!) {
        deleteHospitalNoticeComment(hnc_id: $hncId)
    }
`;

export const UPDATE_HOSPITAL_NOTICE_COMMENT = gql`
    mutation UpdateHospitalNoticeComment($hncId: Int!, $text: String) {
        updateHospitalNoticeComment(hnc_id: $hncId, text: $text)
    }
`;

export const CREATE_PLATFORM_NOTICE_COMMENT = gql`
    mutation CreatePlatformNoticeComment($pnId: Int!, $text: String) {
        createPlatformNoticeComment(pn_id: $pnId, text: $text)
    }
`;

export const UPDATE_PLATFORM_NOTICE_COMMENT = gql`
    mutation UpdatePlatformNoticeComment($pncId: Int!, $text: String) {
        updatePlatformNoticeComment(pnc_id: $pncId, text: $text)
    }
`;

export const DELETE_PLATFORM_NOTICE_COMMENT = gql`
    mutation DeletePlatformNoticeComment($pncId: Int!) {
        deletePlatformNoticeComment(pnc_id: $pncId)
    }
`;

export const UPDATE_HOSPITAL_NOTICE_USER_VIEW = gql`
    mutation UpdateHospitalNoticeUserView($pnId: Int!) {
        updateHospitalNoticeUserView(pn_id: $pnId)
    }
#    mutation UpdateHospitalNoticeUserView($updateHospitalNoticeUserViewPnId2: Int!) {
#        updateHospitalNoticeUserView(pn_id: $updateHospitalNoticeUserViewPnId2)
#    }
`;
