import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import theme from "../../../../styles/theme";
import StyledSelect from "../../../styled/StyledSelect";
import {MonitorSetupContext} from "./MonitorSetupModal";
import {RegularText} from "../../../styled/StyledText";
import StyledInput from "../../../styled/StyledInput";
import StyledButton from "../../../styled/StyledButton";
import StyledCheckbox from "../../../styled/StyledCheckbox";
import StyledFlexBox from "../../../styled/StyledFlexBox";

const Wrapper = styled.div`
  display: flex;
`;
const Section = styled.div`
  flex: 1;
`;
const Line = styled.div`
  position: relative;
  margin: 0 40px;

  &:after {
    content: '';
    width: 1px;
    height: calc(100% + 190px);
    top: -148px;
    position: absolute;
    background-color: ${theme.colors.ultraLightGrayBorder};
  }
`;
const BlueText = styled(RegularText)`
  font-size: 12px;
  color: ${theme.colors.activeBlue};
  margin: 20px 0;
`;
const ClinicList = styled.div`
  height: 354px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  overflow-y: scroll;
`;
const ClinicItem = styled.div`
  display: flex;
  align-items: center;
`;
const ClinicView = styled.div`
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px 0 1px;
`;

const ClinicSetupForm = ({ selectedData, handleSave }) => {
    const {options, options: {clinicState}, setOptions} = useContext(MonitorSetupContext);
    const [clinicNumberOption, setClinicNumberOption] = useState([]);

    // 진료실 통합보기
    const onChangeSelect = useCallback(e => {
        const { name, value } = e.target;
        setOptions({
            ...options,
            clinicState: {
                ...clinicState,
                [name]: value
            }
        });
    }, [options, clinicState]);

    // 각 진료실 변경
    const onChange = useCallback((e, index) => {
        const { name, value, checked } = e.target;
        let tmpClinicRoom;

        if (name === 'ddr_viewSelect') { // Checkbox 변경
            tmpClinicRoom = clinicState.clinicRoom.map((room, i) => index === i
                ? {...room, [name]: checked} : room);
        } else { // Select 변경
            tmpClinicRoom = clinicState.clinicRoom.map((room, i) => index === i
                ? {...room, [name]: value} : room);
        }

        setOptions({
            ...options,
            clinicState: {
                ...clinicState,
                clinicRoom: tmpClinicRoom
            }
        });
    }, [options, clinicState]);

    useEffect(() => {
        let tmpClinicNumberOption = [];

        selectedData?.didDoctorRoom.forEach((list, index) => {
            tmpClinicNumberOption.push(`${index + 1} 순위`);
        });

        setClinicNumberOption(tmpClinicNumberOption);
    }, [selectedData]);

    return (
        <Wrapper>
            <Section>
                <StyledSelect
                    title='진료실 통합보기'
                    width={440}
                    name='clinicIntegratedView'
                    options={['미사용', '사용']}
                    value={clinicState.clinicIntegratedView}
                    onChange={onChangeSelect}
                    // onChange={e => onChange(e, 'clinicState')}
                />
                <BlueText>설정 - 사용자관리에서 진료실 정보를 입력하시면 자동으로 생성됩니다.</BlueText>
                <ClinicList>
                    {clinicState?.clinicRoom.length > 0 &&
                    clinicState?.clinicRoom?.map((list, index) => (
                        <ClinicItem key={list.ddr_id}>
                            <ClinicView>
                                <RegularText>보기</RegularText>
                                <StyledCheckbox
                                    margin='22px 0 0 2px'
                                    id={`${index}-exposure`}
                                    name='ddr_viewSelect'
                                    checked={list.ddr_viewSelect}
                                    onChange={e => onChange(e, index)}
                                />
                            </ClinicView>

                            <StyledInput
                                width={190}
                                title='진료실 정보'
                                value={list.ddr_info}
                                disabled
                            />

                            <StyledSelect
                                width={92}
                                margin='0 20px'
                                title='표기순위'
                                name='ddr_number'
                                value={clinicState?.clinicRoom[index]?.ddr_number}
                                onChange={e => onChange(e, index)}
                                options={clinicNumberOption}
                            />
                            <StyledSelect
                                width={92}
                                title='휴진'
                                name='ddr_dayOff'
                                value={clinicState?.clinicRoom[index]?.ddr_dayOff}
                                onChange={e => onChange(e, index)}
                                options={['X', 'O']}
                            />
                        </ClinicItem>
                    ))}
                </ClinicList>
                <StyledButton
                    width={440}
                    title='저장하기'
                    margin='60px 0 0'
                    onClick={handleSave}
                />
            </Section>
        </Wrapper>
    )
}

export default React.memo(ClinicSetupForm);
