import React, {useCallback, useContext, useEffect, useState} from "react";
import {IoInformationCircleOutline, IoReaderOutline} from 'react-icons/io5';
import RightSlideModal from "../../layout/RightSlideModal";
import {AppointmentContext} from "../../../pages/Appointment/appointment";
import {ActiveBackground, Contents, TabBox, TabButton} from "./appointment.style";
import InfoBox from "./InfoBox";
import MemoBox from "./MemoBox";
import {useQuery} from "@apollo/client";
import {SEE_RESERVATION_PATIENT_DETAIL} from "../../../graphql/Appointment/query";
import Loader from "../../share/Loader";

const AppointmentDetailModal = ({ detailModal }) => {
    const { selectedId, setSelectedId, setDetailModal } = useContext(AppointmentContext);
    const [tab, setTab] = useState('info');
    const [memoList, setMemoList] = useState([]);

    const { data, loading } = useQuery(SEE_RESERVATION_PATIENT_DETAIL, {
        variables: {
            reId: selectedId
        }
    });

    const handleClose = useCallback(() => { // 상세보기 모달 닫기
        setDetailModal(false);
        setTab('info');
        setSelectedId(null);
    }, []);

    useEffect(() => {
        if (!loading && data) {
            const tmpMemoList = data?.seeReservationPatientDetail?.patientMemoList.map(memo => {
                if (!memo) return [];

                return {
                    commentId: memo?.prm_id,
                    creatorId: memo?.prm_creatorId,
                    createDate: memo?.prm_createdAt,
                    creator: memo?.prm_creatorName,
                    creatorRank: memo?.prm_creatorRank,
                    creatorImage: memo?.prm_creatorImg,
                    comment: memo?.prm_text,
                }
            });
            setMemoList(tmpMemoList);
        }
    }, [loading, data]);

    if (loading) return  <Loader />;

    return (
        <RightSlideModal
            title='예약자 상세정보'
            visible={detailModal}
            onClose={handleClose}>
            <Contents>
                <TabBox>
                    <ActiveBackground $active={tab} />
                    <TabButton $active={tab === 'info'} onClick={() => setTab('info')}>
                        <IoInformationCircleOutline fontSize={17} style={{ marginRight: 6 }} />
                        예약 정보
                    </TabButton>
                    <TabButton $active={tab === 'memo'} onClick={() => setTab('memo')}>
                        <IoReaderOutline style={{ marginRight: 6 }} />
                        메모
                    </TabButton>
                </TabBox>

                {tab === 'info' && (
                    <InfoBox
                        data={data?.seeReservationPatientDetail?.reservationInfo}
                        historyList={data?.seeReservationPatientDetail?.resHistoryList}
                        handleClose={handleClose}
                    />
                )}
                {tab === 'memo' && (
                    <MemoBox
                        memoList={memoList}
                    />
                )}
            </Contents>
        </RightSlideModal>
    )
}

export default AppointmentDetailModal;
