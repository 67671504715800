import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {IoClose} from 'react-icons/io5';
import theme from '../../styles/theme';
import {BoldText} from "../styled/StyledText";
import ConfirmModal from "../share/ConfirmModal";

const Wrapper = styled.div`
  width: calc(100vw - 10px);
  height: 100vh;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -100;
  background: rgba(256, 256, 256, 0.6);
  transition: .25s;
  opacity: 0;
  visibility: hidden;

  ${({$visible}) => $visible && css`
    opacity: 1;
    z-index: 20;
    visibility: visible;
  `};
`;
const ModalContents = styled.div`
  width: ${({$width}) => $width}px;
  //min-height: ${({$height}) => $height}px;
  height: ${({$height}) => $height}px;
  padding: 40px;
  border-radius: 20px;
  position: relative;
  background-color: ${theme.colors.whiteColor};
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  transition: .2s;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 100px auto 0;
  
  ${({ $vhHeight }) => $vhHeight && css`
    min-height: ${$vhHeight};
    max-height: ${({$height}) => $height}px;
  `};
`;
const Title = styled(BoldText)``;
const ModalHead = styled.div`
  height: 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const CloseButton = styled.button`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-radius: 8px;

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }
`;

const ModalBackground = ({
                             CONFIRM = false,
                             width = 520,
                             height = 300,
                             vhHeight,
                             title,
                             visible,
                             onClose,
                             children
                         }) => {
    const bgRef = useRef(null);
    const modalRef = useRef(null);
    const confirmModalRef = useRef(null);
    const [confirmModal, setConfirmModal] = useState(false);

    // 모달 밖 클릭 시 닫기
    const handleClickOutside = useCallback(e => {
        if (CONFIRM) {
            if (
                visible &&
                bgRef?.current?.contains(e.target) &&
                !modalRef?.current?.contains(e.target) &&
                !confirmModalRef?.current
            ) {
                setConfirmModal(prev => !prev);
            }
        } else {
            if (
                visible &&
                bgRef?.current?.contains(e.target) &&
                !modalRef?.current?.contains(e.target)
            ) {
                onClose();
            }
        }
    }, [visible, CONFIRM, onClose, bgRef, modalRef, confirmModalRef]);

    // 작성 취소하기
    const handleCancel = useCallback(() => {
        setConfirmModal(prev => !prev);
        onClose();
    }, [onClose]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside, CONFIRM]);

    return (
        <Wrapper ref={bgRef} $visible={visible}>
            <ModalContents $width={width} $height={height} $vhHeight={vhHeight} ref={modalRef}>
                <ModalHead>
                    <Title>{title}</Title>
                    <CloseButton onClick={onClose}>
                        <IoClose fontSize={24} />
                    </CloseButton>
                </ModalHead>
                {children}
            </ModalContents>

            {confirmModal && (
                <ConfirmModal
                    ref={confirmModalRef}
                    visible={confirmModal}
                    handleConfirm={handleCancel}
                    handleCancel={() => setConfirmModal(prev => !prev)}
                />
            )}
        </Wrapper>
    );
};

export default React.memo(ModalBackground);
