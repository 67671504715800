import React, {useCallback, useEffect, useState} from "react";
import RightSlideModal from "../../layout/RightSlideModal";
import {ButtonBox, InputForm, ModalContents, Title} from "./profile.style";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {userVar} from "../../../store";
import StyledSelect from "../../styled/StyledSelect";
import StyledInput from "../../styled/StyledInput";
import StyledButton from "../../styled/StyledButton";
import theme from "../../../styles/theme";
import {autoHyphen, errorMessage} from "../../../utils/commons";
import {UPDATE_MY_EMPLOYEE_INFO} from "../../../graphql/Profile/mutation";
import {toast} from "react-toastify";
import {SEE_ORG} from "../../../graphql/Setting/query";

const HRInfoModal = ({
                         hrInfoModal,
                         setHrInfoModal,
                         refetch
                     }) => {
    const user = useReactiveVar(userVar);
    const [inputs, setInputs] = useState({
        org: '',
        job: '',
    });
    const [orgOption, setOrgOption] = useState([]);

    const { data, loading } = useQuery(SEE_ORG);
    const [updateMyEmployeeInfo] = useMutation(UPDATE_MY_EMPLOYEE_INFO);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const handleClose = useCallback(() => {
        setHrInfoModal(false);
        setInputs({
            org: '',
            job: ''
        });
    }, []);

    const handleSave = useCallback(async () => {
        try {
            const {data} = await updateMyEmployeeInfo({
                variables: {
                    userOrg: inputs.org,
                    userJob: inputs.job
                }
            });

            if (data.updateMyEmployeeInfo) {
                toast.info('인사정보를 수정했습니다.');
                await refetch();
                handleClose();
            }

        } catch (e) {
            errorMessage(e.message);
        }
    }, [inputs]);

    useEffect(() => {
        if (!loading && data) {
            const tmpOrg = data?.seeOrg?.orgList.map(org => org.org_name);
            setOrgOption(tmpOrg)
        }
    }, [data]);

    useEffect(() => {
        setInputs({
            org: user.user_org,
            job: user.user_job,
        });
    }, [user]);

    return (
        <RightSlideModal
            title='정보 변경'
            visible={hrInfoModal}
            onClose={handleClose}>
            <ModalContents>
                <InputForm>
                    <Title>인사 정보</Title>

                    <StyledSelect
                        ROW
                        title='조직'
                        width={440}
                        titleWidth={136}
                        height={38}
                        borderRadius={4}
                        name='org'
                        value={inputs.org}
                        onChange={onChange}
                        options={orgOption}
                    />

                    <StyledInput
                        ROW
                        title='직무'
                        margin='12px 0'
                        height={38}
                        name='job'
                        value={inputs.job}
                        onChange={onChange}
                        placeholder='담당 업무를 입력해주세요.'
                    />
                </InputForm>
                <ButtonBox>
                    <StyledButton
                        PENCIL
                        width={214}
                        title='저장하기'
                        onClick={handleSave}
                    />
                    <StyledButton
                        CLOSE
                        title='닫기'
                        width={214}
                        fontColor={theme.colors.blackColor}
                        border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                        bgColor={theme.colors.whiteColor}
                        onClick={handleClose}
                    />
                </ButtonBox>
            </ModalContents>
        </RightSlideModal>
    );
}

export default HRInfoModal;
