import React, {useRef, useState, useEffect, useCallback, useMemo} from 'react';
import styled, {css} from 'styled-components';
import theme from '../../styles/theme';
import pageArrow from '../../assets/pageNation/pageArrow.svg';
import pageDoubleArrow from '../../assets/pageNation/pageDoubleArrow.svg';
import takeListIcon from '../../assets/pageNation/takeListIcon.svg';
import selectPageArrow from '../../assets/pageNation/selectPageArrow.svg';
import selectFillArrow from '../../assets/pageNation/selectFillArrow.svg';

const Wrapper = styled.div`
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  margin: 26px 0 40px;
`;
const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
const NumberTitle = styled.span`
  color: ${theme.colors.fontGrayColor};
  font-family: 'AppleSDGothicNeoBold';
  margin-right: 12px;
`;
const Number = styled.div`
  min-width: 86px;
  font-weight: bold;
  font-family: 'AppleSDGothicNeoBold';
`;
const ArrowBox = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.lightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }

  &:active {
    opacity: 0.7;
  }
`;
const Text = styled.span`
  font-size: 16px;
  font-weight: ${({$fontWeight}) => $fontWeight ? $fontWeight : 600};
  font-family: 'AppleSDGothicNeoBold';
  color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
const PageWrapper = styled.div`
  width: 60px;
  position: relative;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
const SelectBox = styled.div`
  height: 32px;
  color: ${theme.colors.fontGrayColor};
  font-family: 'AppleSDGothicNeoBold';
  display: flex;
  padding: 2px 8px 0 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${theme.colors.lightGrayBorder};
  background-color: ${theme.colors.whiteColor};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }

  &:active {
    opacity: 0.5;
  }
`;
const OptionBox = styled.div`
  width: 100%;
  max-height: 380px;
  padding: 10px 0;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 31px;
  overflow-y: scroll;
  border: 1px solid ${theme.colors.lightGrayBorder};
  background-color: ${theme.colors.whiteColor};
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;
const OptionText = styled.div`
  color: ${theme.colors.fontGrayColor};
  padding: 12px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.blackColor};
  }
`;
const Image = styled.img`
  width: ${({$width}) => $width ? $width : 15}px;
  height: ${({$height}) => $height ? $height : 15}px;
  margin: ${({$margin}) => $margin ? $margin : 0};
  ${({$rotated}) => $rotated && css`
    transform: rotate(180deg);
  `};
`;

const PageNation = ({
                        totalLength = 1,
                        take = 15,
                        page = 1,
                        onClickPage,
                        setTake,
                        takeOption = [15, 30, 45]
                    }) => {
    const [optionVisible, setOptionVisible] = useState(false);
    const [takeVisible, setTakeVisible] = useState(false);
    const [boxHeight, setBoxHeight] = useState(0);
    const [options, setOptions] = useState([]);
    const pageSelectRef = useRef(null);
    const pageOptionRef = useRef(null);
    const takeSelectRef = useRef(null);
    const takeOptionRef = useRef(null);

    const lastPage = Math.ceil(totalLength / take);

    const clickOption = useCallback(page => {
        onClickPage(page);
        setOptionVisible(false);
    }, []);

    const calcCurrentNumber = useMemo(() => {
        switch (page) {
            case 1:
                return 1;
            default:
                return page * take - take;
        }
    }, [page, take]);

    const onClickTakeOption = useCallback(take => {
        setTake(take);
        setTakeVisible(false);
    }, [setTake]);

    const handleClickOutside = useCallback(({target}) => {
        let pageSelectRefCurrent = pageSelectRef.current && pageSelectRef.current.contains(target);
        let pageOptionRefCurrent = pageOptionRef.current && pageOptionRef.current.contains(target);
        let takeSelectRefCurrent = takeSelectRef.current && takeSelectRef.current.contains(target);
        let takeOptionRefCurrent = takeOptionRef.current && takeOptionRef.current.contains(target);

        if (!pageSelectRefCurrent && !pageOptionRefCurrent) setOptionVisible(false);
        if (!takeSelectRefCurrent && !takeOptionRefCurrent) setTakeVisible(false);
    }, [pageSelectRef, pageOptionRef, takeSelectRef, takeOptionRef]);

    useEffect(() => {
        let option = [];
        if (totalLength !== 0) {
            for (let i = 1; i <= lastPage; i++) {
                option.push(i);
            }
            setOptions(option);
        } else {
            option.push(1);
        }
        setBoxHeight(options.length * 50);
    }, [lastPage, options.length, totalLength]);

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => window.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    return (
        <Wrapper>
            <FlexBox>
                <NumberTitle>번호</NumberTitle>
                <Number>
                    {`${calcCurrentNumber} - ${take * page} of ${totalLength}`}
                </Number>
                <FlexBox>
                    <Image src={takeListIcon} $margin="0 12px"/>
                    <PageWrapper>
                        <SelectBox
                            onClick={() => setTakeVisible(!takeVisible)}
                            ref={takeSelectRef}>
                            {take}
                            <Image src={selectFillArrow} $width={8} $height={6}/>
                            {takeVisible && (
                                <OptionBox ref={takeOptionRef}>
                                    {takeOption.map(take => (
                                        <OptionText
                                            key={take}
                                            onClick={() => onClickTakeOption(take)}>
                                            {take}
                                        </OptionText>
                                    ))}
                                </OptionBox>
                            )}
                        </SelectBox>
                    </PageWrapper>
                </FlexBox>
            </FlexBox>

            <FlexBox>
                <ArrowBox onClick={() => onClickPage(1)}>
                    <Image src={pageDoubleArrow}/>
                </ArrowBox>
                <ArrowBox
                    onClick={() => (page > 1 ? onClickPage(page - 1) : null)}>
                    <Image src={pageArrow}/>
                </ArrowBox>
                <Text $fontColor={theme.colors.fontLightGrayColor} $fontWeight={500} $margin="0 10px">
                    페이지
                </Text>
                <PageWrapper $margin="0 12px 0 0">
                    <SelectBox onClick={() => setOptionVisible(!optionVisible)} ref={pageSelectRef}>
                        {page}
                        <Image src={selectPageArrow} $width={10} $height={10}/>
                    </SelectBox>
                    {optionVisible && options.length !== 0 && (
                        <OptionBox boxHeight={boxHeight} ref={pageOptionRef}>
                            {options.map(page => (
                                <OptionText
                                    key={`option-${page}`}
                                    onClick={() => clickOption(page)}>
                                    {page}
                                </OptionText>
                            ))}
                        </OptionBox>
                    )}
                </PageWrapper>
                <ArrowBox
                    onClick={() => (lastPage > 1) && (page < lastPage)
                        ? onClickPage(page + 1)
                        : null
                    }>
                    <Image src={pageArrow} $rotated/>
                </ArrowBox>
                <ArrowBox onClick={() => (lastPage > 1 ? onClickPage(lastPage) : null)}>
                    <Image src={pageDoubleArrow} $rotated/>
                </ArrowBox>
            </FlexBox>
        </Wrapper>
    );
};

export default React.memo(PageNation);
