import React, {useCallback, useEffect, useRef, useState} from "react";
import RightSlideModal from "../../../layout/RightSlideModal";
import {errorMessage, handleBodyScroll} from "../../../../utils/commons";
import {useLazyQuery, useMutation} from "@apollo/client";
import {SEE_ONE_ON_ONE_DETAIL} from "../../../../graphql/Service/query";
import Loader from "../../../share/Loader";
import {toast} from "react-toastify";
import {
    CREATE_ONE_ON_ONE_COMMENT,
    DELETE_ONE_ON_ONE, DELETE_ONE_ON_ONE_COMMENT,
    UPDATE_ONE_ON_ONE_COMMENT
} from "../../../../graphql/Service/mutation";
import {
    GrayText,
    Image, InlineEmphasisText, Line, PreviewList,
    QnaContentsBox, QnaSection,
    QuestionContents,
    QuestionInfo, QuestionText, QuestionTitle,
    UserImageBox,
    UserInfo
} from "../service.style";
import CommentList from "../../Home/Notice/CommentList";
import noUser from "../../../../assets/images/noUser.png";
import dayjs from "dayjs";
import PreviewFile from "../../../share/PreviewFile";

const QnaDetailModal = ({
                            selectedId,
                            setSelectedId,
                            qnaDetailModal,
                            setQnaDetailModal,
                            qnaListRefetch
                        }) => {
    const scrollRef = useRef();
    const inputRef = useRef();
    const [input, setInput] = useState('');
    const [questionData, setQuestionData] = useState({ // 질문
        questionId: null,
        creatorId: null,
        creatorName: '',
        creatorRank: '',
        creatorImage: '',
        createdAt: new Date(),
        isAnswered: false,
        title: '',
        contents: '',
        isOpen: false
    });
    const [commentId, setCommentId] = useState(null);
    const [commentList, setCommentList] = useState([]);
    const [attachedFile, setAttachedFile] = useState([]);

    const [seeOneOnOneDetail, {data, loading, refetch}] = useLazyQuery(SEE_ONE_ON_ONE_DETAIL);
    const [deleteOneOnOne] = useMutation(DELETE_ONE_ON_ONE);
    const [createOneOnOneComment] = useMutation(CREATE_ONE_ON_ONE_COMMENT);
    const [updateOneOnOneComment] = useMutation(UPDATE_ONE_ON_ONE_COMMENT);
    const [deleteOneOnOneComment] = useMutation(DELETE_ONE_ON_ONE_COMMENT);

    const handleClose = useCallback(() => {
        setQnaDetailModal(false);
        setSelectedId(null);
        setQuestionData({
            questionId: null,
            creatorId: null,
            creatorName: '',
            creatorRank: '',
            creatorImage: '',
            createdAt: new Date(),
            isAnswered: false,
            title: '',
            contents: '',
            isOpen: false
        });

        handleBodyScroll('initial');
    }, []);

    const handleDeleteQna = useCallback(async () => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const {data} = await deleteOneOnOne({
                variables: {
                    oneqId: selectedId
                }
            });

            if (data.deleteOneOnOne) {
                await qnaListRefetch();
                toast.info('일대일 문의를 삭제했습니다.');
                handleClose();
            }
        } catch (e) {
            switch (e.message) {
                case 'err_01':
                    toast.error('작성자만 삭제가 가능합니다.');
                    break;
            }
        }
    }, [selectedId]);

    const handleSaveComment = useCallback(async () => {
        try {
            const {data} = await createOneOnOneComment({
                variables: {
                    oneqId: selectedId,
                    text: input
                }
            });

            if (data.createOneOnOneComment) {
                toast.info('댓글을 등록했습니다.');
                setInput('');
                setTimeout(() => {
                    scrollRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                    });
                }, 100);
                await refetch();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [selectedId, input, scrollRef]);

    const onInputKeyPress = useCallback(async e => { // input keypress
        if (e.key === 'Enter') {
            if (input === '') {
                toast.error('댓글을 입력해주세요.');
                return;
            }

            if (commentId) {
                await handleUpdateComment();
            } else {
                await handleSaveComment();
            }
        }
    }, [input, commentId]);

    const handleUpdateComment = useCallback(async () => { // 댓글 수정 api 호출
        try {
            const { data } = await updateOneOnOneComment({
                variables: {
                    oneAnId: commentId,
                    oneAnText: input,
                }
            });

            if (data?.updateOneOnOneComment) {
                await refetch();
                setCommentId(null);
                setInput('');
                toast.info('댓글을 수정했습니다.');
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [input, commentId]);

    const handleModifyComment = useCallback( id => { // 댓글 수정하기 버튼 클릭
        const findComment = commentList.find(list => list.commentId === id);
        if (findComment) {
            setInput(findComment.comment);
            setCommentId(id);
            inputRef?.current?.focus();
            return;
        }
        toast.error('수정할 수 없는 댓글입니다.');
    }, [commentList, inputRef]);

    const handleDeleteComment = useCallback(async id => { // 댓글 삭제
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const {data} = await deleteOneOnOneComment({
                variables: {
                    oneAnId: id
                }
            });

            if (data?.deleteOneOnOneComment) {
                toast.info('댓글을 삭제했습니다.');
                await refetch();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (selectedId) {
                try {
                    await seeOneOnOneDetail({
                        variables: {
                            oneqId: selectedId
                        }
                    });
                } catch (e) {
                    errorMessage(e.message);
                }
            }
        })();
    }, [selectedId]);

    useEffect(() => {
        if (qnaDetailModal && !loading && data) {
            const tmpData = data?.seeOneOnOneDetail;
            const tmpFileList = data?.seeOneOnOneDetail?.oneOnOneAttached?.map(file => {
                if (!file) return [];
                const splitUrl = file?.oneAt_url?.split('/');
                let fileName = splitUrl[splitUrl.length - 1];
                fileName = fileName.slice(14, fileName.length);

                return {
                    name: fileName || '',
                    url: file?.oneAt_url,
                    size: file?.oneAt_fileSize,
                    type: file?.oneAt_fileType
                }
            });
            const tmpComments = data?.seeOneOnOneDetail?.oneOnOneAnswer?.map(comment => {
                if (!comment) return [];

                if (comment.oneAn_adminAble) { // 관리자 답변
                    return {
                        commentId: comment.oneAn_id || 0,
                        creatorId: comment.oneAn_creatorId || null,
                        createDate: comment.oneAn_createdAt || new Date(),
                        creator: comment.oneAn_adminCreatorName || '',
                        creatorRank: comment.oneAn_adminCreatorRank || '',
                        creatorImage: comment.logo || '',
                        comment: comment.oneAn_answer || ''
                    }
                }

                return {
                    commentId: comment.oneAn_id || 0,
                    creatorId: comment.oneAn_creatorId || null,
                    createDate: comment.oneAn_createdAt || new Date(),
                    creator: comment.oneAn_creatorName || '',
                    creatorRank: comment.oneAn_creatorRank || '',
                    creatorImage: comment.oneAn_creatorImg || '',
                    comment: comment.oneAn_answer || ''
                }
            });
            setQuestionData({
                questionId: tmpData?.oneq_id,
                creatorId: tmpData?.oneq_creatorId,
                creatorName: tmpData?.oneq_creatorName,
                creatorRank: tmpData?.oneq_creatorRank,
                creatorImage: tmpData?.oneq_creatorImg,
                createdAt: tmpData?.oneq_createdAt,
                isAnswered: tmpData?.oneq_status,
                title: tmpData?.oneq_title,
                contents: tmpData?.oneq_text,
                isOpen: tmpData?.oneq_publicPrivate,
            });

            setAttachedFile(tmpFileList);
            setCommentList(tmpComments);
        }
    }, [loading, data, qnaDetailModal]);

    return (
        <RightSlideModal
            MENU
            title="일대일 문의 내용"
            visible={qnaDetailModal}
            onClose={handleClose}
            handleDeleteComment={handleDeleteQna}>
            <QnaContentsBox>
                {loading
                    ? <Loader/>
                    : (
                        <>
                            <QnaSection>
                                <QuestionInfo>
                                    <UserImageBox>
                                        <Image
                                            src={questionData.creatorImage}
                                            onError={e => e.target.src = noUser}
                                        />
                                    </UserImageBox>
                                    <QuestionContents>
                                        <UserInfo>
                                            <InlineEmphasisText>
                                                {questionData.creatorName}&nbsp;
                                                {questionData.creatorRank}
                                            </InlineEmphasisText>
                                            <GrayText $margin='0 0 0 10px'>
                                                {dayjs(questionData.createdAt).format('YYYY-MM-DD HH:mm')}
                                            </GrayText>
                                        </UserInfo>
                                        <QuestionText>
                                            <QuestionTitle>{questionData.title}</QuestionTitle>
                                            {questionData.contents}
                                        </QuestionText>

                                        {attachedFile.length > 0 && (
                                            <>
                                                <GrayText $margin='30px 0 12px'>{attachedFile.length}개의 첨부파일</GrayText>
                                                <PreviewList>
                                                    {attachedFile.map((file, index) => (
                                                        <PreviewFile
                                                            DOWNLOAD
                                                            key={`${index}-file-list`}
                                                            file={file}
                                                        />
                                                    ))}
                                                </PreviewList>
                                            </>
                                        )}
                                    </QuestionContents>
                                </QuestionInfo>
                            </QnaSection>
                            <Line />
                            <CommentList
                                input={input}
                                setInput={setInput}
                                onInputKeyPress={onInputKeyPress}
                                commentList={commentList}
                                handleSaveComment={commentId ? handleUpdateComment : handleSaveComment}
                                handleDeleteComment={handleDeleteComment}
                                handleModifyComment={handleModifyComment}
                                ref={{scrollRef, inputRef}}
                            />
                        </>
                    )}
            </QnaContentsBox>
        </RightSlideModal>
    )
}

export default QnaDetailModal;
