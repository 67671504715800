import theme from "../../../styles/theme";

export const appointmentStateChange = state => {
    let color;
    let text;

    switch (state) {
        case 'waiting':
            color = theme.colors.activeLightOrange;
            text = '대기';
            break;
        case 'complete':
            color = theme.colors.activeGreen;
            text = '완료';
            break;
        case 'confirm':
            color = theme.colors.activeBlue;
            text = '확정';
            break;
        case 'cancel':
            color = theme.colors.activeRed;
            text = '취소';
            break;
    }

    return {
        color,
        text
    }
}

export const scheduleStateChange = state => {
    switch (state) {
        case 'off':
            return '휴무';
        case 'work':
            return '근무';
        case 'subDoctor':
            return '대체';

    }
}
