import { gql } from '@apollo/client';

export const SEE_FAQ = gql`
    query SeeFAQ {
        seeFAQ {
            totalLength
            faqList {
                faq_id
                faq_createdAt
                faq_creatorName
                faq_creatorRank
                faq_creatorId
                faq_adminEditorName
                faq_adminEditorRank
                faq_adminEditorId
                faq_question
                faq_answer
                faq_viewCount
                faq_likeCount
            }
        }
    }
`;

export const SEE_QUESTION_SOLVED_RATIO = gql`
    query SeeQuestionSolvedRatio {
        seeQuestionSolvedRatio {
            solvedRatio
            solvedQuestion
            registQuestion
        }
    }
`;

export const SEE_ER_NOTICE_CHECK_RATIO = gql`
    query Query {
        seeErNoticeCheckRatio
    }
`;

export const SEE_HIGH_VIEW_QUESTION = gql`
    query SeeHighViewQuestion {
        seeHighViewQuestion {
            faq_id
            faq_question
            faq_answer
        }
    }
`;

export const SEE_RECENTLY_QUESTION = gql`
    query SeeRecentlyQuestion {
        seeRecentlyQuestion {
            faq_id
            faq_question
            faq_answer
        }
    }
`;

export const SEE_HIGH_COMMENT_QUESTION = gql`
    query SeeHighCommentQuestion {
        seeHighCommentQuestion {
            faq_id
            faq_question
            faq_answer
        }
    }
`;

export const SEE_ONE_ON_ONE_LIST = gql`
    query SeeOneOnOneList(
        $answerStatus: String
        $orderBy: String
        $take: Int
        $cursor: Int
    ) {
        seeOneOnOneList(
            answerStatus: $answerStatus
            orderBy: $orderBy
            take: $take
            cursor: $cursor
        ) {
            inquireList {
                oneq_id
                oneq_createdAt
                oneq_creatorName
                oneq_creatorRank
                oneq_creatorId
                oneq_title
                oneq_status
                oneq_creatorImg
            }
            totalLength
        }
    }
`;

export const SEE_FAQ_DETAIL = gql`
    query SeeFAQDetail($faqId: Int!) {
        seeFAQDetail(faq_id: $faqId) {
            faq_id
            faq_createdAt
            faq_creatorName
            faq_creatorRank
            faq_creatorId
            faq_adminEditorName
            faq_adminEditorRank
            faq_adminEditorId
            faq_question
            faq_answer
            faq_viewCount
            faq_likeCount
            faq_myLikeStatus
        }
    }
`;

export const SEE_ONE_ON_ONE_DETAIL = gql`
    query SeeOneOnOneDetail($oneqId: Int!) {
        seeOneOnOneDetail(oneq_id: $oneqId) {
            oneq_id
            oneq_createdAt
            oneq_creatorName
            oneq_creatorImg
            oneq_creatorRank
            oneq_creatorId
            oneq_title
            oneq_text
            oneq_status
            oneq_publicPrivate
            oneOnOneAttached {
                oneAt_id
                oneAt_url
                oneAt_fileType
                oneAt_fileSize
            }
            oneOnOneAnswer {
                oneAn_id
                oneAn_createdAt
                oneAn_adminAble
                oneAn_creatorName
                oneAn_creatorRank
                oneAn_creatorId
                oneAn_adminCreatorName
                oneAn_adminCreatorRank
                oneAn_adminCreatorId
                oneAn_answer
                oneAn_creatorImg
            }
        }
    }
`;

export const SEE_PLATFORM_NOTICE_CS = gql`
    query SeePlatformNoticeCs(
        $filter: platformNoticeType
        $searchTerm: String
        $orderby: String
    ) {
        seePlatformNoticeCs(
            filter: $filter
            searchTerm: $searchTerm
            orderby: $orderby
        ) {
            totalLength
            platformNoticeList {
                pn_id
                pn_createdAt
                pn_updatedAt
                pn_type
                pn_title
                pn_text
                pn_adminCreatorName
                pn_adminCreatorRank
                pn_adminCreatorId
                pnAttached {
                    pna_id
                    pna_url
                    pna_fileType
                    pna_fileSize
                }
                pnComment {
                    pnc_id
                    pnc_text
                    pnc_createdAt
                    pnc_updatedAt
                    pnc_creatorName
                    pnc_creatorRank
                    pnc_creatorId
                    pnc_creatorImg
                    pnc_admin
                }
            }
        }
    }
`;
