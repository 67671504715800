import React, {createContext, useCallback, useEffect, useRef, useState} from "react";
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import dayjs from "dayjs";
import PageHeader from '../../../components/layout/PageHeader';
import {NoticeWrapper, FilterBox} from './notice.styles';
import StyledFlexBox from '../../../components/styled/StyledFlexBox';
import {GrayText, SelectBox} from "../../DID/did.styles";
import {getYears} from './notice.lib';

export const NoticeContext = createContext(null);

const Notice = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [year, setYear] = useState(dayjs().get('year'));
    const [activeTab, setActiveTab] = useState('사내 공지');
    const [noticeModal, setNoticeModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);

    const handleTabClick = useCallback(type => {
        switch (type) {
            case '사내 공지':
                navigate('/home/notice/hospital');
                break;
            case '플랫폼 공지':
                navigate('/home/notice/platform');
                break;
        }
        setActiveTab(type);
    }, []);

    useEffect(() => {
        switch (location.pathname) {
            case '/home/notice/hospital':
                setActiveTab('사내 공지');
                break;
            case '/home/notice/platform':
                setActiveTab('플랫폼 공지');
                break;
        }
    }, [location]);

    return (
        <NoticeContext.Provider value={{
            year,
            noticeModal,
            setNoticeModal,
            confirmModal,
            setConfirmModal
        }}>
            <NoticeWrapper>
                <PageHeader
                    HOME_NOTICE
                    TAB_BUTTON
                    guideLine='홈 > 공지사항'
                    tabButton={['사내 공지', '플랫폼 공지']}
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                    setConfirmModal={setConfirmModal}
                    onClick={() => setNoticeModal(true)}>
                    <FilterBox>
                        <StyledFlexBox>
                            <GrayText>정렬 :</GrayText>
                            <SelectBox
                                value={year}
                                onChange={e => setYear(e.target.value)}>
                                {getYears().map((option, index) => (
                                    <option key={`${index}-${option}`} value={option}>{option}</option>
                                ))}
                            </SelectBox>
                        </StyledFlexBox>
                    </FilterBox>
                </PageHeader>
                <Outlet />
            </NoticeWrapper>
        </NoticeContext.Provider>
    );
}

export default Notice;
