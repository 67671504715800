import React from 'react';
import styled from 'styled-components';
import {darken, lighten} from 'polished';
import theme from '../../styles/theme';
import {IoClose, IoDesktopOutline, IoSend, IoPersonOutline} from "react-icons/io5";
import {BiPencil} from "react-icons/bi";

const Button = styled.button`
  width: ${({$width}) => $width ? `${$width}px` : 'auto'};
  height: ${({$height}) => $height}px;
  font-size: ${({$fontSize}) => $fontSize}px;
  font-family: ${({$fontFamily}) => $fontFamily};
  font-weight: ${({$fontWeight}) => $fontWeight};
  color: ${({$fontColor}) => $fontColor};
  padding: 10px 16px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({$border}) => $border};
  border-radius: ${({$borderRadius}) => $borderRadius}px;
  margin: ${({$margin}) => $margin};
  background-color: ${({$bgColor}) => $bgColor};
  cursor: ${({$cursor}) => $cursor};
  transition: .15s;

  &:hover {
    background-color: ${({disabled, $bgColor, theme}) =>
            !disabled &&
            ($bgColor === '#FFFFFF' ? theme.colors.whiteHoverColor : lighten(0.08, $bgColor))};
  }

  &:active {
    background-color: ${({disabled, $bgColor}) => !disabled && darken(0.1, $bgColor)};
  }
`;

const StyledButton = ({
                          MONITOR,
                          HOME_NOTICE,
                          PENCIL,
                          CLOSE,
                          USER,
                          width,
                          height = 50,
                          fontSize = 16,
                          fontWeight = 600,
                          fontFamily = 'AppleSDGothicNeoBold',
                          fontColor = theme.colors.whiteColor,
                          margin = 0,
                          border = 'none',
                          borderRadius = 8,
                          bgColor = theme.colors.activeBlue,
                          cursor = 'pointer',
                          title = '',
                          type = 'button',
                          onClick = () => null,
                          disabled
                      }) => {
    return (
        <Button
            $width={width}
            $height={height}
            $fontSize={fontSize}
            $fontWeight={fontWeight}
            $fontFamily={fontFamily}
            $fontColor={fontColor}
            $margin={margin}
            $border={border}
            $borderRadius={borderRadius}
            $bgColor={bgColor}
            $cursor={cursor}
            type={type}
            onClick={onClick}
            disabled={disabled}>
            {title}
            {MONITOR && <IoDesktopOutline style={{marginLeft: 6}} />}
            {HOME_NOTICE && <IoSend fontSize={15} style={{marginLeft: 8, transform: 'translateY(-2px) rotate(-26deg)'}} />}
            {PENCIL && <BiPencil style={{marginLeft: 6}} />}
            {CLOSE && <IoClose fontSize={20} style={{marginLeft: 6}} />}
            {USER && <IoPersonOutline fontSize={16} style={{marginLeft: 6}} />}
        </Button>
    );
};

export default React.memo(StyledButton);
