import React, {useCallback} from "react";
import {
    RankListBox,
    RankTableRow,
    RankTitle,
    TrophyBox,
    Image,
    FaqTitle
} from "../../../../pages/ServiceCenter/service.style";
import Loader from "../../../share/Loader";
import goldTrophy from '../../../../assets/images/goldTrophy.png';
import silverTrophy from '../../../../assets/images/silverTrophy.png';
import bronzeTrophy from '../../../../assets/images/bronzeTrophy.png';
import {handleBodyScroll} from "../../../../utils/commons";

const FaqRankList = ({ title, loading, data = [], setSelectedId, setFaqDetailModal }) => {

    const handleOpenDetail = useCallback(id => {
        setFaqDetailModal(true);
        setSelectedId(id);
        handleBodyScroll('hidden');
    }, []);

    if (loading) return <Loader />;

    return (
        <RankListBox>
            <RankTitle>{title}</RankTitle>
            {data.map((list, index) => (
                <RankTableRow
                    key={`${index}-${list?.faq_id}-list`}
                    onClick={() => handleOpenDetail(list?.faq_id)}>
                    <TrophyBox>
                        <Image
                            src={
                                (index === 0 && goldTrophy) ||
                                (index === 1 && silverTrophy) ||
                                (index === 2 && bronzeTrophy)
                            }
                        />
                    </TrophyBox>
                    <FaqTitle>
                        {list?.faq_question || ''}
                    </FaqTitle>
                </RankTableRow>
            ))}
        </RankListBox>
    );
}

export default FaqRankList;
