import React, {useEffect, useState} from "react";
import {LineChart as StyledLineChart, Line, XAxis, Tooltip, ResponsiveContainer} from 'recharts';
import styled from "styled-components";
import {RegularText} from "../../styled/StyledText";
import theme from "../../../styles/theme";

const testData = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];
const Wrapper = styled.div`
  width: 68%;
  height: 100%;
  flex: 3;
  margin-right: 30px;
`;
const Contents = styled(ResponsiveContainer)`
  width: 100%;
  height: 100%;
  padding-top: 20px;
`;
const TooltipBox = styled.div`
  color: ${theme.colors.whiteColor};
  padding: 12px;
  position: relative;
  border-radius: 4px;
  background-color: ${theme.colors.activeIndigo};
  
  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 10px;
    border-top: 8px solid ${theme.colors.activeIndigo};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 0 solid transparent;
  }
`;

const CustomTooltip = ({active, payload}) => {
    if (active && payload && payload.length) {
        return (
            <TooltipBox>
                {payload[0].value} 명
            </TooltipBox>
        );
    }
}

const LineChart = ({ data }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (data) {
            const tmpData = data?.byMonthCount?.map(item => (
                { name: `${item?.month || ''}월`, value: item?.count || 0 }
            ));
            setChartData(tmpData);
        }
    }, [data]);

    return (
        <Wrapper>
            <RegularText>
                {data?.year || ''}년도 예약 환자 추이
            </RegularText>
            <Contents>
                <StyledLineChart
                    data={chartData}
                    margin={{ left: 26, bottom: 20 }}
                >
                    <Line
                        type="bump"
                        dataKey="value"
                        stroke={theme.colors.activeBlue}
                        strokeWidth={2}
                        dot={false}
                        label={false}
                    />
                    <XAxis
                        height={38}
                        dataKey="name"
                        tickSize={16}
                        tickLine={false}
                        padding="gap"
                    />
                    <Tooltip
                        content={<CustomTooltip/>}
                        payload={data}
                    />
                </StyledLineChart>
            </Contents>
        </Wrapper>
    )
}

export default LineChart;
