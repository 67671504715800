import React, {useCallback, useContext} from 'react';
import {DefaultText, EmphasisText, TableCell, TableRow} from "./dayOff.style";
import {IconBox} from "../Appointment/appointment.style";
import {BsFillPinAngleFill} from "react-icons/bs";
import theme from "../../../styles/theme";
import {dayOffTypeChange} from "../../../pages/HospitalDayOff/dayOff.lib";
import dayjs from "dayjs";
import StyledIconButton from "../../styled/StyledIconButton";
import {useMutation} from "@apollo/client";
import {errorMessage} from "../../../utils/commons";
import {toast} from "react-toastify";
import {DayOffContext} from "../../../pages/HospitalDayOff/dayOff";
import {DELETE_HOSPITAL_OFF_DAY} from "../../../graphql/Setting/mutation";

const DayOffTableRow = ({ list }) => {
    const { setSelectedData, setDayOffModal, refetch, monthRefetch} = useContext(DayOffContext);

    const [deleteHospitalOffday] = useMutation(DELETE_HOSPITAL_OFF_DAY);

    const handleDeleteDayOff = useCallback(async () => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const { data } = await deleteHospitalOffday({
                variables: {
                    deleteId: list?.ho_id,
                    aldyoffdayRepeat: list?.reType
                }
            });

            if (data.deleteHospitalOffday) {
                toast.info('휴무일정을 삭제했습니다.');
                await refetch();
                await monthRefetch();
            }

        } catch (e) {
            errorMessage(e.message);
        }
    }, [list]);

    const handleUpdateDayOff = useCallback(() => {
        setSelectedData(list);
        setDayOffModal(true);
    }, []);

    return (
        <TableRow>
            <TableCell
                $minWidth={42}
                $width={2}
                $marginRight={16}>
                <IconBox>
                    {list?.offType === 'temp'
                        ? 'once'
                        : <BsFillPinAngleFill fontSize={18} color={theme.colors.activeRed}/>}
                </IconBox>
            </TableCell>
            <TableCell
                $minWidth={60}
                $width={10}>
                <EmphasisText>
                    {list?.offType ? dayOffTypeChange(list?.offType) : '-'}
                </EmphasisText>
            </TableCell>
            <TableCell
                $minWidth={290}
                $width={19}>
                <DefaultText>
                    {list?.startDate ? dayjs(list?.startDate).format('YYYY-MM-DD (ddd)') : '-'}
                    {(list?.offType !== 'fix' && list?.endDate) && ` ~ ${dayjs(list?.endDate).format('YYYY-MM-DD (ddd)')}`}
                </DefaultText>
            </TableCell>
            <TableCell
                $column={list?.startTime && list?.endTime}
                $minWidth={94}
                $width={10}>
                {(list?.startTime && list?.endTime) && (
                    <DefaultText>시간 휴무</DefaultText>
                )}
                <DefaultText>
                    {list?.startTime ? list?.startTime : ''}
                    {list?.endTime ? ` ~ ${list?.endTime}` : ''}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={80}
                $width={6}>
                <DefaultText>
                    {list?.reType ? dayOffTypeChange(list?.reType) : '-'}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={300}
                $width={25}>
                <DefaultText>
                    {list?.memo || ''}
                </DefaultText>
            </TableCell>
            <TableCell
                $column
                $minWidth={160}
                $width={23}>
                <DefaultText>
                    {list?.creatorName || ''}
                </DefaultText>
                <DefaultText>
                    {list?.createdAt ? dayjs(list?.createdAt).format('YYYY-MM-DD HH:mm') : ''}
                </DefaultText>
            </TableCell>
            <TableCell
                $minWidth={70}
                $width={5}
                $marginRight='0'>
                <StyledIconButton
                    UPDATE
                    topRightRadius={0}
                    bottomRightRadius={0}
                    margin='0 0 0 -1px'
                    onClick={handleUpdateDayOff}
                />
                <StyledIconButton
                    DELETE
                    topLeftRadius={0}
                    bottomLeftRadius={0}
                    margin='0 0 0 -1px'
                    onClick={handleDeleteDayOff}
                />
            </TableCell>
        </TableRow>
    );
}

export default DayOffTableRow;
