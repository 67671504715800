import React, {useCallback} from "react";
import styled from "styled-components";
import SortablePreviewFile from "../../share/SortablePreviewFile";
import {IoChevronDownOutline, IoChevronUpOutline} from "react-icons/io5";
import theme from "../../../styles/theme";

const Wrapper = styled.ul`
  max-height: 580px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
`;
const SortableItem = styled.li`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ArrowBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;
const ArrowIcon = styled.span`
  height: 16px;
  cursor: pointer;

  &:hover {
    & svg path {
      stroke: black;
    }
  }
`;

const SortableFileList = ({
                              type,
                              fileList,
                              setFileList,
                              previewList,
                              setPreviewList,
                              deletedFileId = [],
                              setDeletedFileId = () => null
                          }) => {

    // 드래그 시작 시 data set
    const handleDragStart = useCallback((e, index) => {
        e.dataTransfer.setData('index', index);
    }, []);

    // 드래그 이벤트 시 index 정렬
    const handleDrop = useCallback((e, newIndex) => {
        e.preventDefault();
        const oldIndex = e.dataTransfer.getData('index');
        const updatedFileList = [...fileList];
        const updatePreviewList = [...previewList];
        const [movedFileList] = updatedFileList.splice(oldIndex, 1);
        const [movedPreviewList] = updatePreviewList.splice(oldIndex, 1);

        updatedFileList.splice(newIndex, 0, movedFileList);
        updatePreviewList.splice(newIndex, 0, movedPreviewList);

        setFileList(updatedFileList);
        setPreviewList(updatePreviewList);
    }, [fileList, previewList]);

    const handleSortUp = useCallback(selectedIndex => {
        if (selectedIndex === 0) return;

        const updatedFileList = [...fileList];
        const updatePreviewList = [...previewList];
        const movedItem = updatedFileList.splice(selectedIndex, 1)[0];
        const movedPreview = updatePreviewList.splice(selectedIndex, 1)[0];

        updatedFileList.splice(selectedIndex - 1, 0, movedItem);
        updatePreviewList.splice(selectedIndex - 1, 0, movedPreview);

        setFileList(updatedFileList);
        setPreviewList(updatePreviewList);
    }, [fileList, previewList]);

    const handleSortDown = useCallback(selectedIndex => {
        if (selectedIndex > fileList.length - 1) return;

        const updatedFileList = [...fileList];
        const updatePreviewList = [...previewList];
        const movedItem = updatedFileList.splice(selectedIndex, 1)[0];
        const movedPreview = updatePreviewList.splice(selectedIndex, 1)[0];

        updatedFileList.splice(selectedIndex + 1, 0, movedItem);
        updatePreviewList.splice(selectedIndex + 1, 0, movedPreview);

        setFileList(updatedFileList);
        setPreviewList(updatePreviewList);
    }, [fileList, previewList]);

    const handleDeleteFile = useCallback((selectedIndex, daId) => {
        const updatedFileList = [...fileList];
        const updatedPreviewList = [...previewList];
        const updatedDeleteId = [...deletedFileId];

        updatedFileList.splice(selectedIndex, 1);
        updatedPreviewList.splice(selectedIndex, 1);

        if (daId !== 0) {
            updatedDeleteId.push(daId);
        }

        setFileList(updatedFileList);
        setPreviewList(updatedPreviewList);
        setDeletedFileId(updatedDeleteId);
    }, [fileList, previewList, deletedFileId]);

    return (
        <Wrapper>
            {previewList.map((file, index) => (
                <SortableItem
                    key={`${index}-file`}
                    draggable
                    onDragStart={e => handleDragStart(e, index)}
                    onDragOver={e => e.preventDefault()}
                    onDrop={e => handleDrop(e, index)}
                >
                    <SortablePreviewFile
                        type={type}
                        file={file}
                        onDelete={() => handleDeleteFile(index, file.da_id)}
                    />
                    <ArrowBox>
                        <ArrowIcon onClick={() => handleSortUp(index)}>
                            <IoChevronUpOutline fontSize={16} color={theme.colors.grayBorder}/>
                        </ArrowIcon>
                        <ArrowIcon onClick={() => handleSortDown(index)}>
                            <IoChevronDownOutline fontSize={16} color={theme.colors.grayBorder}/>
                        </ArrowIcon>
                    </ArrowBox>
                </SortableItem>
            ))}
        </Wrapper>
    )
}

export default SortableFileList;
