import React from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';
import {RegularText} from "../../styled/StyledText";
import theme from "../../../styles/theme";

const CountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Title = styled(RegularText)`
  color: ${theme.colors.fontLightGrayColor};
  margin-bottom: 8px;
`;
const CountBox = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Count = styled(CountUp)`
  min-width: 16px;
  font-size: 24px;
  font-family: 'AppleSDGothicNeoBold';
  color: ${theme.colors.activeIndigo};
`;
const GrayText = styled(RegularText)`
  color: ${theme.colors.fontLightGrayColor};
  margin-left: 6px;
`;

const StatsCount = ({
                        title,
                        unit = '명',
                        count = 0,
                    }) => {
    return (
        <CountWrapper>
            <Title>{title}</Title>
            <CountBox>
                <Count
                    start={0}
                    end={count}
                    duration={1.5}
                    separator=","
                >
                    {count}
                </Count>
                <GrayText>{unit}</GrayText>
            </CountBox>
        </CountWrapper>
    )
}

export default StatsCount;
