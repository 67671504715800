import React, {useCallback, useEffect, useState} from "react";
import {NoticeSection, NoticeFilterBox, FilterBox, TabButton, NoticeListBox} from "../service.style";
import StyledIconButton from "../../../components/styled/StyledIconButton";
import SearchModal from "../../../components/share/SearchModal";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_PLATFORM_NOTICE_CS} from "../../../graphql/Service/query";
import {serviceChange} from "../service.lib";
import Loader from "../../../components/share/Loader";
import NoticeTableRow from "../../../components/feature/Service/Notice/NoticeTableRow";
import PlatformNoticeDetailModal from "../../../components/feature/Home/Notice/PlatformNoticeDetailModal";
import {errorMessage, handleBodyScroll} from "../../../utils/commons";
import {UPDATE_HOSPITAL_NOTICE_USER_VIEW} from "../../../graphql/Home/mutation";

const Notice = () => {
    const [activeTab, setActiveTab] = useState('긴급 공지');
    const [searchModal, setSearchModal] = useState(false);
    const [sortType, setSortType] = useState('desc');
    const [searchTerm, setSearchTerm] = useState('');

    const [selectedId, setSelectedId,] = useState(null);
    const [commentModal, setCommentModal] = useState(false);

    const { data, loading, refetch: noticeListRefetch } = useQuery(SEE_PLATFORM_NOTICE_CS, {
       variables: {
           filter: serviceChange(activeTab),
           searchTerm: searchTerm,
           orderby: sortType,
       }
    });
    const [updateHospitalNoticeUserView] = useMutation(UPDATE_HOSPITAL_NOTICE_USER_VIEW);

    const handleSort = useCallback(() => {
        if (sortType === 'desc') {
            setSortType('asc');
        } else {
            setSortType('desc');
        }
    }, [sortType]);

    const handleOpenComment = useCallback(async id => {
        setSelectedId(id);
        setCommentModal(true);

        try { // 공지사항 조회 수 count
            await updateHospitalNoticeUserView({
                variables: {
                    pnId: id
                }
            });
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    useEffect(() => {
        if (commentModal) {
            handleBodyScroll('hidden');
        } else {
            handleBodyScroll('initial');
        }
    }, [commentModal]);

    return (
        <NoticeSection>
            <NoticeFilterBox>
                <FilterBox>
                    <TabButton $active={activeTab === '긴급 공지'} onClick={() => setActiveTab('긴급 공지')}>긴급 공지</TabButton>
                    <TabButton $active={activeTab === '업데이트 공지'} onClick={() => setActiveTab('업데이트 공지')}>업데이트 공지</TabButton>
                </FilterBox>
                <FilterBox>
                    <StyledIconButton // 검색
                        SEARCH
                        margin='0 12px'
                        onClick={() => setSearchModal(true)}
                    />
                    <StyledIconButton // 리스트 보기
                        SORT
                        onClick={handleSort}
                    />
                </FilterBox>
            </NoticeFilterBox>
            <NoticeListBox>
                {loading
                    ? <Loader />
                    : data?.seePlatformNoticeCs?.platformNoticeList?.map((notice, index) => (
                        <NoticeTableRow
                            key={`${index}-${notice.pn_id}-notice-list`}
                            notice={notice}
                            handleOpenComment={handleOpenComment}
                        />
                    ))
                }
            </NoticeListBox>


            <SearchModal // 검색하기 모달
                searchModal={searchModal}
                setSearchModal={setSearchModal}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />

            <PlatformNoticeDetailModal
                commentModal={commentModal}
                setCommentModal={setCommentModal}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                noticeListRefetch={noticeListRefetch}
            />
        </NoticeSection>
    )
}

export default Notice;
