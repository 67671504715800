import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../../styles/theme';

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg)
  }
`;
const Wrapper = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
`;
const LoaderBox = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid ${theme.colors.lightGrayBorder};
  border-top: 5px solid ${theme.colors.activeIndigo};
  border-radius: 50%;
  animation: ${Spin} 1.3s linear infinite;
`;

const Loader = ({ height }) => {
    return (
        <Wrapper height={height}>
            <LoaderBox />
        </Wrapper>
    );
};

export default Loader;
