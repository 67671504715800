import React, {useLayoutEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {toast, ToastContainer} from "react-toastify";
import theme from './styles/theme';
import GlobalStyles from "./styles/GlobalStyles";
import {Router} from "./Router";
import {useQuery} from "@apollo/client";
import {SEE_DOCTOR_ROOM, SEE_USER_INFO_DETAIL} from "./graphql/Common/query";
import {doctorsVar, userRefetchVar, userVar} from "./store";
import 'dayjs/locale/ko';
import dayjs from "dayjs";
import {getCookie} from "./utils/cookie";
import isToday from "dayjs/plugin/isToday";

dayjs.locale('ko'); // korean language setting
dayjs.extend(isToday); // 오늘인지 여부

if (process.env.NODE_ENV === 'production') {
    console.log = function no_console() {};
    console.warn = function no_console() {};
    console.info = function no_console() {};
}

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);

    // Query
    const { data: userData, loading: userLoading, refetch: userRefetch } = useQuery(SEE_USER_INFO_DETAIL);
    const { data: doctorsData, loading: doctorsLoading } = useQuery(SEE_DOCTOR_ROOM);

    const previousLoading = () => {
        try {
            const token = getCookie('mediplatform_token');

            if (token) {
                // tokenValidate(token);
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        } catch (e) {
            toast.error(e);
        }
    };

    useLayoutEffect(() => {
        previousLoading();
    }, []);

    useLayoutEffect(() => {
        if (!userLoading && userData) {
            userVar(userData?.seeUserInfoDetail);
            userRefetchVar(userRefetch);
        }
        if (!doctorsLoading && doctorsData) { // 진료실 option 값
            doctorsVar(doctorsData?.seeDoctorRoom?.doctorRoomList);
        }
    }, [userLoading, userData, doctorsLoading, doctorsData]);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <ToastContainer position={toast.POSITION.TOP_RIGHT} />
            <Router isLoggedIn={isLoggedIn} />
        </ThemeProvider>
    );
}

export default App;
