import React from "react";
import styled, {css} from "styled-components";
import {BoldText, RegularText} from "../styled/StyledText";
import theme from "../../styles/theme";
import StyledButton from "../styled/StyledButton";
import { IoArrowBackOutline } from 'react-icons/io5';
import {useNavigate} from "react-router-dom";

const Wrapper = styled.div`
  padding: 20px 40px;
  border-bottom: ${({ $borderBottom }) => $borderBottom};
`;
const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const GuideLine = styled(RegularText)`
  color: ${theme.colors.fontLightGrayColor};
  margin-bottom: 20px;
`;
const Title = styled(BoldText)`
  display: flex;
  align-items: center;
  padding-bottom: 6px;
`;
const ButtonBox = styled.div`
  display: flex;
`;
const TabButton = styled.button`
  font-size: 24px;
  font-family: 'AppleSDGothicNeoBold', 'SF Pro Display', sans-serif;
  color: ${theme.colors.grayBorder};
  margin-right: 20px;
  transition: .25s;

  ${({$active}) => $active && css`
    color: ${theme.colors.blackColor};
  `};
`;


const PageHeader = ({
                        GO_BACK,
                        HOME_NOTICE,
                        TAB_BUTTON,
                        APPOINTMENT,
                        SCHEDULE,
                        DID,
                        SERVICE,
                        DAY_OFF,
                        MANAGER,
                        borderBottom = `1px solid ${theme.colors.ultraLightGrayBorder}`,
                        guideLine = '',
                        title = '',
                        tabButton = [],
                        activeTab,
                        onClick,
                        handleTabClick,
                        children,
                    }) => {
    const navigate = useNavigate();
    return (
        <Wrapper $borderBottom={borderBottom}>
            <HeaderInfo>
                <TitleBox>
                    {guideLine && <GuideLine>{guideLine}</GuideLine>}
                    {!TAB_BUTTON && ( // 탭 버튼으로 페이지가 나뉘지 않을 때
                        <Title>
                            {GO_BACK && (
                                <IoArrowBackOutline
                                    fontSize={26}
                                    style={{ marginRight: 10, marginTop: 4, cursor: 'pointer' }}
                                    onClick={() => navigate(-1)} />
                            )}
                            {title}
                        </Title>
                    )}
                    {TAB_BUTTON && ( // 탭 버튼으로 페이지가 나뉠 때
                        <ButtonBox>
                            {tabButton.map((button, index) => (
                                <TabButton
                                    key={`${index}-button`}
                                    onClick={() => handleTabClick(button)}
                                    $active={activeTab === button}>
                                    {button}
                                </TabButton>
                            ))}
                        </ButtonBox>
                    )}
                </TitleBox>

                {APPOINTMENT && ( // 예약 목록
                    <StyledButton
                        PENCIL
                        width={160}
                        height={38}
                        fontFamily='AppleSDGothicNeoRegular'
                        title='예약자 추가하기'
                        onClick={onClick}
                    />
                )}
                {HOME_NOTICE && activeTab === '사내 공지' && ( // 사내 공지=
                    <StyledButton
                        HOME_NOTICE
                        // width={155}
                        height={38}
                        fontFamily='AppleSDGothicNeoRegular'
                        title='공지 작성하기'
                        onClick={onClick}
                    />
                )}

                {SCHEDULE && (
                    <StyledButton
                        PENCIL
                        height={38}
                        fontFamily='AppleSDGothicNeoRegular'
                        title='특별 일정 추가하기'
                        onClick={onClick}
                    />
                )}

                {DID && (
                    <StyledButton
                        MONITOR
                        width={155}
                        height={38}
                        fontFamily='AppleSDGothicNeoRegular'
                        title='모니터 추가하기'
                        onClick={onClick}
                    />
                )}

                {SERVICE && (
                    <StyledButton
                        PENCIL
                        width={188}
                        height={38}
                        fontFamily='AppleSDGothicNeoRegular'
                        title='궁금한 사항 문의하기'
                        onClick={onClick}
                    />
                )}

                {DAY_OFF && (
                    <StyledButton
                        PENCIL
                        width={188}
                        height={38}
                        fontFamily='AppleSDGothicNeoRegular'
                        title='쉬는날 등록하기'
                        onClick={onClick}
                    />
                )}

                {MANAGER && (
                    <StyledButton
                        USER
                        width={188}
                        height={38}
                        fontFamily='AppleSDGothicNeoRegular'
                        title='사용자 추가하기'
                        onClick={onClick}
                    />
                )}
            </HeaderInfo>
            {children}
        </Wrapper>
    );
}

export default React.memo(PageHeader);
