const colors = {
	// General
	whiteColor: '#FFFFFF',
	blackColor: '#000000',

	// Point
	activeBlue: '#2D8CFF',
	activeIndigo: '#0E2044',
	activeRed: '#D32F2F',
	activeOrange: '#EB6927',
	activePink: '#F63E90',
	activeApricot: '#FAE0D3',
	activeLightOrange: '#F99F25',
	activeYellow: '#F9D025',
	activeGreen: '#4CAF50',
	activeLightGreen: '#ECF6EC',
	activeYellowGreen: '#B5FF0D',
	activeGray: '#A2A2A2',

	activeLightBlue: '#E9F3FF',
	activeLightYellow: '#FFF6E8',
	activeLightPink: '#FDF0E9',

	// Font
	fontGrayColor: '#B3B3B3',
	fontLightGrayColor: '#B9B9B9',
	fontDarkGrayColor: '#7B7B80',

	// Border
	lightGrayBorder: '#E5E5E5',
	ultraLightGrayBorder: '#EFEFEF',
	grayBorder: '#D3D3D3',

	// Background
	lightWhiteBgColor: '#FBFBFB',
	whiteHoverColor: '#F0F0F0',
	lightGrayBgColor: '#F3F3F3',
};

const theme = {
	colors
};

export default theme;
