import styled from "styled-components";
import theme from "../../../styles/theme";
import {RegularText} from "../../../components/styled/StyledText";

export const NoticeWrapper = styled.div`
  padding-bottom: 20px;
`;
export const FilterBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
export const GrayText = styled(RegularText)`
  color: ${theme.colors.fontDarkGrayColor};
  margin-right: 10px;
`;
export const TableBody = styled.ul``;
