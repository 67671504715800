import React, {useCallback, useEffect, useMemo, useState} from "react";
import RightSlideModal from "../../layout/RightSlideModal";
import {useMutation, useReactiveVar} from "@apollo/client";
import {userVar} from "../../../store";
import {ButtonBox, InputForm, ModalContents, Title} from "./profile.style";
import StyledInput from "../../styled/StyledInput";
import StyledBorderCalendar from "../../styled/StyledBorderCalendar";
import StyledPostCode from "../../styled/StyledPostCode";
import DaumPostModal from "../../share/DaumPostModal";
import StyledButton from "../../styled/StyledButton";
import theme from "../../../styles/theme";
import {autoHyphen, errorMessage, removeHyphen} from "../../../utils/commons";
import {REQUEST_PHONE_AUTH_CODE, UPDATE_MY_INFO} from "../../../graphql/Profile/mutation";
import {toast} from "react-toastify";
import {changeInfoValidCheck} from "../../../pages/Profile/profile.lib";

const PersonalInfoModal = ({
                               personalInfoModal,
                               setPersonalInfoModal,
                               refetch
                           }) => {
    const user = useReactiveVar(userVar);
    const [inputs, setInputs] = useState({
        name: '',
        phoneNumber: '',
        authCode: '',
        address: '',
        detailAddress: '',
        prevPhoneNumber: ''
    });
    const [birthday, setBirthDay] = useState(new Date());
    const [postModal, setPostModal] = useState(false);

    // 폰 번호 변경
    const isChangePhoneNumber = useMemo(() => inputs.phoneNumber !== inputs.prevPhoneNumber, [inputs]);

    const [requestPhoneAuthCode] = useMutation(REQUEST_PHONE_AUTH_CODE);
    const [updateMyInfo] = useMutation(UPDATE_MY_INFO);

    const onChange = useCallback(e => {
        const { name, value } = e.target;

        if (name === 'phoneNumber') {
            setInputs({
                ...inputs,
                [name]: autoHyphen(value)
            });
        } else {
            setInputs({
                ...inputs,
                [name]: value
            });
        }
    }, [inputs]);

    const handleClose = useCallback(() => {
        setPersonalInfoModal(false);
        setInputs({
            name: '',
            phoneNumber: '',
            authCode: '',
            address: '',
            detailAddress: '',
            prevPhoneNumber: ''
        });
        setBirthDay(new Date());
        setPostModal(false);
    }, []);

    const handleSendAuthCode = useCallback(async () => {
        if (inputs.phoneNumber === '') {
            toast.error('휴대폰 번호를 입력해주세요.');
            return;
        }
        if (!isChangePhoneNumber) {
            toast.error('휴대폰 번호가 변경 되었을 때만 인증이 필요합니다.');
            return;
        }

        try {
            const { data } = await requestPhoneAuthCode({
                variables: {
                    cellphone: inputs.phoneNumber
                }
            });

            if (data?.requestPhoneAuthCode) {
                toast.info('인증코드를 전송했습니다.');
            }

        } catch (e) {
            ;
            switch (e.message) {
                case 'err_00':
                    toast.error('인증번호 전송에 실패했습니다.');
                    break;
                case 'err_01':
                    toast.error('문자 거래처 번호를 추가해주세요.');
                    break;
            }
        }
    }, [inputs]);

    const handleComplete = useCallback(data => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setInputs({
            ...inputs,
            address: fullAddress
        });
        setPostModal(false);
    }, [inputs]);

    const handleSave = useCallback(async () => {
        if (changeInfoValidCheck(inputs, isChangePhoneNumber)) return;

        try {
            const { data } = await updateMyInfo({
                variables: {
                    userName: inputs.name,
                    userBirthday: birthday,
                    userCellphone: removeHyphen(inputs.phoneNumber),
                    userDetailAddress: inputs.detailAddress,
                    authCode: inputs.authCode,
                    userAddress: inputs.address,
                }
            });

            if (data?.updateMyInfo) {
                toast.info('개인정보를 수정했습니다.');
                handleClose();
                await refetch();
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error( '데이터 처리에 실패했습니다.\n잠시 후 다시 시도해주세요.');
                    break;
                case 'err_01':
                    toast.error( '전송된 인증번호가 없습니다.\n다시 요청해주세요.');
                    break;
                case 'err_02':
                    toast.error( '인증번호가 일치하지 않습니다.\n다시 입력해주세요.');
                    break;
                case 'err_03':
                    toast.error( '이미 사용중인 번호입니다..\n확인 후 다시 시도해주세요.');
                    break;
            }
            ;
        }
    }, [inputs, birthday, isChangePhoneNumber]);

    useEffect(() => {
        if (personalInfoModal) {
            setBirthDay(new Date(user.user_birthday));
            setInputs({
                name: user.user_name,
                phoneNumber: autoHyphen(user.user_cellphone),
                authCode: '',
                address: user.user_address,
                detailAddress: user.user_detailAddress,
                prevPhoneNumber: autoHyphen(user.user_cellphone)
            });
        }
    }, [personalInfoModal, user]);

    return (
        <RightSlideModal
            title='정보 변경'
            visible={personalInfoModal}
            onClose={handleClose}>
            <ModalContents>
                <InputForm>
                    <Title>개인 정보</Title>
                    <StyledInput
                        ROW
                        title='이름'
                        height={38}
                        name='name'
                        value={inputs.name}
                        onChange={onChange}
                        placeholder='이름을 입력해주세요.'
                    />

                    <StyledBorderCalendar
                        ROW
                        title='생년월일'
                        value={birthday}
                        onChange={date => setBirthDay(date)}
                        height={38}
                        margin='12px 0'
                    />

                    <StyledInput
                        ROW AUTH
                        title='휴대전화번호'
                        height={38}
                        name='phoneNumber'
                        value={inputs.phoneNumber}
                        onChange={onChange}
                        onClickSendAuthCode={handleSendAuthCode}
                        placeholder='휴대전화번호를 입력해주세요.'
                        maxLength={13}
                    />

                    <StyledInput
                        ROW
                        title=' '
                        margin='12px 0'
                        height={38}
                        name='authCode'
                        value={inputs.authCode}
                        onChange={onChange}
                        placeholder='6자리 인증번호를 입력해주세요.'
                    />

                    <StyledPostCode
                        setPostModal={setPostModal}
                        address={inputs.address}
                        value={inputs.detailAddress}
                        onChange={onChange}
                    />
                </InputForm>
                <ButtonBox>
                    <StyledButton
                        PENCIL
                        width={214}
                        title='저장하기'
                        onClick={handleSave}
                    />
                    <StyledButton
                        CLOSE
                        title='닫기'
                        width={214}
                        fontColor={theme.colors.blackColor}
                        border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                        bgColor={theme.colors.whiteColor}
                        onClick={handleClose}
                    />
                </ButtonBox>
            </ModalContents>

            {postModal && (
                <DaumPostModal
                    setPostModal={setPostModal}
                    handleComplete={handleComplete}
                />
            )}
        </RightSlideModal>
    );
}

export default PersonalInfoModal;
