import React, {useCallback, useContext, useEffect, useState} from 'react';
import ModalBackground from "../../layout/ModalBackground";
import {DayOffModalContents, Line, Section} from "./dayOff.style";
import StyledSelect from "../../styled/StyledSelect";
import StyledRangeCalendar from "../../styled/StyledRangeCalendar";
import {hourOption, minuteOption} from "../../../pages/Setting/setting.lib";
import {Hyphen} from "../Setting/Modal/setting.modal.style";
import StyledFlexBox from "../../styled/StyledFlexBox";
import StyledTextarea from "../../styled/StyledTextarea";
import StyledButton from "../../styled/StyledButton";
import {useMutation} from "@apollo/client";
import {CREATE_HOSPITAL_OFF_DAY, UPDATE_HOSPITAL_OFF_DAY} from "../../../graphql/Setting/mutation";
import {createDayOffValidateCheck, dayOffTypeChange} from "../../../pages/HospitalDayOff/dayOff.lib";
import {toast} from "react-toastify";
import {DayOffContext} from "../../../pages/HospitalDayOff/dayOff";
import {errorMessage} from "../../../utils/commons";

const DayOffModal = ({
                         dayOffModal,
                         setDayOffModal,
                         refetch,
                         monthRefetch
                     }) => {
    const { selectedData, setSelectedData } = useContext(DayOffContext);

    const [inputs, setInputs] = useState({
        type: '임시휴무',
        startHour: '00시',
        startMin: '00분',
        endHour: '00시',
        endMin: '00분',
        isRepeat: '반복없음',
        memo: '',
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [createHospitalOffday] = useMutation(CREATE_HOSPITAL_OFF_DAY);
    const [updateHospitalOffday] = useMutation(UPDATE_HOSPITAL_OFF_DAY);

    const onChangeDate = useCallback(dates => { // 달력 onChange
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
    }, []);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const handleClose = useCallback(() => {
        setDayOffModal(false);
        setSelectedData(null);
        setInputs({
            type: '임시휴무',
            startHour: '00시',
            startMin: '00분',
            endHour: '00시',
            endMin: '00분',
            isRepeat: '반복없음',
            memo: '',
        });
        setStartDate(null);
        setEndDate(null);
    }, []);

    const handleUpdate = useCallback(async () => {
        if (createDayOffValidateCheck(startDate, endDate, inputs)) return;

        try {
            const { data } = await updateHospitalOffday({
                variables: {
                    updateId: selectedData.ho_id,
                    aldyoffdayRepeat: selectedData.reType,
                    offdayRepeat: dayOffTypeChange(inputs.isRepeat),
                    offStartDate: startDate,
                    offEndDate: endDate,
                    offStartTime: `${inputs.startHour.replace('시', '')}:${inputs.startMin.replace('분', '')}`,
                    offEndTime: `${inputs.endHour.replace('시', '')}:${inputs.endMin.replace('분', '')}`,
                    memo: inputs.memo,
                }
            });

            if (data.updateHospitalOffday) {
                toast.info('휴무일을 수정했습니다.');
                await refetch();
                await monthRefetch();
                handleClose();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [selectedData, inputs, startDate, endDate]);

    const handleSave = useCallback(async () => {
        if (createDayOffValidateCheck(startDate, endDate, inputs)) return;

        try {
            const { data } = await createHospitalOffday({
                variables: {
                    hoType: dayOffTypeChange(inputs.type),
                    hoOffStartDate: startDate,
                    hoOffEndDate: endDate,
                    hoOffStartTime: `${inputs.startHour.replace('시', '')}:${inputs.startMin.replace('분', '')}`,
                    hoOffEndTime: `${inputs.endHour.replace('시', '')}:${inputs.endMin.replace('분', '')}`,
                    hoOffdayRepeat: dayOffTypeChange(inputs.isRepeat),
                    hoMemo: inputs.memo,
                }
            });

            if (data.createHospitalOffday) {
                toast.info('휴무일을 등록했습니다.');
                await refetch();
                await monthRefetch();
                handleClose();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [startDate, endDate, inputs]);

    useEffect(() => {
        if (selectedData) {
            setInputs({
                type: dayOffTypeChange(selectedData.offType),
                startHour: selectedData.startTime ? `${selectedData.startTime.split(':')[0]}시` : '00시',
                startMin: selectedData.startTime ? `${selectedData.startTime.split(':')[1]}분` : '00분',
                endHour: selectedData.endTime ? `${selectedData.endTime.split(':')[0]}시` : '00시',
                endMin: selectedData.endTime ? `${selectedData.endTime.split(':')[1]}분` : '00분',
                isRepeat: dayOffTypeChange(selectedData.reType),
                memo: selectedData.memo,
            });
            setStartDate(new Date(selectedData.startDate));
            setEndDate(new Date(selectedData.endDate));
        }
    }, [selectedData]);

    return (
        <ModalBackground
            width={1040}
            height={620}
            title={`쉬는날 ${selectedData ? '수정' : '등록'}하기`}
            visible={dayOffModal}
            onClose={handleClose}>
            <DayOffModalContents>
                <Section>
                    <StyledSelect
                        ASTERISK
                        title='구분'
                        width={440}
                        borderRadius={4}
                        name='type'
                        value={inputs.type}
                        onChange={onChange}
                        options={['임시휴무', '고정휴무']}
                    />

                    <StyledRangeCalendar
                        COLUMN
                        id='dayOff-date-range-picker-wrapper'
                        inputId='dayOff-date-range-picker'
                        height={50}
                        borderRadius={4}
                        margin='20px 0'
                        startDate={startDate}
                        endDate={endDate}
                        onChange={onChangeDate}
                    />

                    <StyledFlexBox>
                        <StyledSelect
                            title='시작시간'
                            width={205}
                            borderRadius={4}
                            name='startHour'
                            value={inputs.startHour}
                            onChange={onChange}
                            options={hourOption}
                        />
                        <Hyphen>-</Hyphen>
                        <StyledSelect
                            width={205}
                            borderRadius={4}
                            name='startMin'
                            value={inputs.startMin}
                            onChange={onChange}
                            options={minuteOption}
                        />
                    </StyledFlexBox>
                    <StyledFlexBox margin='20px 0'>
                        <StyledSelect
                            title='종료시간'
                            width={205}
                            borderRadius={4}
                            name='endHour'
                            value={inputs.endHour}
                            onChange={onChange}
                            options={hourOption}
                        />
                        <Hyphen>-</Hyphen>
                        <StyledSelect
                            width={205}
                            borderRadius={4}
                            name='endMin'
                            value={inputs.endMin}
                            onChange={onChange}
                            options={minuteOption}
                        />
                    </StyledFlexBox>

                    <StyledSelect
                        title='반복여부'
                        width={440}
                        margin='20px 0 40px'
                        borderRadius={4}
                        name='isRepeat'
                        value={inputs.isRepeat}
                        onChange={onChange}
                        options={['반복없음', '매주반복', '매월반복']}
                        disabled={inputs.type === '임시휴무'}
                    />

                    <StyledButton
                        title={selectedData ? '수정하기' : '등록하기'}
                        width={440}
                        onClick={selectedData ? handleUpdate :handleSave}
                    />
                </Section>
                <Line />
                <Section>
                    <StyledTextarea
                        title='메모'
                        name='memo'
                        value={inputs.memo}
                        onChange={onChange}
                        placeholder='메모를 입력해주세요.'
                    />
                </Section>
            </DayOffModalContents>
        </ModalBackground>
    );
}

export default DayOffModal;
