import React from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import {IoCamera} from "react-icons/io5";

const ImageUploadLabel = styled.label`
  width: 168px;
  height: 168px;
  font-size: 24px;
  color: ${theme.colors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
  
  &::after {
    content: '';
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 20px;
    background-color: ${theme.colors.activeBlue};
  }
`;
const CameraIcon = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  bottom: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.whiteColor};
`;
const Upload = styled.input`
  display: none;
`;
const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
  border-radius: 20px;
`;

const ProfileImageUpload = ({
                                userName = '',
                                onChange = () => null,
                                previewUrl = '',
                            }) => {
    return (
        <ImageUploadLabel>
            {userName}
            <CameraIcon>
                <IoCamera fontSize={20} color={theme.colors.blackColor}/>
            </CameraIcon>
            <Upload
                type='file'
                accept='.png, .jpeg, .jpg'
                onChange={onChange}
            />
            {previewUrl && <PreviewImage src={previewUrl}/>}
        </ImageUploadLabel>
    )
}

export default ProfileImageUpload;
