import { gql } from '@apollo/client';

export const SEE_SPECIAL_SCHEDULE = gql`
    query SeeSpecialSchedule(
        $year: Int
        $month: Int
        $searchTerm: String
        $orderBy: String
    ) {
        seeSpecialSchedule(
            year: $year
            month: $month
            searchTerm: $searchTerm
            orderBy: $orderBy
        ) {
            ss_id
            ss_type
            ss_doctorRoomName
            ss_doctorName
            ss_startDate
            ss_endDate
            ss_subDoctorUsed
            ss_startTime
            ss_endTime
            ss_memo
            ss_status
        }
    }
`;

export const SEE_SPECIAL_SCHEDULE_DETAIL = gql`
    query SeeSpecialScheduleDetail($ssId: Int!) {
        seeSpecialScheduleDetail(ss_id: $ssId) {
            ss_id
            ss_type
            ss_doctorRoomName
            ss_doctorName
            ss_startDate
            ss_endDate
            ss_subDoctorUsed
            ss_startTime
            ss_endTime
            ss_memo
            ss_status
            specialScheduleAttactheds {
                sa_id
                sa_url
                sa_fileType
                sa_fileSize
            }
            specialScheduleHistory {
                ssh_id
                ssh_createdAt
                ssh_creatorName
                ssh_creatorRank
                ssh_creatorId
                ssh_text
                ssh_confirmStatus
                ssh_creatorImg
                ssh_type
            }
            ss_createdAt
        }
    }
`;
