import {toast} from "react-toastify";
import {isEmail} from "../../utils/commons";

export const managerValidCheck = inputs => {
    const { name, phoneNumber, email } = inputs;
    let valid;

    if (name === '') {
        toast.error('이름을 입력해주세요.');
        valid = true;
    } else if (phoneNumber === '') {
        toast.error('휴대폰번호를 입력해주세요.');
        valid = true;
    } else if (email === '') {
        toast.error('이메일을 입력해주세요.');
        valid = true;
    } else if (!isEmail(email)) {
        toast.error('이메일을 형식이 아닙니다.');
        valid = true;
    }

    return valid;
}
