import styled from "styled-components";
import {RegularText} from "../../components/styled/StyledText";
import theme from "../../styles/theme";
import selectBottomArrow from '../../assets/icons/selectBottomArrow.svg';

export const FilterBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
export const GrayText = styled(RegularText)`
  color: ${theme.colors.fontDarkGrayColor};
  margin-right: 10px;
`;
export const TableBody = styled.div`
  min-height: calc(100vh - 360px);
  padding-left: 40px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const SelectBox = styled.select`
  width: 68px;
  font-size: 16px;
  font-family: 'AppleSDGothicNeoRegular';
  padding-bottom: 1px;
  background-image: url(${selectBottomArrow});
  background-repeat: no-repeat;
  background-position: 90% 50%;
`;
