import React, {useCallback} from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import {RegularText} from "../styled/StyledText";
import {getFileSize} from "../../utils/commons";
import {IoCloseOutline} from "react-icons/io5";
import fileIcon from "../../assets/images/fileIcon.png";

const Wrapper = styled.div`
  max-width: 148px;
  flex: 1;
  height: 42px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
const PreviewBox = styled.div`
  width: 42px;
  height: 100%;
  margin-right: 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: 1px solid ${theme.colors.ultraLightGrayBorder};
  overflow: hidden;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const InfoBox = styled.div`
  width: calc(100% - 60px);
`;
const Title = styled(RegularText)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 2px;
`;
const SizeText = styled(RegularText)`
  font-size: 12px;
  color: ${theme.colors.fontGrayColor};
`;
const DeleteIcon = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: ${theme.colors.whiteColor};
`;

const PreviewFile = ({
                         DOWNLOAD,
                         DELETE,
                         file,
                         onDownload = () => null,
                         onDelete,
                     }) => {
    const handleDownload = useCallback(() => {
        if (DOWNLOAD) {
            window.open(file.url);
            // onDownload(file.url);
        }
    }, [DOWNLOAD, onDownload, file]);

    return (
        <Wrapper onClick={handleDownload}>
            <PreviewBox>
                <Image src={file?.url || fileIcon}/>
            </PreviewBox>
            <InfoBox>
                <Title>{file?.name || ''}</Title>
                <SizeText>{getFileSize(file?.size || 0)}</SizeText>
            </InfoBox>

            {DELETE && (
                <DeleteIcon onClick={() => onDelete(file?.name)}>
                    <IoCloseOutline fontSize={18} color={theme.colors.activeOrange}/>
                </DeleteIcon>
            )}
        </Wrapper>
    )
}

export default React.memo(PreviewFile);
