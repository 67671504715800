import { gql } from '@apollo/client';

export const REQUEST_PHONE_AUTH_CODE = gql`
    mutation RequestPhoneAuthCode($cellphone: String) {
        requestPhoneAuthCode(cellphone: $cellphone)
    }
`;

export const UPDATE_MY_INFO = gql`
    mutation UpdateMyInfo(
        $userName: String
        $userBirthday: String
        $userCellphone: String
        $userDetailAddress: String
        $authCode: String
        $userAddress: String
    ) {
        updateMyInfo(
            user_name: $userName
            user_birthday: $userBirthday
            user_cellphone: $userCellphone
            user_detailAddress: $userDetailAddress
            authCode: $authCode
            user_address: $userAddress
        )
    }
`;

export const UPDATE_USER_IMG = gql`
    mutation UpdateUserImg($userImg: Upload) {
        updateUserImg(user_img: $userImg)
    }
`;

export const UPDATE_MY_EMPLOYEE_INFO = gql`
    mutation UpdateMyEmployeeInfo($userOrg: String, $userJob: String) {
        updateMyEmployeeInfo(user_org: $userOrg, user_job: $userJob)
    }
`;
