import React from "react";
import {DateText, TableBody} from "../../../pages/Appointment/appointment.styles";
import dayjs from "dayjs";
import Loader from "../../share/Loader";
import AppointmentTableRow from "./AppointmentTableRow";
import PageNation from "../../layout/PageNation";

const AppointmentList = ({
                            date,
                             listLoading,
                             listData,
                             take,
                             setTake,
                             page,
                             onClickPage
                         }) => {
    return (
        <>
            <DateText>{dayjs(date).format('YYYY-MM-DD')}</DateText>
            <TableBody>
                {listLoading
                    ? <Loader/>
                    : listData?.seeReservationList?.reservationList?.map(list => (
                        <AppointmentTableRow
                            key={`${list.re_id}-list`}
                            list={list}
                        />
                    ))}
            </TableBody>
            <PageNation // 페이지
                totalLength={listData?.seeReservationList?.totalLength}
                take={take}
                setTake={setTake}
                page={page}
                onClickPage={onClickPage}
                takeOption={[10, 20, 30]}
            />
        </>
    )
}

export default AppointmentList;
