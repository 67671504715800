export const serviceChange = value => {
    switch (value) {
        case '전체':
            return 'total';
        case '답변':
            return 'answered';
        case '미답변':
            return 'unanswered';

        case '긴급 공지':
            return 'emergency';
        case '업데이트 공지':
            return 'update';
    }
}
