import { gql } from '@apollo/client';

export const SEE_HOSPITAL_NOTICE = gql`
    query SeeHospitalNotice($year: Int, $filter: String, $take: Int, $cursor: Int) {
        seeHospitalNotice(year: $year, filter: $filter, take: $take, cursor: $cursor) {
            totalLength
            hospitalNoticeList {
                hn_id
                hn_title
                hn_text
                hn_createdAt
                hn_creatorName
                hn_creatorRank
                hn_creatorId
                hn_creatorImg
                hnAttached {
                    hna_id
                    hna_url
                    han_fileType
                    han_fileSize
                }
                hnComment {
                    hnc_id
                    hnc_text
                    hnc_createdAt
                    hnc_creatorName
                    hnc_creatorRank
                    hnc_creatorId
                }
            }
        }
    }
`;

export const SEE_DASHBOARD_COUNT = gql`
    query SeeDashBoardCount {
        seeDashBoardCount {
            thisMonthResCount
            thisWeekResCount
            todayResCount
            thisMonthSpecialScheduleCount
            hospitalNoticeCount
        }
    }
`;

export const SEE_TODAY_RESERVATION = gql`
    query SeeTodayReservation {
        seeTodayReservation {
            totalResCount
            todayResInfo {
                re_id
                re_time
                re_patientName
                re_status
            }
        }
    }
`;

export const SEE_PLATFORM_NOTICE = gql`
    query SeePlatformNotice(
        $searchTerm: String
        $filter: platformNoticeType
        $year: Int
        $take: Int
        $cursor: Int
    ) {
        seePlatformNotice(
            searchTerm: $searchTerm
            filter: $filter
            year: $year
            take: $take
            cursor: $cursor
        ) {
            totalLength
            platformNotice {
                pn_id
                pn_createdAt
                pn_updatedAt
                pn_type
                pn_title
                pn_text
                pn_adminCreatorName
                pn_adminCreatorRank
                pn_adminCreatorId
                pnAttached {
                    pna_id
                    pna_url
                    pna_fileType
                    pna_fileSize
                }
                pnComment {
                    pnc_id
                    pnc_text
                    pnc_createdAt
                    pnc_updatedAt
                    pnc_creatorName
                    pnc_creatorRank
                    pnc_creatorId
                    pnc_creatorImg
                }
            }
        }
    }
`;

export const SEE_BY_DAY_SCHEDULE = gql`
    query SeeByDaySchedule(
        $searchDate: String
        $searchTerm: String
        $orderBy: String
    ) {
        seeByDaySchedule(
            searchDate: $searchDate
            searchTerm: $searchTerm
            orderBy: $orderBy
        ) {
            title
            isOffDay
            offStartHour
            offStartMin
            offEndHour
            offEndMin
            totalOperTime
            startHour
            startMin
            endHour
            endMin
            lunchBreak
            lbStartHour
            lbStartMin
            lbEndHour
            lbEndMin
        }
    }
`;

export const SEE_BY_MONTH_SCHEDULE = gql`
    query SeeByMonthSchedule(
        $year: Int
        $month: Int
        $searchTerm: String
        $orderBy: String
    ) {
        seeByMonthSchedule(
            year: $year
            month: $month
            searchTerm: $searchTerm
            orderBy: $orderBy
        ) {
            title
            workdayCount
            offDayCount
            subDoctorDayCount
            byDateInfo {
                date
                offInfo
            }
        }
    }
`;

export const SEE_THIS_YEAR_RESERVATION_STATISTICS = gql`
    query SeeThisYearReservationStatistics {
        seeThisYearReservationStatistics {
            year
            manCount
            womanCount
            byMonthCount {
                month
                count
            }
        }
    }
`;

export const SEE_HOSPITAL_NOTICE_DETAIL = gql`
    query SeeHospitalNoticeDetail($hnId: Int!) {
        seeHospitalNoticeDetail(hn_id: $hnId) {
            hn_id
            hn_title
            hn_text
            hn_createdAt
            hn_creatorName
            hn_creatorRank
            hn_creatorImg
            hn_creatorId
            hnAttached {
                hna_id
                hna_url
                han_fileType
                han_fileSize
            }
            hnComment {
                hnc_id
                hnc_text
                hnc_createdAt
                hnc_creatorName
                hnc_creatorRank
                hnc_creatorImg
                hnc_creatorId
            }
        }
    }
`;

export const SEE_PLATFORM_NOTICE_DETAIL = gql`
    query SeePlatformNoticeDetail($pnId: Int!) {
        seePlatformNoticeDetail(pn_id: $pnId) {
            pn_id
            pn_createdAt
            pn_updatedAt
            pn_type
            pn_title
            pn_text
            pn_adminCreatorName
            pn_adminCreatorRank
            pn_adminCreatorId
            pnAttached {
                pna_id
                pna_url
                pna_fileType
                pna_fileSize
            }
            pnComment {
                pnc_id
                pnc_text
                pnc_createdAt
                pnc_updatedAt
                pnc_creatorName
                pnc_creatorRank
                pnc_creatorId
                pnc_creatorImg
                pnc_admin
            }
        }
    }
`;
