import React from "react";
import {MemoList, MemoTitle, MemoWrapper} from "./appointment.style";
import CommentBox from "../../share/CommentBox";

const MemoBox = ({ memoList }) => {
    return (
        <MemoWrapper>
            <MemoTitle>메모 기록</MemoTitle>
            <MemoList>
                {memoList.map(memo => (
                    <CommentBox
                        NO_HOVER
                        key={`${memo.commentId}-comment`}
                        commentId={memo.commentId}
                        creatorId={memo.creatorId}
                        creator={memo.creator}
                        creatorRank={memo.creatorRank}
                        creatorImage={memo.creatorImage}
                        createDate={memo.createDate}
                        comment={memo.comment}
                        // handleDeleteComment={() => {}}
                        // handleModifyComment={() => {}}
                    />
                ))}
            </MemoList>
        </MemoWrapper>
    )
}

export default MemoBox;
