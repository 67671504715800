import styled from "styled-components";
import {BoldText} from "../../components/styled/StyledText";
import theme from "../../styles/theme";

export const SiteMapBox = styled.div`
  display: flex;
  padding: 0 40px;
`;
export const SiteMapTitle = styled(BoldText)`
  padding: 50px 0 30px;
  margin: 0 40px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
