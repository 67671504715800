import {toast} from "react-toastify";

export const specialScheduleValidate = (startDate, endDate, inputs) => {
    const { adjustmentStartTime, adjustmentEndTime, type } = inputs;
    let valid;
    let startHour = parseInt(adjustmentStartTime?.split(':')?.[0], 10);
    let startMin = parseInt(adjustmentStartTime?.split(':')?.[1], 10);
    let endHour = parseInt(adjustmentEndTime?.split(':')?.[0], 10);
    let endMin = parseInt(adjustmentEndTime?.split(':')?.[1], 10);

    if (!startDate) {
        toast.error('조정일을 선택해주세요.');
        valid = true;
    } else if (
        !startHour ||
        (startMin !== 0 && !startMin) ||
        !endHour ||
        (endMin !== 0 && !endMin)
    ) {
        toast.error('조정시간을 확인해주세요.');
        valid = true;
    } else if (
        (startHour > 24 || startHour < 0) ||
        (endHour > 24 || endHour < 0) ||
        (startMin > 60 || startMin < 0) ||
        (endMin > 60 || endMin < 0)
    ) {
        toast.error('시간을 정확히 입력해주세요.');
        valid = true;
    }

    if (type !== '휴무 시간 추가' && !endDate) {
        toast.error('조정일을 선택해주세요.');
        valid = true;
    }

    return valid;
}

export const statusChange = status => {
    switch (status){
        case 'notSign':
            return '승인전';
        case 'sign':
            return '승인완료';
        case 'reject':
            return '반려';

        case '승인전':
            return 'notSign';
        case '승인완료':
            return 'sign';
        case '반려':
            return 'reject';
    }
}
