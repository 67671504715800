import React, {useCallback, useContext, useEffect, useState} from 'react';
import theme from "../../../../styles/theme";
import FullPageModalBackground from "../../../layout/FullPageModalBackground";
import {SettingContext} from "../../../../pages/Setting/setting";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {SEE_DOCTOR_ROOM_SCHEDULE} from "../../../../graphql/Setting/query";
import {doctorsVar} from "../../../../store";
import {EmphasisText, Hyphen, ScheduleList} from "./setting.modal.style";
import Loader from "../../../share/Loader";
import DayScheduleList from "./DayScheduleList";
import {RegularText} from "../../../styled/StyledText";
import StyledSelect from "../../../styled/StyledSelect";
import {
    dayKorChange,
    hourOption,
    hourToInteger,
    minuteOption,
    minuteToInteger
} from "../../../../pages/Setting/setting.lib";
import StyledFlexBox from "../../../styled/StyledFlexBox";
import StyledRadio from "../../../styled/StyledRadio";
import StyledButton from "../../../styled/StyledButton";
import {toast} from "react-toastify";
import {
    CREATE_DOCTOR_ROOM_SCHEDULE,
    DELETE_DOCTOR_ROOM_SCHEDULE,
    UPDATE_DOCTOR_ROOM_SCHEDULE
} from "../../../../graphql/Setting/mutation";
import {errorMessage} from "../../../../utils/commons";
import {operationTimeValidator} from "../../../../lib/validator";

const DoctorsOperationTimeModal = () => {
    const { siteMap, handleCloseSiteMap } = useContext(SettingContext);
    const doctorsOption = useReactiveVar(doctorsVar);

    const [inputs, setInputs] = useState({
        doctors: doctorsOption[0]?.dr_roomName,
        day: '월요일',
        startHour: '00시',
        startMin: '00분',
        endHour: '00시',
        endMin: '00분',
        doctorsIsLunchTime: 'true',
        lunchStartHour: '10시',
        lunchStartMin: '00분',
        lunchEndHour: '10시',
        lunchEndMin: '00분',
    });
    const [selectedId, setSelectedId] = useState(null);
    const [doctorRoomId, setDoctorRoomId] = useState(doctorsOption[0]?.dr_id);

    const { data, loading, refetch } = useQuery(SEE_DOCTOR_ROOM_SCHEDULE, {
        variables: {
            drId: doctorRoomId
        },
        fetchPolicy: 'network-only'
    });
    const [createDoctorRoomSchedule] = useMutation(CREATE_DOCTOR_ROOM_SCHEDULE);
    const [updateDoctorRoomSchedule] = useMutation(UPDATE_DOCTOR_ROOM_SCHEDULE);
    const [deleteDoctorRoomSchedule] = useMutation(DELETE_DOCTOR_ROOM_SCHEDULE);

    const handleReset = useCallback(() => {
        setInputs({
            ...inputs,
            day: '월요일',
            startHour: '00시',
            startMin: '00분',
            endHour: '00시',
            endMin: '00분',
            doctorsIsLunchTime: 'true',
            lunchStartHour: '10시',
            lunchStartMin: '00분',
            lunchEndHour: '10시',
            lunchEndMin: '00분',
        });
    }, [doctorsOption, inputs]);

    const handleClose = useCallback(() => {
        handleCloseSiteMap();
        setDoctorRoomId(doctorsOption[0]?.dr_id);
        handleReset();
        setSelectedId(null);
        setInputs({
            ...inputs,
            doctors:  doctorsOption[0]?.dr_roomName,
        });
    }, [handleReset, handleCloseSiteMap, doctorsOption, inputs]);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });

        if (name === 'doctors') {
            const drId = doctorsOption.find(room => room.dr_roomName === value)?.dr_id;
            setDoctorRoomId(drId);
            setSelectedId(null);
        }

    }, [inputs, doctorsOption]);

    const onChangeRadio = useCallback((e) => {
        const { value } = e.target;

        if (selectedId === parseInt(value, 10)) {
            setSelectedId(null);
            e.target.checked = false;
            return;
        }
        setSelectedId(parseInt(value, 10));
        e.target.checked = false;
    }, [selectedId]);

    const handleUpdateDoctorsSchedule = useCallback(async () => { // 진료실 운영시간 수정
        if (operationTimeValidator(
            inputs.startHour,
            inputs.startMin,
            inputs.endHour,
            inputs.endMin,
            inputs.lunchStartHour,
            inputs.lunchStartMin,
            inputs.lunchEndHour,
            inputs.lunchEndMin,
            inputs.doctorsIsLunchTime
        )) return;

        try {
            const {data} = await updateDoctorRoomSchedule({
                variables: {
                    drsId: selectedId,
                    day: dayKorChange(inputs.day),
                    startHour: hourToInteger(inputs.startHour),
                    startMin: minuteToInteger(inputs.startMin),
                    endHour: hourToInteger(inputs.endHour),
                    endMin: minuteToInteger(inputs.endMin),
                    lunchBreak: inputs.doctorsIsLunchTime === 'true',
                    lunchBreakStartHour: hourToInteger(inputs.lunchStartHour),
                    lunchBreakStartMin: minuteToInteger(inputs.lunchStartMin),
                    lunchBreakEndHour: hourToInteger(inputs.lunchEndHour),
                    lunchBreakEndMin: minuteToInteger(inputs.lunchEndMin),
                }
            });

            if (data.updateDoctorRoomSchedule) {
                toast.info('스케줄을 수정했습니다.');
                await refetch();
                setSelectedId(null);
                handleReset();
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('데이터 처리에 실패했습니다.\n잠시 후 다시 시도해주세요.');
                    break;
                case 'err_01':
                    toast.error('해당 요일에 등록된 일정이 있습니다.');
                    break;
            }
        }
    }, [inputs, selectedId, doctorsOption]);

    const handleCreateDoctorsSchedule = useCallback(async () => { // 진료실 운영시간 추가
        if (operationTimeValidator(
            inputs.startHour,
            inputs.startMin,
            inputs.endHour,
            inputs.endMin,
            inputs.lunchStartHour,
            inputs.lunchStartMin,
            inputs.lunchEndHour,
            inputs.lunchEndMin,
            inputs.doctorsIsLunchTime
        )) return;

        try {
            const {data} = await createDoctorRoomSchedule({
                variables: {
                    drId: doctorRoomId,
                    day: dayKorChange(inputs.day),
                    startHour: hourToInteger(inputs.startHour),
                    startMin: minuteToInteger(inputs.startMin),
                    endHour: hourToInteger(inputs.endHour),
                    endMin: minuteToInteger(inputs.endMin),
                    lunchBreak: inputs.doctorsIsLunchTime === 'true',
                    lunchBreakStartHour: hourToInteger(inputs.lunchStartHour),
                    lunchBreakStartMin: minuteToInteger(inputs.lunchStartMin),
                    lunchBreakEndHour: hourToInteger(inputs.lunchEndHour),
                    lunchBreakEndMin: minuteToInteger(inputs.lunchEndMin),
                }
            });

            if (data.createDoctorRoomSchedule) {
                toast.info('스케줄을 추가했습니다.');
                await refetch();
                handleReset();
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('데이터 처리에 실패했습니다.\n잠시 후 다시 시도해주세요.');
                    break;
                case 'err_01':
                    toast.error('해당 요일에 등록된 일정이 있습니다.');
                    break;
            }
        }
    }, [inputs, doctorsOption, doctorRoomId]);

    const handleDeleteDoctorsSchedule = useCallback(async id => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const {data} = await deleteDoctorRoomSchedule({
                variables: {
                    drsId: id
                }
            });

            if (data.deleteDoctorRoomSchedule) {
                toast.info('일정을 삭제했습니다.');
                await refetch();
                setSelectedId(null);
                handleReset();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    useEffect(() => {
        if (doctorsOption.length > 0) {
            setInputs({
                ...inputs,
                doctors: doctorsOption[0]?.dr_roomName
            });
            setDoctorRoomId(doctorsOption[0]?.dr_id);
        }
    }, [doctorsOption]);

    useEffect(() => {
        if (selectedId) {
            const findData = data?.seeDoctorRoomSchedule?.find(schedule => schedule.drs_id === selectedId);
            if (!findData) return;

            setInputs({
                ...inputs,
                day: `${dayKorChange(findData?.drs_day)}요일`,
                startHour: `${String(findData?.drs_startHour).padStart(2, '0')}시`,
                startMin: `${String(findData?.drs_startMin).padStart(2, '0')}분`,
                endHour: `${String(findData?.drs_endHour).padStart(2, '0')}시`,
                endMin: `${String(findData?.drs_endMin).padStart(2, '0')}분`,
                doctorsIsLunchTime: findData?.drs_lunchBreak ? 'true' : 'false',
                lunchStartHour: `${String(findData?.drs_lbStartHour).padStart(2, '0')}시`,
                lunchStartMin: `${String(findData?.drs_lbStartMin).padStart(2, '0')}분`,
                lunchEndHour: `${String(findData?.drs_lbEndHour).padStart(2, '0')}시`,
                lunchEndMin: `${String(findData?.drs_lbEndMin).padStart(2, '0')}분`,
            });
        }
    }, [data, selectedId]);

    return (
        <FullPageModalBackground
            // buttonTitle='진료실 운영 스케줄 등록'
            guideLine='설정 > 병원 운영 스케줄 등록'
            modalTitle='진료실 운영시간 설정'
            visible={siteMap === '진료실 운영시간 설정'}
            onClose={handleClose}
            // onClickButton={handleSave}
            buttonColor={theme.colors.activeBlue}>

            <EmphasisText $margin='30px 0 10px'>설정 스케줄</EmphasisText>
            <ScheduleList>
                {loading
                    ? <Loader/>
                    : data?.seeDoctorRoomSchedule?.map((list, index) => (
                        <DayScheduleList
                            key={`${list?.drs_id}-${index}-day-list`}
                            id={list?.drs_id}
                            day={list?.drs_day}
                            startHour={list?.drs_startHour}
                            startMin={list?.drs_startMin}
                            endHour={list?.drs_endHour}
                            endMin={list?.drs_endMin}
                            lunchBreak={list?.drs_lunchBreak}
                            lunchStartHour={list?.drs_lbStartHour}
                            lunchStartMin={list?.drs_lbStartMin}
                            lunchEndHour={list?.drs_lbEndHour}
                            lunchEndMin={list?.drs_lbEndMin}
                            handleDeleteSchedule={handleDeleteDoctorsSchedule}
                            selectedId={selectedId}
                            onChangeRadio={onChangeRadio}
                        />
                    ))}
            </ScheduleList>
            <RegularText $fontColor={theme.colors.activeBlue} $margin='30px 0 20px'>진료실별 운영시간</RegularText>

            <StyledFlexBox margin='20px 0'>
                <StyledSelect
                    title='진료실'
                    width={440}
                    borderRadius={4}
                    name='doctors'
                    value={inputs.doctors}
                    onChange={onChange}
                    options={doctorsOption.map(room => room.dr_roomName)}
                />
                <StyledSelect
                    title='요일선택'
                    width={440}
                    borderRadius={4}
                    margin='0 0 0 30px'
                    name='day'
                    value={inputs.day}
                    onChange={onChange}
                    options={['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일']}
                />
            </StyledFlexBox>

            <StyledFlexBox margin='20px 0'>
                <StyledSelect
                    title='시작시간'
                    width={205}
                    borderRadius={4}
                    name='startHour'
                    value={inputs.startHour}
                    onChange={onChange}
                    options={hourOption}
                />
                <Hyphen>-</Hyphen>
                <StyledSelect
                    width={205}
                    borderRadius={4}
                    margin='0 30px 0 0'
                    name='startMin'
                    value={inputs.startMin}
                    onChange={onChange}
                    options={minuteOption}
                />
                <StyledSelect
                    title='종료시간'
                    width={205}
                    borderRadius={4}
                    name='endHour'
                    value={inputs.endHour}
                    onChange={onChange}
                    options={hourOption}
                />
                <Hyphen>-</Hyphen>
                <StyledSelect
                    width={205}
                    borderRadius={4}
                    name='endMin'
                    value={inputs.endMin}
                    onChange={onChange}
                    options={minuteOption}
                />
            </StyledFlexBox>

            <RegularText $margin='20px 0'>점심시간 휴진 사용</RegularText>
            <StyledFlexBox margin='0 0 20px'>
                <StyledRadio
                    label='사용'
                    name='doctorsIsLunchTime'
                    value='true'
                    checked={inputs.doctorsIsLunchTime}
                    onChange={onChange}
                />
                <StyledRadio
                    margin='0 0 0 20px'
                    label='미사용'
                    name='doctorsIsLunchTime'
                    value='false'
                    checked={inputs.doctorsIsLunchTime}
                    onChange={onChange}
                />
            </StyledFlexBox>
            <StyledFlexBox margin='20px 0'>
                <StyledSelect
                    title='시작시간'
                    width={205}
                    borderRadius={4}
                    name='lunchStartHour'
                    value={inputs.lunchStartHour}
                    onChange={onChange}
                    options={['10시', '11시', '12시', '13시', '14시', '15시']}
                    disabled={inputs.doctorsIsLunchTime === 'false'}
                    // options={hourOption}
                />
                <Hyphen>-</Hyphen>
                <StyledSelect
                    width={205}
                    borderRadius={4}
                    margin='0 30px 0 0'
                    name='lunchStartMin'
                    value={inputs.lunchStartMin}
                    onChange={onChange}
                    options={minuteOption}
                    disabled={inputs.doctorsIsLunchTime === 'false'}
                />

                <StyledSelect
                    title='종료시간'
                    width={205}
                    borderRadius={4}
                    name='lunchEndHour'
                    value={inputs.lunchEndHour}
                    onChange={onChange}
                    options={['10시', '11시', '12시', '13시', '14시', '15시']}
                    disabled={inputs.doctorsIsLunchTime === 'false'}
                    // options={hourOption}
                />
                <Hyphen>-</Hyphen>
                <StyledSelect
                    width={205}
                    borderRadius={4}
                    name='lunchEndMin'
                    value={inputs.lunchEndMin}
                    onChange={onChange}
                    options={minuteOption}
                    disabled={inputs.doctorsIsLunchTime === 'false'}
                />
            </StyledFlexBox>
            <StyledButton
                title={`${selectedId ? '수정' : '추가'}하기`}
                width={214}
                margin='30px 0 200px'
                bgColor={selectedId ? theme.colors.activeOrange : theme.colors.activeBlue}
                onClick={selectedId ? handleUpdateDoctorsSchedule : handleCreateDoctorsSchedule}
            />
        </FullPageModalBackground>
    );
}

export default DoctorsOperationTimeModal;
