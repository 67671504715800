import React, {useCallback, useEffect, useRef} from 'react';
import DaumPostcode from "react-daum-postcode";
import styled from "styled-components";
import theme from "../../styles/theme";
import {IoClose} from "react-icons/io5";
import {BoldText} from "../styled/StyledText";

const Background = styled.div`
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
`;
const Content = styled.div`
  width: 600px;
  height: 560px;
  padding: 40px;
  border-radius: 20px;
  background: ${theme.colors.whiteColor};
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
`;
const PostBox = styled.div`
  margin-top: 20px;
`;
const Title = styled(BoldText)``;
const Header = styled.div`
  font-size: ${({fontSize}) => fontSize ? fontSize : 16}px;
  font-weight: ${({fontWeight}) => fontWeight ? fontWeight : 600};
  padding: ${({padding}) => padding ? padding : 0};
  margin: ${({margin}) => margin ? margin : 0};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CloseButton = styled.button`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-radius: 8px;

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }
`;

const DaumPostModal = ({setPostModal, handleComplete}) => {
    const bgRef = useRef(null);
    const modalRef = useRef(null);

    // 모달 밖 클릭 시 닫기
    const handleClickOutside = useCallback(e => {
        if (
            bgRef?.current?.contains(e.target) &&
            !modalRef?.current?.contains(e.target)
        ) {
            setPostModal(false);
        }
    }, [bgRef, modalRef]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    return (
        <Background ref={bgRef}>
            <Content>
                <Header>
                    <Title>주소검색</Title>
                    <CloseButton onClick={() => setPostModal(false)}>
                        <IoClose fontSize={24}/>
                    </CloseButton>
                </Header>
                <PostBox>
                    <DaumPostcode
                        autoClose
                        onComplete={handleComplete}
                    />
                </PostBox>
            </Content>
        </Background>
    );
}

export default DaumPostModal;
