import styled from "styled-components";
import {BoldText} from "../../components/styled/StyledText";
import theme from "../../styles/theme";

export const Wrapper = styled.div`
  height: calc(100% - 90px);
`;
export const SortingBox = styled.div`
  width: 100%;
  display: flex;
  align-content: flex-end;
  justify-content: space-between;
`;
export const FilterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const SortBox = styled.div`
  display: flex;
`;
export const IconBox = styled.div`
  display: flex;
`;
export const DateText = styled(BoldText)`
  font-size: 16px;
  margin: 20px 40px 30px;
`;
export const TableBody = styled.div`
  min-height: calc(100vh - 420px);
  padding: 0 40px 20px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
