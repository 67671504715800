import React, {useCallback} from 'react';
import styled, {css} from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    IoMenu,
    IoHomeOutline,
    IoCalendarClearOutline,
    IoTimeOutline,
    IoPersonOutline,
    IoDesktopOutline,
    IoBagAddOutline,
    IoHeadsetSharp,
    IoSettingsOutline, IoBuildOutline
} from 'react-icons/io5';
import theme from "../../styles/theme";
import logo from '../../assets/images/logo.png';

const Wrapper = styled.div`
  width: 76px;
  height: 100vh;
  padding: 26px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${theme.colors.activeIndigo};
`;
const TopMenu = styled.div`
  flex: 10;
`;
const TabButton = styled.div`
  width: 36px;
  height: 36px;
  color: ${theme.colors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({$margin}) => $margin ? $margin : '2px 0'};
  position: relative;
  transition: .25s;

  ${({$active}) => $active && css`
    color: ${theme.colors.activeBlue};
  `};
`;
const Logo = styled.div`
  width: 36px;
  height: 36px;
  margin-top: 56px;
  border-radius: 6px;
  overflow: hidden;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const BottomMenu = styled.div`
  flex: 1;
`;
export const Tooltip = styled.div`
  color: ${theme.colors.whiteColor};
  padding: 10px 16px;
  border-radius: 4px;
  position: absolute;
  z-index: 20;
  left: calc(100% + 10px);
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: .25s;
  background-color: ${theme.colors.activeBlue};

  &:after {
    content: '';
    position: absolute;
    bottom: 12px;
    left: -7px;
    border-top: 6px solid transparent;
    border-left: 0 solid transparent;
    border-right: 8px solid ${theme.colors.activeBlue};
    border-bottom: 6px solid transparent;
  }

  ${TabButton}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;
const Version = styled.div`
  font-size: 12px;
  text-align: center;
  line-height: 1.4;
  margin-top: 20px;
  color: ${theme.colors.whiteColor};
`;

const SideBar = () => {
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const handleTabButton = useCallback(uri => {
        navigate(uri);
    }, []);

    return (
        <Wrapper>
            <TopMenu>
                <Logo>
                    <Image src={logo}/>
                </Logo>

                <TabButton
                    $margin='60px 0'
                    $active={
                        pathname === '/home' ||
                        pathname === '/home/notice/hospital' ||
                        pathname === '/home/notice/platform'
                    }
                    onClick={() => handleTabButton('/home')}>
                    <IoHomeOutline fontSize={22}/>
                    <Tooltip>홈</Tooltip>
                </TabButton>
                <TabButton $active={pathname === '/appointment'} onClick={() => handleTabButton('/appointment')}>
                    <IoCalendarClearOutline fontSize={20}/>
                    <Tooltip>예약</Tooltip>
                </TabButton>
                <TabButton
                    $active={
                        pathname === '/schedule/operation' ||
                        pathname === '/schedule/special'
                    }
                    onClick={() => handleTabButton('/schedule/operation')}>
                    <IoTimeOutline fontSize={22}/>
                    <Tooltip>스케줄</Tooltip>
                </TabButton>
                <TabButton $active={pathname === '/patient'} onClick={() => handleTabButton('/patient')}>
                    <IoPersonOutline fontSize={22}/>
                    <Tooltip>환자 정보</Tooltip>
                </TabButton>
                <TabButton $active={pathname === '/did'} onClick={() => handleTabButton('/did')}>
                    <IoDesktopOutline fontSize={18}/>
                    <Tooltip>DID 모니터</Tooltip>
                </TabButton>
                <TabButton $active={pathname === '/insurance'} onClick={() => handleTabButton('/insurance')}>
                    <IoBagAddOutline fontSize={20}/>
                    <Tooltip>실손보험청구</Tooltip>
                </TabButton>
                <TabButton $margin='60px 0 0' $active={
                    pathname === '/service/center' ||
                    pathname === '/service/qna' ||
                    pathname === '/service/notice'
                }
                           onClick={() => handleTabButton('/service/center')}>
                    <IoHeadsetSharp fontSize={20}/>
                    <Tooltip>고객센터</Tooltip>
                </TabButton>
            </TopMenu>
            <BottomMenu>
                <TabButton $active={pathname === '/profile'} onClick={() => handleTabButton('/profile')}>
                    <IoBuildOutline fontSize={20}/>
                    <Tooltip>내 프로필</Tooltip>
                </TabButton>
                <TabButton
                    $active={
                        pathname === '/setting' ||
                        pathname === '/setting/day-off' ||
                        pathname === '/setting/manager'
                    }
                    onClick={() => handleTabButton('/setting')}>
                    <IoSettingsOutline fontSize={20}/>
                    <Tooltip>설정</Tooltip>
                </TabButton>
                <Version>
                    v.1.0.4
                </Version>
            </BottomMenu>
        </Wrapper>
    );
};

export default React.memo(SideBar);
