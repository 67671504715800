import styled, {css} from "styled-components";
import theme from "../../../styles/theme";
import {BoldText, RegularText} from "../../styled/StyledText";

// 예약 추가 모달
export const InputSection = styled.section`
  padding: 30px 0;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};

  &:last-child {
    border-bottom: none;
  }
`;
export const BlueTitle = styled(BoldText)`
  font-size: 16px;
  color: ${theme.colors.activeBlue};
  margin-bottom: 20px;
`;
export const NotificationTypeBox = styled.div`
  flex: 1;

  & + & {
    margin-left: 30px;
  }
`;

// 예약 목록 테이블 리스트
export const TableRow = styled.div`
  padding: 20px 0;
  display: flex;
  align-content: center;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
export const IconBox = styled.div`
  width: 42px;
  height: 42px;
  color: ${theme.colors.activeOrange};
  font-family: 'AppleSDGothicNeoBold';
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${theme.colors.lightWhiteBgColor};
`;
export const TableCell = styled.div`
  min-width: ${({$minWidth}) => $minWidth}px;
  width: ${({$width}) => $width}%;
  display: flex;
  align-items: center;
  margin-right: ${({$marginRight}) => $marginRight ? $marginRight : 40}px;

  ${({$column}) => $column && css`
    height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `};
`;
export const EmphasisText = styled(BoldText)`
  width: 90%;
  font-size: 16px;
  padding-bottom: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const DefaultText = styled(RegularText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const InlineEmphasisText = styled.span`
  font-family: 'AppleSDGothicNeoBold';
`;

// 예약 상세보기 모달
export const Contents = styled.div`
  height: calc(100% - 100px);
  overflow: scroll;
`;
export const TabBox = styled.div`
  width: calc(100% - 80px);
  height: 54px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  margin: 0 auto 30px;
  border-radius: 8px;
  background-color: ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;
`;
export const TabButton = styled.span`
  width: calc(100% / 2 - 4px);
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
`;
export const ActiveBackground = styled.div`
  content: '';
  width: calc(100% / 2 - 4px);
  height: calc(100% - 4px);
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: ${theme.colors.whiteColor};
  border-radius: 8px;
  transition: .25s;

  ${({$active}) => $active === 'memo' && css`
    transform: translateX(calc(100% + 2px));
  `};
`;
export const SectionTitle = styled(BoldText)`
  font-size: 16px;
  margin-bottom: 30px;
`;

// 예약 정보 박스
export const InfoWrapper = styled.div`
  height: calc(100% - 84px);
  padding: 0 40px 40px;
  display: flex;
  flex-direction: column;
`;
export const DetailInfoSection = styled.section`
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};

  & + & {
    flex: 1;
    margin-top: 30px;
    border-bottom: none;
  }
`;
export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;
export const InfoTitle = styled(RegularText)`
  flex: 1;
`;
export const InfoText = styled(RegularText)`
  flex: 3;
  text-align: right;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;
export const DetailStatusBox = styled.div`
  width: 110px;
  height: 40px;
  color: ${({$fontColor}) => $fontColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${({$bgColor}) => $bgColor};
`;
export const HistoryTableHeader = styled.div`
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;
export const HistoryTableCell = styled.div`
  flex: ${({$flex}) => $flex ? $flex : 2};
`;
export const HistoryTableBody = styled.div`
  min-height: 200px;
  //height: calc(100vh - 926px);
`;
export const HistoryTableRow = styled.div`
  border-radius: 8px;
  background-color: ${theme.colors.lightWhiteBgColor};
  cursor: pointer;
`;
export const HistoryInfoBox = styled.div`
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;
export const MoreViewArrow = styled.div`
  text-align: center;
  transition: .25s;

  ${({$active}) => $active && css`
    transform: rotate(-180deg);
  `};
`;
export const HistoryMemoBox = styled.div`
  min-height: 0;
  height: 0;
  padding: 0 20px;
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 1.4;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.lightWhiteBgColor};
  border-radius: 8px;
  background-color: ${theme.colors.whiteColor};
  overflow: hidden;
  transition: .25s;
  opacity: 0;

  ${({$active}) => $active && css`
    min-height: 60px;
    height: auto;
    padding: 20px;
    opacity: 1;
  `};;
`;
export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

// 메모 박스
export const MemoWrapper = styled.div`
  height: calc(100% - 44px);
`;
export const MemoTitle = styled(BoldText)`
  font-size: 16px;
  margin: 0 40px 30px;
`;
export const MemoList = styled.div`
  height: calc(100% - 46px);
  padding-bottom: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

// 예약 목록 달력 리스트
export const CalendarWrapper = styled.div`
  padding: 0 40px 100px;
  margin-top: 30px;
`;
export const CalendarTable = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
`;
export const CalendarTableHeader = styled.th`
  height: 32px;
  color: ${theme.colors.fontLightGrayColor};
  padding: 6px 0;
`;
export const CalendarTableRow = styled.tr``;
export const CalendarTableData = styled.td`
  width: calc(100% / 7);
  max-width: 242px;
  height: calc((100vh - 400px) / 6);
  padding: 12px 6px 6px;
  border: 1px solid ${({theme}) => theme.colors.ultraLightGrayBorder};
  box-sizing: border-box;
  position: relative;
  background-color: ${({$bgColor}) => $bgColor};
  cursor: ${({$cursor}) => $cursor ? $cursor : 'pointer'};
`;
export const CalendarDataHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin-bottom: 10px;
`;
export const CalendarDataBody = styled.div`
  min-height: 134px;
  height: calc(100% - 34px);
  max-height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;
export const AddIcon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  visibility: hidden;

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }

  ${CalendarTableData}:hover & {
    visibility: visible;
  }
`;
export const NumberBox = styled.div`
  display: flex;
`;
export const CountText = styled.span`
  margin-right: 20px;
`;
export const DateText = styled.span`
  color: ${({$fontColor}) => ($fontColor ? $fontColor : theme.colors.blackColor)};
  text-align: right;
  position: relative;
  z-index: 0;

  ${({$isToday}) => $isToday && css`
    color: ${theme.colors.whiteColor};

    &:before {
      content: '';
      width: 30px;
      height: 30px;
      position: absolute;
      z-index: -1;
      top: -6px;
      right: -8px;
      border-radius: 50%;
      background-color: ${theme.colors.activeOrange};
    }
  `};
`;
export const ScheduleBox = styled.div`
  width: 100%;
  height: 34px;
  padding: 4px 4px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 2px;
  border: ${({$border}) => $border};
  background-color: ${({$bgColor}) => $bgColor};
`;
export const PlatformIcon = styled.div`
  width: 27px;
  height: 27px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: ${theme.colors.whiteColor};
  background-image: ${({$bgImg}) => `url(${$bgImg})`};
  background-repeat: no-repeat;
  background-size: 28px 28px;
`;
export const BrieflyInfoText = styled.div`
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const TimeText = styled.span`
  font-size: 16px;
  font-family: 'AppleSDGothicNeoBold';
  margin-right: 4px;
`;

// 달력 상세보기 모달
export const CalendarDetailWrapper = styled.div`
  height: 100%;
  padding: 0 40px;
`;
export const FilterBox = styled.div`
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const ScheduleSection = styled.div`
  height: calc(100% - 188px);
  padding-top: 20px;
  overflow: scroll;
`;
export const HourList = styled.div`
  min-height: 76px;
  display: flex;
`;
export const HourText = styled.div`
  width: 46px;
  margin-right: 8px;
  transform: translateY(-8px);
`;
export const TimeScheduleBox = styled.div`
  width: calc(100% - 46px);
  border-top: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  .plus-schedule {
    display: none;
  }

  ${({$isEmpty}) => $isEmpty && css`
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${theme.colors.lightWhiteBgColor};

      .plus-schedule {
        display: flex;
      }
    }
  `};

  ${({$isSchedule}) => $isSchedule && css`
    .plus-schedule {
      height: 0;
      display: flex;
      opacity: 0;
      visibility: hidden;
      transition: .25s;
      margin: 0 auto;
    }
    
    &:hover {
      background-color: ${theme.colors.lightWhiteBgColor};

      .plus-schedule {
        height: 34px;
        opacity: 1;
        visibility: visible;
        margin: 10px auto;
      }
    }
  `};
`;
export const ClinicSchedule = styled.div`
  width: 100%;
  padding: 4px 4px 34px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: ${({$border}) => $border};
  background-color: ${({$bgColor}) => $bgColor};

  & + & {
    margin-top: 2px;
  }

  &:last-child {
    margin-bottom: 2px;
  }
`;
export const HoverWrapper = styled.div`
  width: 42px;
  height: 42px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  position: absolute;
  top: ${({$top}) => $top}px;
  right: 40px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  background-color: ${theme.colors.whiteColor};
  transition: .25s;

  ${ClinicSchedule}:hover & {
    z-index: 1;
    opacity: 1;
    visibility: visible;
  }
`;
export const WeekTableRow = styled.tr`
  width: 100%;
`;
export const WeekTableData = styled.td`
  width: 240px;
  max-width: 240px;
  height: 140px;
  padding: 4px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:first-child {
    width: 88px;
    border: none;
  }
`;
