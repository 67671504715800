import React, {memo, useRef, useState, useCallback, useEffect} from 'react';
import styled, {css} from 'styled-components';
import selectBottomArrow from '../../assets/icons/selectBottomArrow.svg';
import theme from "../../styles/theme";
import {RegularText} from "./StyledText";
import {colorChange} from "../../pages/DID/did.lib";

const Wrapper = styled.div`
  font-family: 'AppleSDGothicNeoMedium';
  position: relative;
  margin: ${({$margin}) => $margin};
  background-color: ${theme.colors.whiteColor};

  ${({ROW}) => ROW && css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;
const ColumnTitle = styled(RegularText)`
  margin-bottom: 8px;
`;
const RowTitle = styled(RegularText)`
  width: ${({$titleWidth}) => $titleWidth}px;
`;
const SelectBox = styled.div`
  width: ${({$width}) => $width}px;
  height: ${({$height}) => $height}px;
  padding: 0 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.colors.lightGrayBorder};
  border-radius: 4px;
  background-image: url(${selectBottomArrow});
  background-size: 7%;
  background-repeat: no-repeat;
  background-position: 90% 50%;
  cursor: pointer;

  ${({$optionVisible}) => $optionVisible && css`
    border: 1px solid ${theme.colors.lightGrayBorder};
  `};

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }

  &:active {
    background-color: darken(0.1, ${theme.colors.whiteHoverColor});
  }
`;
const Text = styled.div`
  width: 100%;
  font-size: ${({$fontSize}) => $fontSize}px;
  font-weight: ${({$fontWeight}) => ($fontWeight ? $fontWeight : 500)};
  font-family: ${({$fontFamily}) => $fontFamily};
  color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
`;
const OptionBox = styled.div`
  width: ${({$width}) => $width}px;
  min-height: 46px;
  max-height: ${({$maxHeight}) => $maxHeight}px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: absolute;
  top: 73px;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
  border: 1px solid ${theme.colors.lightGrayBorder};
  border-radius: 4px;
  background-color: ${theme.colors.whiteColor};
`;
const OptionText = styled.div`
  width: 100%;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
  font-size: ${({$fontSize}) => $fontSize ? $fontSize : 16}px;
  font-weight: ${({$fontWeight}) => $fontWeight};
  word-break: keep-all;
  line-height: 1.2;
  cursor: pointer;

  &:hover {
    color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
    background-color: ${theme.colors.whiteHoverColor};
  }
`;
const ColorPalette = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-color: ${({ $bgColor }) => $bgColor};
`;

const StyledCustomSelect = ({
                                COLOR,
                                ROW,
                                width = 210,
                                height = 50,
                                maxHeight = 600,
                                fontSize = 16,
                                fontFamily = 'AppleSDGothicNeoMedium',
                                margin,
                                title,
                                name,
                                value,
                                onChange,
                                options = []
                            }) => {
    const boxRef = useRef(null);
    const optRef = useRef(null);
    const [optionVisible, setOptionVisible] = useState(false);

    const onClickOutSide = useCallback(({target}) => {
        let boxRefCurrent = boxRef.current && boxRef?.current.contains(target);
        let optRefCurrent = optRef.current && optRef?.current.contains(target);

        if (!boxRefCurrent && !optRefCurrent) setOptionVisible(false);
    }, [boxRef, optRef]);

    const onClickOption = useCallback(value => {
        onChange({target: {name, value}});
        setOptionVisible(false);
    }, [onChange, name]);

    useEffect(() => {
        window.addEventListener('click', onClickOutSide);
        return () => window.removeEventListener('click', onClickOutSide);
    }, [onClickOutSide]);

    if (ROW) {
        return (
            <Wrapper ROW={ROW} margin={margin}>
                <RowTitle>{title}</RowTitle>
                <SelectBox
                    $width={width}
                    $height={height}
                    ref={boxRef}
                    $optionVisible={optionVisible}
                    onClick={() => setOptionVisible(!optionVisible)}>
                    <ColorPalette $bgColor={colorChange(value)} />
                    <Text $fontSize={fontSize} $fontFamily={fontFamily}>{value}</Text>
                </SelectBox>
                {optionVisible && (
                    <OptionBox ref={optRef} $width={width} $height={height} $maxHeight={maxHeight}>
                        {options.map((option, index) => (
                            <OptionText key={`${index}-option}`} $fontSize={fontSize}
                                        onClick={() => onClickOption(option)}>
                                {COLOR && (
                                    <>
                                        <ColorPalette $bgColor={colorChange(option)} /> {option}
                                    </>
                                )}
                            </OptionText>
                        ))}
                    </OptionBox>
                )}
            </Wrapper>
        )
    }

    return (
        <Wrapper margin={margin}>
            <ColumnTitle>{title}</ColumnTitle>
            <SelectBox
                $width={width}
                $height={height}
                ref={boxRef}
                $optionVisible={optionVisible}
                onClick={() => setOptionVisible(!optionVisible)}>
                <ColorPalette $bgColor={colorChange(value)} />
                <Text $fontSize={fontSize} $fontFamily={fontFamily}>{value}</Text>
            </SelectBox>
            {optionVisible && (
                <OptionBox ref={optRef} $width={width} $height={height} $maxHeight={maxHeight}>
                    {options.map((option, index) => (
                        <OptionText key={`${index}-option}`} $fontSize={fontSize} onClick={() => onClickOption(option)}>
                            {COLOR && (
                                <>
                                    <ColorPalette $bgColor={colorChange(option)} /> {option}
                                </>
                            )}
                        </OptionText>
                    ))}
                </OptionBox>
            )}
        </Wrapper>
    );
};

export default memo(StyledCustomSelect);
