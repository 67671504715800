import { gql } from '@apollo/client';

export const SEE_RESERVATION_LIST = gql`
    query SeeReservationList(
        $searchTerm: String
        $searchDate: String
        $status: reStatus
        $doctorRoom: String
        $resPlatform: rePlatform
        $visitConfirm: Boolean
        $largeCategory: String
        $take: Int
        $cursor: Int
    ) {
        seeReservationList(
            searchTerm: $searchTerm
            searchDate: $searchDate
            status: $status
            doctorRoom: $doctorRoom
            resPlatform: $resPlatform
            visitConfirm: $visitConfirm
            largeCategory: $largeCategory
            take: $take
            cursor: $cursor
        ) {
            totalLength
            reservationList {
                re_id
                resDate
                desireDate
                desireTime
                time
                doctorRoomName
                visitCount
                patientName
                patientRrn
                patientCellphone
                recentlyVisitDate
                patientChartNumber
                oneLineMemo
                platform
                status
                largeCategory
                smallCategory
                alimType
                alimTime1
                alimTime2
                alimTime3
                alimTime4
                template
            }
        }
    }
`;

export const SEE_MEDICAL_SUBJECT = gql`
    query SeeMedicalSubject {
        seeMedicalSubject {
            totalLength
            medicalSubjectList {
                ms_id
                ms_name
            }
        }
    }
`;

export const SEE_RESERVATION_PATIENT_DETAIL = gql`
    query ReservationInfo($reId: Int!) {
        seeReservationPatientDetail(re_id: $reId) {
            reservationInfo {
                re_id
                resDate
                desireDate
                desireTime
                time
                patientName
                patientCellphone
                platform
                status
                approvalName
                approvalDate
                largeCategory
                smallCategory
                alimType
                alimTime1
                alimTime2
                alimTime3
                alimTime4
                template
                doctorRoomName
                dr_deptCode
                patientChartNumber
                patientRrn
                oneLineMemo
            }
            resHistoryList {
                resDate
                resTime
                resStatus
                oneLineMemo
            }
            patientMemoList {
                prm_id
                prm_text
                prm_createdAt
                prm_creatorId
                prm_creatorName
                prm_creatorRank
                prm_creatorImg
            }
        }
    }
`;

export const SEE_BY_MONTH_RESERVATION_LIST = gql`
    query SeeByMonthReservationList(
        $searchTerm: String
        $year: Int
        $month: Int
        $status: reStatus
        $doctorRoom: String
        $resPlatform: rePlatform
        $visitConfirm: Boolean
        $largeCategory: String
    ) {
        seeByMonthReservationList(
            searchTerm: $searchTerm
            year: $year
            month: $month
            status: $status
            doctorRoom: $doctorRoom
            resPlatform: $resPlatform
            visitConfirm: $visitConfirm
            largeCategory: $largeCategory
        ) {
            date
            totalResCount
            byDateReservationInfo {
                re_id
                re_platform
                re_time
                re_patientName
                re_LCategory
                re_SCategory
                re_status
            }
        }
    }
`;

export const SEE_DATE_RESERVATION_DETAIL = gql`
    query SeeDateReservationDetail(
        $year: Int!
        $month: Int!
        $date: Int!
        $doctorRoom: String
        $resPlatform: rePlatform
    ) {
        seeDateReservationDetail(
            year: $year
            month: $month
            date: $date
            doctorRoom: $doctorRoom
            resPlatform: $resPlatform
        ) {
            totalResCount
            byDateReservationDetailInfo {
                re_id
                re_platform
                re_time
                re_patientName
                re_doctorRoomName
                re_LCategory
                re_SCategory
                re_status
            }
        }
    }
`;

export const SEE_BY_WEEK_RESERVATION_LIST = gql`
    query SeeByWeekReservationList(
        $searchTerm: String
        $year: Int
        $month: Int
        $date: Int
        $status: reStatus
        $doctorRoom: String
        $resPlatform: rePlatform
        $visitConfirm: Boolean
        $largeCategory: String
    ) {
        seeByWeekReservationList(
            searchTerm: $searchTerm
            year: $year
            month: $month
            date: $date
            status: $status
            doctorRoom: $doctorRoom
            resPlatform: $resPlatform
            visitConfirm: $visitConfirm
            largeCategory: $largeCategory
        ) {
            year
            month
            date
            byDateReservationInfo {
                re_id
                re_platform
                re_time
                re_status
                re_patientName
                re_LCategory
                re_SCategory
            }
        }
    }
`;
