import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import theme from "../../styles/theme";
import noUser from "../../assets/images/noUser.png";
import platformLogo from "../../assets/images/plaformLogo.png";
import {BoldText, RegularText} from "../styled/StyledText";
import dayjs from "dayjs";
import HoverMenu from "./HoverMenu";
import {useReactiveVar} from "@apollo/client";
import {userVar} from "../../store";
import {timeCalculator} from "../../utils/commons";

const Wrapper = styled.div`
  display: flex;
  padding: 15px 40px;
  position: relative;
  background-color: ${theme.colors.whiteColor};

  ${({$isCreator}) => $isCreator && css`
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.ultraLightGrayBorder};
    }
  `};
`;
const ImageBox = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 16px 0 0;
  border-radius: 8px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  overflow: hidden;
  
  ${({ $isAdmin }) => $isAdmin && css`
    border-radius: 0;
    border: none;
  `};
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Contents = styled.div`
  width: calc(100% - 48px);
`;
const Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
`;
const UserName = styled(BoldText)`
  font-size: 16px;
  margin-right: 16px;
`;
const DateText = styled(RegularText)`
  color: ${theme.colors.fontGrayColor}
`;
const CommentText = styled(RegularText)`
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 1.4;
`;
const HoverWrapper = styled.div`
  width: 42px;
  height: 42px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  position: absolute;
  //top: ${({$top}) => $top}px;
  right: 40px;
  transform: translateY(-34px);
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  background-color: ${theme.colors.whiteColor};
  transition: .25s;
  
  ${Wrapper}:hover & {
    z-index: 1;
    opacity: 1;
    visibility: visible;
  }
`;

const CommentBox = ({
                        NO_HOVER,
                        spareTop = 24,
                        commentId,
                        creatorId,
                        creator = '',
                        creatorRank = '',
                        creatorImage = '',
                        createDate = new Date(),
                        comment = '',
                        isAdmin = false,
                        handleDeleteComment,
                        handleModifyComment,
                        listRef
                    }) => {
    const hoverRef = useRef();
    const [top, setTop] = useState(null);
    const [timeText, setTimeText] = useState('');
    const user = useReactiveVar(userVar);
    const now = dayjs();

    // const onMouseOver = useCallback(e => {
    //     if (e.target === hoverRef.current) {
    //         const windowHeight = window.innerHeight;
    //         const listHeight = listRef.current.getBoundingClientRect().height; // 리스트 높이
    //         const scrollHeight = listRef.current.scrollHeight; // 스크롤 전체 DOM height
    //         const scrollTop = listRef.current.scrollTop; // 스크롤 된 지점
    //         const offsetTop = e.target.offsetTop;
    //         const relativeTop = e.target.getBoundingClientRect().top;
    //
    //         let result = Math.floor(scrollTop + offsetTop);
    //         // const result = Math.floor(windowHeight - e.target.getBoundingClientRect().top - (scrollHeight - scrollTop));
    //
    //
    //         if (result < listHeight) {
    //             setTop(-40);
    //
    //         } else { // 스크롤 된 범위보다 높다면
    //             // result = Math.floor(-(relativeTop - scrollTop));
    //             // // result = Math.floor(scrollHeight - listHeight - (relativeTop));
    //             // // result = Math.floor(relativeTop - scrollHeight - offsetTop);
    //             //
    //             // setTop(result);
    //             // // setTop(0);
    //         }
    //
    //         // const result = Math.floor(e.target.getBoundingClientRect().top - spareTop);
    //         // setTop(Math.abs(result));
    //     }
    // }, [hoverRef, spareTop, listRef]);

    useEffect(() => {
        let duration = now.diff(createDate, 'second');
        setTimeText(timeCalculator(duration));
    }, [createDate, now]);

    useEffect(() => {
        return () => setTop(null);
    }, []);

    return (
        <Wrapper
            $isCreator={(!NO_HOVER && user.user_id === creatorId)}
            ref={hoverRef}
            // onMouseOver={onMouseOver}
        >
            <ImageBox $isAdmin={isAdmin}>
                <Image
                    src={isAdmin ? platformLogo : creatorImage}
                    onError={e => e.target.src = noUser}/>
            </ImageBox>
            <Contents>
                <Header>
                    <UserName>{creator} {creatorRank}</UserName>
                    <DateText>{timeText}</DateText>
                </Header>
                <CommentText>
                    {comment}
                </CommentText>
            </Contents>

            {(!NO_HOVER && user.user_id === creatorId) && (
                <HoverWrapper $top={top}>
                    <HoverMenu
                        handleModifyComment={() => handleModifyComment(commentId)}
                        handleDeleteComment={() => handleDeleteComment(commentId)}
                    />
                </HoverWrapper>
            )}
        </Wrapper>
    )
}

export default CommentBox;
