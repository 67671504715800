import styled, {css} from "styled-components";
import theme from "../../styles/theme";
import {BoldText, RegularText} from "../../components/styled/StyledText";

// 고객센터
export const ServiceCenterSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 40px 20px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  
  & + & {
    border-bottom: none;
  }
`;
export const FaqBox = styled.div`
  flex: 4;
  margin-right: 40px;
`;
export const FaqList = styled.div`
  height: 342px;
  margin-top: 20px;
  overflow-y: scroll;
`;
export const PercentBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;
export const StatsBox = styled.div`
  width: 250px;
  height: 360px;
  padding: 30px;
  border-radius: 8px;
  border: ${({$border}) => $border ? $border : 'none'};
  background-color: ${({$bgColor}) => $bgColor ? $bgColor : theme.colors.lightWhiteBgColor};

  & + & {
    margin-left: 30px;
  }
`;
export const CountText = styled(BoldText)`
  font-size: 46px;
  margin: 30px 0 8px;
`;
export const SubCountBox = styled.div`
  & + & {
    margin-left: 20px;
  }
`;
export const GrayText = styled.div`
  color: ${theme.colors.fontLightGrayColor};
  margin: ${({$margin}) => $margin ? $margin : 0};
  line-height: 1.2;
`;

export const ImageBox = styled.div`
  width: 80px;
  height: 80px;
`;
export const Image = styled.img``;

// 각 Rank 별 FAQ 리스트
export const RankListBox = styled.div`
  flex: 1;
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }
`;
export const RankTitle = styled(BoldText)`
  font-size: 16px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
`;
export const RankTableRow = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
export const TrophyBox = styled.div`
  width: 42px;
  height: 42px;
  padding: 10px;
  margin-right: 30px;
  border-radius: 8px;
  background-color: ${theme.colors.lightWhiteBgColor};
`;
export const FaqTitle = styled.span`
  width: 80%;
  font-family: 'AppleSDGothicNeoRegular';
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

// 긴급공지
export const NoticeSection = styled.section`
  padding: 30px 0;
`;
export const NoticeFilterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 40px;
`;
export const FilterBox = styled.div`
  display: flex;
`;
export const TabButton = styled.div`
  font-family: 'AppleSDGothicNeoBold';
  color: ${theme.colors.grayBorder};
  margin-right: 20px;
  transition: .25s;
  cursor: pointer;
  
  ${({ $active }) => $active && css`
    color: ${theme.colors.blackColor};
  `}; 
`;
export const NoticeListBox = styled.div`
  //border: 1px solid red;
  margin-top: 10px;
`;
export const EmphasisText = styled(BoldText)`
  width: 90%;
  font-size: 16px;
  text-overflow: ellipsis;
  padding-bottom: 1px;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
export const DefaultText = styled(RegularText)`
  width: 90%;
  font-size: 16px;
  color: ${({ $fontColor }) => $fontColor ? $fontColor : theme.colors.blackColor};
  text-overflow: ellipsis;
  overflow: hidden;
  margin: ${({$margin}) => $margin ? $margin : 0};
`;
