import React from "react";
import styled, {css} from "styled-components";
import theme from "../../styles/theme";

const ToggleBox = styled.div`
  display: flex;
  align-items: center;
  & * {
    cursor: pointer;
  }
`;
const CheckBox = styled.input``;
const Label = styled.label`
  width: 34px;
  height: 20px;
  display: block;
  position: relative;
  border-radius: 30px;
  transition: all .15s ease-in;
  background-color: ${({checked}) => checked ? theme.colors.activeGreen : theme.colors.grayBorder};
`;
const ToggleButton = styled.span`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 1px;
  transform: translateY(-50%);
  transition: all .15s ease-in;
  border-radius: 50%;
  background: ${({theme}) => theme.colors.whiteColor};

  ${({checked}) => checked && css`
    left: calc(100% - 19px);
  `};
`;

const ToggleSwitch = ({
                          name = '',
                          value = false,
                          onChange = () => null,
                      }) => {
    return (
        <ToggleBox>
            <CheckBox id={name} name={name} type="checkbox" checked={value} onChange={onChange} hidden/>
            <Label htmlFor={name} checked={value}>
                <ToggleButton checked={value}/>
            </Label>
        </ToggleBox>
    )
}

export default React.memo(ToggleSwitch);
