export const insuranceChange = value => {
    switch (value) {
        // 보험사
        case '전체':
            return 'total';
        case '투비콘':
            return 'tobecon';
        case '하이웹넷':
            return 'hiweb';

        case 'tobecon':
            return '투비콘';
        case 'hiweb':
            return '하이웹넷';

        // 상태
        case 'processing':
            return '진행중';
        case 'complete':
            return '완료';
        case 'fail':
            return '실패';
        default:
            return value;

    }
}
