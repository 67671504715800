import React from 'react';
import {SiteMapWrapper, Title, GrayText} from "./setting.style";
import StyledButton from "../../styled/StyledButton";
import theme from "../../../styles/theme";

const GuideBox = ({
                      imageText,
                      title,
                      subTitle,
                      onClickShortcut = () => null
                  }) => {

    return (
        <SiteMapWrapper>
            <Title>
                {imageText}&nbsp;&nbsp;&nbsp;{title}
            </Title>
            <GrayText>
                {subTitle}
            </GrayText>

            <StyledButton
                title='바로가기'
                height={38}
                fontFamily='AppleSDGothicNeoMedium'
                fontColor={theme.colors.blackColor}
                margin='30px 0 0'
                border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                bgColor={theme.colors.whiteColor}
                onClick={onClickShortcut}
            />
        </SiteMapWrapper>
    );
}

export default GuideBox;
