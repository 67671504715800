import React, {createContext, useCallback, useState} from "react";
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {useQuery} from '@apollo/client';
import StatsCount from "../../../components/feature/Home/StatsCount";
import {IoArrowForwardOutline, IoChevronBackOutline, IoChevronForwardOutline} from 'react-icons/io5';
import StyledFlexBox from '../../../components/styled/StyledFlexBox';
import {
    SEE_DASHBOARD_COUNT,
    SEE_HOSPITAL_NOTICE, SEE_PLATFORM_NOTICE,
    SEE_THIS_YEAR_RESERVATION_STATISTICS,
    SEE_TODAY_RESERVATION
} from '../../../graphql/Home/query';
import Loader from '../../../components/share/Loader';
import NoticeList from '../../../components/feature/Home/NoticeList';
import {BoldText, RegularText} from "../../../components/styled/StyledText";
import {appointmentStateChange} from "./main.lib";
import theme from "../../../styles/theme";
import OperationDaySchedule from "../../../components/share/OperationDaySchedule";
import OperationMonthSchedule from "../../../components/share/OperationMonthSchedule";
import LineChart from "../../../components/feature/Home/LineChart";
import {
    HomeWrapper,
    InformationSection,
    StatsSection,
    NoticeBox,
    TodayPatientBox,
    GrayText,
    PlatformSelectBox,
    DetailViewButton,
    ListTable,
    PatientList,
    PatientInfo,
    StateBox,
    StateColor,
    OperationScheduleSection,
    ScheduleFilter,
    ArrowBox,
    DateFilterBox,
    DateBox, ChartSection, ChartBox
} from './main.styles';
import CircleChart from "../../../components/feature/Home/CircleChart";

export const MainContext = createContext(null);

const Main = () => {
    const navigate = useNavigate();
    const [noticeType, setNoticeType] = useState('사내공지');
    const [scheduleFilter, setScheduleFilter] = useState('월');
    const [date, setDate] = useState(dayjs());

    // 대시보드 통계
    const {data: statsData, loading: statsLoading} = useQuery(SEE_DASHBOARD_COUNT);
    // 사내 공지사항
    const {data: noticeData, loading: noticeLoading, refetch} = useQuery(SEE_HOSPITAL_NOTICE, {
        variables: {
            year: dayjs().get('year'),
            filter: 'desc',
            take: 10,
            cursor: 0
        }
    });
    // 플랫폼 공지사항
    const {data: platformData, loading: platformLoading} = useQuery(SEE_PLATFORM_NOTICE, {
        variables: {
            searchTerm: '',
            filter: 'total',
            year: dayjs().get('year'),
            take: 10,
            cursor: 0
        }
    })
    // 오늘 예약 환자
    const {data: patientData, loading: patientLoading} = useQuery(SEE_TODAY_RESERVATION);
    // 차트
    const {data: chartData, loading: chartLoading} = useQuery(SEE_THIS_YEAR_RESERVATION_STATISTICS);

    const handleIncreaseDate = useCallback(() => {
        switch (scheduleFilter) {
            case '일':
                setDate(dayjs(date).add(1, 'day'));
                break;
            case '월':
                setDate(dayjs(date).add(1, 'month'));
                break;
        }
    }, [scheduleFilter, date]);

    const handleDecreaseDate = useCallback(() => {
        switch (scheduleFilter) {
            case '일':
                setDate(dayjs(date).subtract(1, 'day'));
                break;
            case '월':
                setDate(dayjs(date).subtract(1, 'month'));
                break;
        }
    }, [scheduleFilter, date]);

    return (
        <MainContext.Provider value={{}}>
            <HomeWrapper>
                <StatsSection>
                    {statsLoading
                        ? <Loader/>
                        : (
                            <>
                                <StatsCount
                                    title='이번달 예약 환자'
                                    count={statsData?.seeDashBoardCount?.thisMonthResCount}
                                />
                                <StatsCount
                                    title='이번주 예약 환자'
                                    count={statsData?.seeDashBoardCount?.thisWeekResCount}
                                />
                                <StatsCount
                                    title='오늘 예약 환자'
                                    count={statsData?.seeDashBoardCount?.todayResCount}
                                />
                                <StatsCount
                                    title='이번달 특별일정'
                                    count={statsData?.seeDashBoardCount?.thisMonthSpecialScheduleCount}
                                />
                                <StatsCount
                                    title='원내 공지사항'
                                    unit='건'
                                    count={statsData?.seeDashBoardCount?.hospitalNoticeCount}
                                />
                            </>
                        )}
                </StatsSection>
                <InformationSection>
                    <NoticeBox>
                        <GrayText>공지사항</GrayText>
                        <StyledFlexBox alignItems="center" margin="6px 0 40px">
                            <PlatformSelectBox
                                value={noticeType}
                                onChange={e => setNoticeType(e.target.value)}
                            >
                                <option value='사내공지'>사내공지</option>
                                <option value='플랫폼공지'>플랫폼공지</option>
                            </PlatformSelectBox>
                            <DetailViewButton onClick={() => navigate('/home/notice/hospital')}>
                                <IoArrowForwardOutline fontSize={18}/>
                            </DetailViewButton>
                        </StyledFlexBox>
                        <ListTable>
                            {noticeLoading
                                ? <Loader/>
                                : noticeType === '사내공지' && noticeData?.seeHospitalNotice?.hospitalNoticeList.map(notice => (
                                <NoticeList
                                    key={`${notice.hn_id}-company-notice-list`}
                                    title={notice?.hn_title}
                                    creator={notice?.hn_creatorName}
                                    creatorRank={notice?.hn_creatorRank}
                                    createDate={notice?.hn_createdAt}
                                    content={notice?.hn_text}
                                    commentLength={notice?.hnComment?.length}
                                />
                            ))}
                            {platformLoading
                                ? <Loader/>
                                : noticeType === '플랫폼공지' && platformData?.seePlatformNotice?.platformNotice.map(notice => (
                                <NoticeList
                                    key={`${notice.pn_id}-platform-notice-list`}
                                    title={notice?.pn_title}
                                    creator={notice?.pn_adminCreateName}
                                    creatorRank={notice?.pn_adminCreatorRank}
                                    createDate={notice?.pn_createdAt}
                                    content={notice?.pn_text}
                                    commentLength={notice?.pnComment?.length}
                                />
                            ))}
                        </ListTable>
                    </NoticeBox>

                    <TodayPatientBox>
                        <StyledFlexBox justifyContent='space-between' margin='0 0 32px'>
                            <BoldText $fontColor={theme.colors.activeIndigo}>오늘의 예약 환자</BoldText>
                            <BoldText>{patientData?.seeTodayReservation?.totalResCount || 0} 명</BoldText>
                        </StyledFlexBox>
                        <ListTable>
                            {patientLoading
                                ? <Loader/>
                                : patientData?.seeTodayReservation?.todayResInfo?.map(patient => (
                                    <PatientList key={`${patient?.re_id}-patient-list`}>
                                        <PatientInfo>
                                            <BoldText $fontSize={16} $margin='0 0 4px'>
                                                {patient?.re_time && parseInt(patient?.re_time.split(':')[0], 10) < 12 ? '오전' : '오후'} {patient?.re_time || '-'}
                                            </BoldText>
                                            <RegularText>
                                                {patient?.re_patientName || '-'}
                                            </RegularText>
                                        </PatientInfo>
                                        <StateBox>
                                            <GrayText>예약{appointmentStateChange(patient?.re_status).text}</GrayText>
                                            <StateColor
                                                $bgColor={appointmentStateChange(patient?.re_status).color}
                                            />
                                        </StateBox>
                                    </PatientList>
                                ))}
                        </ListTable>
                    </TodayPatientBox>
                </InformationSection>
                <OperationScheduleSection>
                    <ScheduleFilter>
                        <StyledFlexBox>
                            <BoldText $fontColor={theme.colors.activeIndigo}>운영 스케줄</BoldText>
                            <StyledFlexBox margin='2px 0 0 12px'>
                                <ArrowBox onClick={handleDecreaseDate}>
                                    <IoChevronBackOutline/>
                                </ArrowBox>
                                <ArrowBox onClick={handleIncreaseDate}>
                                    <IoChevronForwardOutline/>
                                </ArrowBox>
                            </StyledFlexBox>
                        </StyledFlexBox>
                        <DateFilterBox>
                            <DateBox $active={scheduleFilter === '일'} onClick={() => setScheduleFilter('일')}>일</DateBox>
                            <DateBox $active={scheduleFilter === '월'} onClick={() => setScheduleFilter('월')}>월</DateBox>
                        </DateFilterBox>
                    </ScheduleFilter>

                    {scheduleFilter === '일' && <OperationDaySchedule date={date}/>}
                    {scheduleFilter === '월' && <OperationMonthSchedule date={date}/>}

                </OperationScheduleSection>
                <ChartSection>
                    <BoldText $fontColor={theme.colors.activeIndigo}>통계</BoldText>
                    {chartLoading
                        ? <Loader/>
                        : (
                            <ChartBox>
                                <LineChart data={chartData?.seeThisYearReservationStatistics}/>
                                <CircleChart data={chartData?.seeThisYearReservationStatistics}/>
                            </ChartBox>
                        )
                    }
                </ChartSection>
            </HomeWrapper>
        </MainContext.Provider>
    )
}

export default Main;
