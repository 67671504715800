import React, {createContext, useCallback, useEffect, useLayoutEffect, useState} from "react";
import PageHeader from "../../components/layout/PageHeader";
import {FilterBox, IconBox, Wrapper} from "./schedule.style";
import {useNavigate, Outlet, useLocation} from "react-router-dom";
import StyledFlexBox from "../../components/styled/StyledFlexBox";
import StyledCalendar from "../../components/styled/StyledCalendar";
import StyledIconButton from "../../components/styled/StyledIconButton";
import {DateBox, DateFilterBox} from "../Home/Main/main.styles";
import dayjs from "dayjs";
import SearchModal from "../../components/share/SearchModal";
import {toast} from "react-toastify";
import {useReactiveVar} from "@apollo/client";
import {userVar} from "../../store";

export const ScheduleContext = createContext(null);

const Schedule = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const user = useReactiveVar(userVar);
    const [date, setDate] = useState(new Date());
    const [activeTab, setActiveTab] = useState('운영 스케줄');
    const [scheduleFilter, setScheduleFilter] = useState('월');
    const [sortType, setSortType] = useState('desc');
    const [searchTerm, setSearchTerm] = useState('');
    // 모달
    const [searchModal, setSearchModal] = useState(false);
    const [scheduleModal, setScheduleModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleTabClick = useCallback(type => {
        switch (type) {
            case '운영 스케줄':
                navigate('/schedule/operation');
                break;
            case '특별 일정':
                navigate('/schedule/special');
                break;
        }
        setActiveTab(type);
    }, []);

    const handleSort = useCallback(() => {
        if (sortType === 'desc') {
            setSortType('asc');
        } else {
            setSortType('desc');
        }
    }, [sortType]);

    const handlePrevMonth = useCallback(() => { // 이전 달 클릭
        if (scheduleFilter === '월') {
            const prevMonth = dayjs(date).subtract(1, 'month');
            setDate(new Date(prevMonth.format('YYYY-MM-DD')));
        } else {
            const prevMonth = dayjs(date).subtract(1, 'day');
            setDate(new Date(prevMonth.format('YYYY-MM-DD')));
        }
    }, [date, scheduleFilter]);

    const handleNextMonth = useCallback(() => { // 다음 달 클릭
        if (scheduleFilter === '월') {
            const nextMonth = dayjs(date).add(1, 'month');
            setDate(new Date(nextMonth.format('YYYY-MM-DD')));
        } else {
            const nextMonth = dayjs(date).add(1, 'day');
            setDate(new Date(nextMonth.format('YYYY-MM-DD')));
        }
    }, [date, scheduleFilter]);

    useEffect(() => {
        if (activeTab === '특별 일정') {
            setScheduleFilter('월');
        }
    }, [activeTab]);

    useEffect(() => {
        if (location.pathname === '/schedule/special') {
            setActiveTab('특별 일정');
        } else if (location.pathname === '/schedule/operation') {
            setActiveTab('운영 스케줄');
        }
    }, [location]);

    useLayoutEffect(() => {
        if (!user.user_rankPermission.schedule) {
            navigate('/home');
            toast.error('접근 권한이 없습니다.');
        }
    }, [user]);

    return (
        <ScheduleContext.Provider value={{
            date,
            sortType,
            scheduleFilter,
            setScheduleFilter,
            scheduleModal,
            setScheduleModal,
            selectedId,
            setSelectedId,
            searchTerm
        }}>
            <Wrapper>
                <PageHeader
                    SCHEDULE
                    TAB_BUTTON
                    guideLine='스케줄 > 스케줄현황'
                    tabButton={['운영 스케줄', '특별 일정']}
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                    onClick={() => setScheduleModal(true)}
                >
                    <FilterBox>
                        <StyledFlexBox>
                            <StyledCalendar
                                dateFormat={scheduleFilter === '월' ? 'yyyy-MM' : 'yyyy-MM-dd'}
                                inputWidth={88}
                                value={date}
                                onChange={value => setDate(value)}
                                handlePrevMonth={handlePrevMonth}
                                handleNextMonth={handleNextMonth}
                            />
                        </StyledFlexBox>
                        <IconBox>
                            <StyledIconButton // 리프레쉬
                                REFRESH
                                onClick={() => setSearchTerm('')}
                            />
                            <StyledIconButton // 검색
                                SEARCH
                                margin='0 12px'
                                onClick={() => setSearchModal(true)}
                            />
                            <StyledIconButton
                                SORT
                                margin='0 12px 0 0'
                                onClick={handleSort}
                            />
                            {activeTab === '운영 스케줄' && (
                                <DateFilterBox>
                                    <DateBox $active={scheduleFilter === '일'} onClick={() => setScheduleFilter('일')}>일</DateBox>
                                    <DateBox $active={scheduleFilter === '월'} onClick={() => setScheduleFilter('월')}>월</DateBox>
                                </DateFilterBox>
                            )}
                        </IconBox>
                    </FilterBox>
                </PageHeader>

                <Outlet />

                <SearchModal // 검색하기 모달
                    searchModal={searchModal}
                    setSearchModal={setSearchModal}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Wrapper>
        </ScheduleContext.Provider>
    )
}

export default Schedule;
