import {gql} from '@apollo/client';

export const SEE_DID_MONITORS = gql`
    query SeeDidMonitors(
        $searchTerm: String
        $filter: mediaType
        $take: Int
        $cursor: Int
    ) {
        seeDidMonitors(
            searchTerm: $searchTerm
            filter: $filter
            take: $take
            cursor: $cursor
        ) {
            totalLength
            didList {
                did_id
                did_createdAt
                did_updatedAt
                did_creatorName
                did_creatorRank
                did_creatorId
                did_editorName
                did_editorRank
                did_editorId
                did_isDelete
                did_deleteDate
                did_title
                did_doctorRoomExpression
                did_standbyPersonExpression
                did_erColorUsed
                did_erColor
                did_holdingColorUsed
                did_holdingColor
                did_standbyPersonFontsize
                did_calledPersonFontsize
                did_calledTextUsed
                did_calledVoiceUsed
                did_monitorType
                did_mediaType
                did_resUsed
                did_transmitType
                did_resInfoLocation
                did_monitorRatio
                did_patExpress1
                did_patExpRatio1
                did_patExpress2
                did_patExpRatio2
                did_patExpress3
                did_patExpRatio3
                did_patExpress4
                did_patExpRatio4
                did_lowMsgUsed
                did_doctorRoomIsHorizontal
                did_nameMasking
                did_resInfoTime
                did_resInfoCycle
                did_doctorRoomMerge
                did_uniqueId
                did_saveMode
                didAttached {
                    da_id
                    da_createdAt
                    da_updatedAt
                    da_creatorName
                    da_creatorRank
                    da_creatorId
                    da_editorName
                    da_editorRank
                    da_editorId
                    da_isDelete
                    da_deleteDate
                    da_number
                    da_url
                    da_fileType
                    da_fileSize
                }
                didLowMsg {
                    dlm_id
                    dlm_createdAt
                    dlm_updatedAt
                    dlm_creatorName
                    dlm_creatorRank
                    dlm_creatorId
                    dlm_editorName
                    dlm_editorRank
                    dlm_editorId
                    dlm_isDelete
                    dlm_deleteDate
                    dlm_number
                    dlm_text
                }
                didDoctorRoom {
                    ddr_id
                    ddr_info
                    ddr_number
                    ddr_doctorRoomName
                    ddr_doctorName
                    ddr_dayOff
                    ddr_updatedAt
                    ddr_editorName
                    ddr_editorRank
                    ddr_editorId
                    ddr_isDelete
                    ddr_deleteDate
                    ddr_viewSelect
                }
            }
        }
    }
`;

export const SEE_DID_LOW_MSG = gql`
    query SeeDidLowMsg($didId: Int!) {
        seeDidLowMsg(did_id: $didId) {
            totalLength
            didLowMsgList {
                dlm_id
                dlm_createdAt
                dlm_updatedAt
                dlm_creatorName
                dlm_creatorRank
                dlm_creatorId
                dlm_editorName
                dlm_editorRank
                dlm_editorId
                dlm_isDelete
                dlm_deleteDate
                dlm_number
                dlm_text
            }
        }
    }
`;
