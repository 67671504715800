import React, {useCallback} from "react";
import noUser from '../../../../assets/images/noUser.png';
import {QnaTableRow as TableRow, QnaTableCell, ImageBox, Image, EmphasisText, DefaultText} from '../service.style';
import theme from "../../../../styles/theme";
import dayjs from "dayjs";
import {IoCheckmarkCircleSharp} from 'react-icons/io5';
import {handleBodyScroll} from "../../../../utils/commons";

const QnaTableRow = ({
                         list,
                         setSelectedId,
                         setQnaDetailModal
                     }) => {

    const handleOpenDetail = useCallback(() => {
        setSelectedId(list?.oneq_id);
        setQnaDetailModal(true);
        handleBodyScroll('hidden');
    }, [list]);

    return (
        <TableRow onClick={handleOpenDetail}>
            <QnaTableCell
                $minWidth={42}
                $width={2}
                $marginRight={16}>
                <ImageBox>
                    <Image
                        src={list?.oneq_creatorImg || ''}
                        onError={e => e.target.src = noUser}
                    />
                </ImageBox>
            </QnaTableCell>
            <QnaTableCell
                $column
                $minWidth={740}
                $width={90}
                $marginRight={100}>
                <EmphasisText>
                    {list?.oneq_title || '-'}
                </EmphasisText>
                <DefaultText $fontColor={theme.colors.fontGrayColor}>
                    {list?.oneq_creatorName || '-'}&nbsp;
                    {list?.oneq_creatorRank || '-'}&nbsp;
                    {list?.oneq_createdAt
                        ? dayjs(list?.oneq_createdAt).format('YYYY-MM-DD HH:mm')
                        : '-'}&nbsp;
                </DefaultText>
            </QnaTableCell>
            <QnaTableCell
                $minWidth={82}
                $width={4}
                $marginRight='0'>
                <DefaultText $fontColor={list?.oneq_status ? theme.colors.activeIndigo : theme.colors.fontGrayColor}>
                    {list?.oneq_status ? '답변완료' : '미답변'}
                </DefaultText>
                <IoCheckmarkCircleSharp
                    fontSize={22}
                    color={list?.oneq_status ? theme.colors.activeIndigo : theme.colors.fontLightGrayColor}
                />
            </QnaTableCell>
        </TableRow>
    )
}

export default QnaTableRow;
