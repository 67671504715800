import React from 'react';
import styled from "styled-components";
import theme from "../../styles/theme";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 100;
  opacity: 0.2;
  background-color: ${theme.colors.blackColor};
`;

const LoaderBackground = ({ children }) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
}

export default LoaderBackground;
