import React, {useCallback} from "react";
import logo from '../../../../assets/images/logo.png';
import {
    NoticeTableRow as TableRow,
    ImageBox,
    Image,
    NoticeInfoBox,
    EmphasisText,
    DefaultText,
    QuestionText, NoticeImageBox, NoticeImage
} from '../service.style';
import theme from "../../../../styles/theme";
import dayjs from "dayjs";

const NoticeTableRow = ({
                            notice,
                            handleOpenComment
                        }) => {

    const handleOpenDetail = useCallback(() => {
        handleOpenComment(notice.pn_id);
    }, [notice]);

    return (
        <TableRow onClick={handleOpenDetail}>
            <ImageBox $border={`1px solid ${theme.colors.activeOrange}`} $marginRight={16}>
                <Image src={logo}/>
            </ImageBox>
            <NoticeInfoBox>
                <EmphasisText>{notice?.pn_title || ''}</EmphasisText>
                <DefaultText $fontColor={theme.colors.fontGrayColor} $margin='8px 0 20px'>
                    {notice?.pn_adminCreatorName || ''} &nbsp;
                    {notice?.pn_adminCreatorRank || ''} &nbsp;&nbsp;&nbsp;&nbsp;
                    {notice?.pn_createdAt
                        ? dayjs(notice?.pn_createdAt).format('YYYY-MM-DD HH:mm')
                        : '-'
                    }
                </DefaultText>
                <QuestionText>
                    {notice?.pn_text || ''}
                </QuestionText>
                <NoticeImageBox>
                    {notice?.pnAttached?.map((img, index) => (
                        <NoticeImage
                            key={`${img.pna_id}-${index}-img-list`}
                            src={img.pna_url}
                            alt='공지사항 이미지'
                        />
                    ))}
                </NoticeImageBox>
            </NoticeInfoBox>
        </TableRow>
    )
}

export default NoticeTableRow;
