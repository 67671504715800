import React, {useCallback, useEffect, useState} from 'react';
import {SortingBox, ManagerTableList, Wrapper, SortingIconBox} from "./manager.style";
import PageHeader from "../../components/layout/PageHeader";
import StyledSelect from "../../components/styled/StyledSelect";
import StyledIconButton from "../../components/styled/StyledIconButton";
import SearchModal from "../../components/share/SearchModal";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_ORG} from "../../graphql/Setting/query";
import {SEE_USER_LIST} from "../../graphql/Manager/query";
import Loader from "../../components/share/Loader";
import ManagerTableRow from "../../components/feature/Manager/ManagerTableRow";
import ManagerModal from "../../components/feature/Manager/ManagerModal";
import {DELETE_USER} from "../../graphql/Manager/mutation";
import {toast} from "react-toastify";
import {errorMessage} from "../../utils/commons";
import PageNation from "../../components/layout/PageNation";

const Manager = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('전체');

    const [orgOption, setOrgOption] = useState(['전체']);
    const [searchModal, setSearchModal] = useState(false);
    const [managerModal, setManagerModal] = useState(false);
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);
    const [selectedUser, setSelectedUser] = useState(null);

    const { data: orgData, loading: orgLoading } = useQuery(SEE_ORG);
    const { data: userData, loading: userLoading, refetch: userRefetch } = useQuery(SEE_USER_LIST, {
        variables: {
            searchTerm: searchTerm,
            filter: filter === '전체' ? '' : filter,
            take: take,
            cursor: cursor,
        }
    });
    const [deleteUser] = useMutation(DELETE_USER);

    const handleUpdateUser = useCallback(user => {
        setSelectedUser(user);
        setManagerModal(true);
    }, []);

    const handleDeleteUser = useCallback(async id => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const { data } = await deleteUser({
                variables: {
                    userId: id
                }
            });

            if (data?.deleteUser) {
                toast.info('사용자를 삭제했습니다.');
                await userRefetch();
            }
        } catch (e) {
            switch (e.message) {
                case 'err_01':
                    toast.error( '로그인 한 계정과 동일한 계정은 삭제할 수 없습니다.');
                    break;
            }
        }
    }, []);

    const onClickPage = useCallback(page => { // 페이지
        setPage(page);
        if (page === 1) {
            setCursor(0);
        } else {
            setCursor((page - 1) * take);
        }
    }, [take]);

    useEffect(() => {
        if (searchTerm.length > 0) {
            setCursor(0);
            setPage(1);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (!orgLoading && orgData) {
            const tmpOrg = orgData?.seeOrg?.orgList.map(org => org.org_name);
            setOrgOption(['전체', ...tmpOrg])
        }
    }, [orgData]);

    return (
        <Wrapper>
            <PageHeader
                GO_BACK
                MANAGER
                title='사용자 관리'
                guideLine='설정 > 사용자 관리'
                onClick={() => setManagerModal(true)}>
                <SortingBox>
                    <StyledSelect
                        NO_BORDER
                        title='조직 : '
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                        options={orgOption}
                    />
                    <SortingIconBox>
                        <StyledIconButton // 검색
                            REFRESH
                            onClick={() => setSearchTerm('')}
                        />
                        <StyledIconButton // 검색
                            SEARCH
                            margin='0 12px'
                            onClick={() => setSearchModal(true)}
                    />
                    </SortingIconBox>
                </SortingBox>
            </PageHeader>
            <ManagerTableList>
                {userLoading
                    ? <Loader />
                    : userData?.seeUserList?.userList?.map((user, index) => (
                        <ManagerTableRow
                            key={`${user.user_id}-${index}-manager-list`}
                            user={user}
                            handleUpdateUser={handleUpdateUser}
                            handleDeleteUser={handleDeleteUser}
                        />
                    ))
                }
            </ManagerTableList>

            <PageNation // 페이지
                totalLength={userData?.seeUserList?.totalLength}
                take={take}
                setTake={setTake}
                page={page}
                onClickPage={onClickPage}
                takeOption={[10, 20, 30]}
            />

            <SearchModal // 검색하기 모달
                searchModal={searchModal}
                setSearchModal={setSearchModal}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />

            <ManagerModal // 사용자 추가하기 모달
                managerModal={managerModal}
                setManagerModal={setManagerModal}
                userRefetch={userRefetch}
                orgOption={orgOption}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
            />
        </Wrapper>
    );
}

export default Manager;
