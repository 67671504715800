import React, {useCallback, useContext, useEffect, useState} from "react";
import {BoldText} from "../../../components/styled/StyledText";
import {QnaHeader, QnaTableList} from "../../../components/feature/Service/service.style";
import StyledFlexBox from "../../../components/styled/StyledFlexBox";
import StyledSelect from "../../../components/styled/StyledSelect";
import {useQuery} from "@apollo/client";
import {SEE_ONE_ON_ONE_LIST} from "../../../graphql/Service/query";
import {serviceChange} from "../service.lib";
import StyledIconButton from "../../../components/styled/StyledIconButton";
import PageNation from "../../../components/layout/PageNation";
import Loader from "../../../components/share/Loader";
import QnaTableRow from "../../../components/feature/Service/QNA/QnaTableRow";
import QnaDetailModal from "../../../components/feature/Service/QNA/QnaDetailModal";
import {ServiceContext} from "../service";

const QNA = () => {
    const { qnaRefetch } = useContext(ServiceContext);
    const [status, setStatus] = useState('전체');
    const [sortType, setSortType] = useState('desc');
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);
    const [qnaDetailModal, setQnaDetailModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const { data, loading, refetch: qnaListRefetch } = useQuery(SEE_ONE_ON_ONE_LIST, {
        variables: {
            answerStatus: serviceChange(status),
            orderBy: sortType,
            take: take,
            cursor: cursor,
        },
        fetchPolicy: 'network-only'
    });

    const onChange = useCallback(e => setStatus(e.target.value), []);
    const handleSort = useCallback(() => {
        if (sortType === 'desc') {
            setSortType('asc');
        } else {
            setSortType('desc');
        }
    }, [sortType]);

    const onClickPage = useCallback(page => { // 페이지
        setPage(page);
        if (page === 1) {
            setCursor(0);
        } else {
            setCursor((page - 1) * take);
        }
    }, [take]);

    useEffect(() => {
        qnaRefetch.current = qnaListRefetch;
    }, [qnaRefetch]);

    return (
        <>
            <QnaHeader>
                <BoldText>등록한 질문</BoldText>
                <StyledFlexBox justifyContent='space-between'>
                    <StyledSelect
                        NO_BORDER
                        width={200}
                        title='답변상태 : '
                        margin='20px 0 0'
                        value={status}
                        onChange={onChange}
                        options={['전체', '답변', '미답변']}
                    />
                    <StyledIconButton // 리스트 보기
                        SORT
                        onClick={handleSort}
                    />
                </StyledFlexBox>
            </QnaHeader>
            <QnaTableList>
                {loading
                    ? <Loader />
                    : data?.seeOneOnOneList?.inquireList?.map((list, index) => (
                        <QnaTableRow
                            key={`${index}-${list?.oneq_id}-qna-list`}
                            list={list}
                            setSelectedId={setSelectedId}
                            setQnaDetailModal={setQnaDetailModal}
                        />
                    ))
                }
            </QnaTableList>
            <PageNation
                totalLength={data?.seeOneOnOneList?.totalLength}
                take={take}
                setTake={setTake}
                page={page}
                onClickPage={onClickPage}
                takeOption={[10, 20, 30]}
            />

            <QnaDetailModal
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                qnaDetailModal={qnaDetailModal}
                setQnaDetailModal={setQnaDetailModal}
                qnaListRefetch={qnaListRefetch}
            />
        </>
    )
}

export default QNA;
