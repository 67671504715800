import React, {useState} from "react";
import {
    CountText,
    FaqBox,
    FaqList, GrayText,
    Image,
    ImageBox,
    PercentBox,
    ServiceCenterSection,
    StatsBox, SubCountBox
} from "../service.style";
import {BoldText} from "../../../components/styled/StyledText";
import {useQuery} from "@apollo/client";
import {
    SEE_ER_NOTICE_CHECK_RATIO,
    SEE_FAQ,
    SEE_HIGH_VIEW_QUESTION,
    SEE_QUESTION_SOLVED_RATIO,
    SEE_RECENTLY_QUESTION,
    SEE_HIGH_COMMENT_QUESTION
} from "../../../graphql/Service/query";
import Loader from "../../../components/share/Loader";
import FaqTableRow from "../../../components/feature/Service/ServiceCenter/FaqTableRow";
import theme from "../../../styles/theme";
import questionMarkImg from '../../../assets/images/questionMarkImg.png';
import loudspeakerImg from '../../../assets/images/loudspeakerImg.png';
import StyledFlexBox from "../../../components/styled/StyledFlexBox";
import FaqRankList from "../../../components/feature/Service/ServiceCenter/FaqRankList";
import FaqDetailModal from "../../../components/feature/Service/ServiceCenter/FaqDetailModal";

const ServiceCenter = () => {
    const [faqDetailModal, setFaqDetailModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const {data: faqData, loading: faqLoading, refetch: faqRefetch} = useQuery(SEE_FAQ, {
        fetchPolicy: 'network-only'
    });
    const {data: questionRatioData, loading: questionRatioLoading} = useQuery(SEE_QUESTION_SOLVED_RATIO, {
        fetchPolicy: 'network-only'
    });
    const {data: noticeRatioData, loading: noticeRatioLoading} = useQuery(SEE_ER_NOTICE_CHECK_RATIO, {
        fetchPolicy: 'network-only'
    });
    const {data: hitsData, loading: hitsLoading} = useQuery(SEE_HIGH_VIEW_QUESTION, {
        fetchPolicy: 'network-only'
    });
    const {data: recentlyData, loading: recentlyLoading} = useQuery(SEE_RECENTLY_QUESTION, {
        fetchPolicy: 'network-only'
    });
    const {data: likeData, loading: likeLoading} = useQuery(SEE_HIGH_COMMENT_QUESTION, {
        fetchPolicy: 'network-only'
    });

    return (
        <>
            <ServiceCenterSection>
                <FaqBox>
                    <BoldText>자주 묻는 질문</BoldText>
                    <FaqList>
                        {faqLoading
                            ? <Loader/>
                            : faqData?.seeFAQ?.faqList?.map((faq, index) => (
                                <FaqTableRow
                                    key={`${faq.faq_id}-${index}-faq-list`}
                                    faq={faq}
                                    setFaqDetailModal={setFaqDetailModal}
                                    setSelectedId={setSelectedId}
                                />
                            ))
                        }
                    </FaqList>
                </FaqBox>
                <PercentBox>
                    <StatsBox>
                        {questionRatioLoading
                            ? <Loader/>
                            : (
                                <>
                                    <ImageBox>
                                        <Image src={questionMarkImg}/>
                                    </ImageBox>
                                    <CountText>
                                        {questionRatioData?.seeQuestionSolvedRatio?.solvedRatio || 0}%
                                    </CountText>
                                    <BoldText
                                        $fontSize={16}
                                        $margin='0 0 60px'>
                                        질문 해결률
                                    </BoldText>
                                    <StyledFlexBox>
                                        <SubCountBox>
                                            <GrayText>
                                                {questionRatioData?.seeQuestionSolvedRatio?.solvedQuestion || 0}개
                                            </GrayText>
                                            <GrayText $margin='10px 0 0'>해결된 질문</GrayText>
                                        </SubCountBox>
                                        <SubCountBox>
                                            <GrayText>
                                                {questionRatioData?.seeQuestionSolvedRatio?.registQuestion || 0}개
                                            </GrayText>
                                            <GrayText $margin='10px 0 0'>등록된 질문</GrayText>
                                        </SubCountBox>
                                    </StyledFlexBox>
                                </>
                            )
                        }
                    </StatsBox>
                    <StatsBox
                        $border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                        $bgColor={theme.colors.whiteColor}>
                        {noticeRatioLoading
                            ? <Loader/>
                            : (
                                <>
                                    <ImageBox>
                                        <Image src={loudspeakerImg}/>
                                    </ImageBox>
                                    <CountText>
                                        {noticeRatioData?.seeErNoticeCheckRatio || 0}%
                                    </CountText>
                                    <BoldText
                                        $fontSize={16}
                                        $margin='0 0 72px'>
                                        공지 확인률
                                    </BoldText>
                                    <GrayText>
                                        소속 직원들의 공지확인률입니다<br/>
                                        확인률을 높이 유지해주세요.
                                    </GrayText>
                                </>
                            )
                        }
                    </StatsBox>
                </PercentBox>
            </ServiceCenterSection>
            <ServiceCenterSection>
                <FaqRankList
                    title='조회수가 높은 질문'
                    loading={hitsLoading}
                    data={hitsData?.seeHighViewQuestion}
                    setSelectedId={setSelectedId}
                    setFaqDetailModal={setFaqDetailModal}
                />
                <FaqRankList
                    title='최근 등록된 질문'
                    loading={recentlyLoading}
                    data={recentlyData?.seeRecentlyQuestion}
                    setSelectedId={setSelectedId}
                    setFaqDetailModal={setFaqDetailModal}
                />
                <FaqRankList
                    title='좋아요가 많은 질문'
                    loading={likeLoading}
                    data={likeData?.seeHighCommentQuestion}
                    setSelectedId={setSelectedId}
                    setFaqDetailModal={setFaqDetailModal}
                />
            </ServiceCenterSection>

            <FaqDetailModal
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                faqDetailModal={faqDetailModal}
                setFaqDetailModal={setFaqDetailModal}
                faqRefetch={faqRefetch}
            />
        </>
    )
}

export default ServiceCenter;
