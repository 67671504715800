import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {IoChevronDownOutline, IoTrashOutline, IoCheckmarkCircleOutline, IoCloseCircleOutline} from 'react-icons/io5';
import {BiPencil} from 'react-icons/bi';
import theme from "../../styles/theme";

const Wrapper = styled.div`
  font-family: 'AppleSDGothicNeoMedium';
  position: relative;
  margin: ${({$margin}) => $margin};

  ${({ROW}) => ROW && css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;
const SelectBox = styled.div`
  width: ${({$width}) => $width}px;
  height: ${({$height}) => $height}px;
  padding: 0 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Text = styled.div`
  min-width: 60px;
  width: 100%;
  font-size: ${({$fontSize}) => $fontSize}px;
  font-weight: ${({$fontWeight}) => ($fontWeight ? $fontWeight : 500)};
  font-family: ${({$fontFamily}) => $fontFamily};
  color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
`;
const ArrowBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  background-color: ${({ $disabled }) => $disabled ? theme.colors.ultraLightGrayBorder : 'inherit'};
`;
const OptionBox = styled.div`
  width: 100%;
  min-height: 80px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: -80px;
  right: -82px;
  z-index: 10;
  overflow-y: scroll;
  border-radius: 8px;
  background-color: ${theme.colors.whiteColor};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
`;
const OptionText = styled.div`
  width: 100%;
  font-family: 'AppleSDGothicNeoRegular';
  display: flex;
  align-items: center;
  color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
  font-size: ${({$fontSize}) => $fontSize ? $fontSize : 16}px;
  font-weight: ${({$fontWeight}) => $fontWeight};
  word-break: keep-all;
  line-height: 1.2;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
    background-color: ${theme.colors.whiteHoverColor};
  }
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 2px 0 0;
  background-color: ${theme.colors.ultraLightGrayBorder};
`;
const IconBox = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconSelect = ({
                              width = 120,
                              value,
                              handleChangeStatus,
                              setConfirmModal,
                              options = [],
                              disabled,
                          }) => {
    const boxRef = useRef(null);
    const optRef = useRef(null);
    const [optionVisible, setOptionVisible] = useState(false);

    const handleSelect = useCallback(() => {
        if (!disabled) {
            setOptionVisible(!optionVisible);
        }
    }, [disabled, optionVisible]);

    const onClickOutSide = useCallback(({target}) => {
        let boxRefCurrent = boxRef.current && boxRef?.current.contains(target);
        let optRefCurrent = optRef.current && optRef?.current.contains(target);

        if (!boxRefCurrent && !optRefCurrent) setOptionVisible(false);
    }, [boxRef, optRef]);

    const onClickOption = useCallback(async option => {
        if (option === '삭제하기') {
            setConfirmModal(true);
        } else {
            await handleChangeStatus(option);
            setOptionVisible(false);
        }
    }, [handleChangeStatus]);

    useEffect(() => {
        window.addEventListener('click', onClickOutSide);
        return () => window.removeEventListener('click', onClickOutSide);
    }, [onClickOutSide]);

    return (
        <Wrapper>
            <SelectBox
                $width={width}
                ref={boxRef}
                onClick={handleSelect}>
                <Text>{value}</Text>
                <ArrowBox $disabled={disabled}>
                    <IoChevronDownOutline/>
                </ArrowBox>
            </SelectBox>
            {optionVisible && (
                <OptionBox ref={optRef}>
                    {options.map((option, index) => (
                        <div key={`${index}-option}`}>
                            <OptionText
                                $fontColor={(option === '삭제하기' || option === '반려') && theme.colors.activeRed}
                                onClick={() => onClickOption(option)}>
                                <IconBox>
                                    {option === '삭제하기' && <IoTrashOutline color={theme.colors.activeRed}/>}
                                    {(option === '수정하기' || option === '예약 완료') && <BiPencil/>}
                                    {(option === '승인완료' || option === '내원 확정') && <IoCheckmarkCircleOutline fontSize={20} />}
                                    {option === '반려' && <IoCloseCircleOutline fontSize={20} color={theme.colors.activeRed}/>}
                                </IconBox>
                                {option}
                            </OptionText>
                            {index !== options.length - 1 && <Line/>}
                        </div>
                    ))}
                </OptionBox>
            )}
        </Wrapper>
    );
}

export default StyledIconSelect;
