import React, {useCallback, useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import {IoSearchOutline, IoCloseOutline} from 'react-icons/io5';
import ModalBackground from "../layout/ModalBackground";
import theme from "../../styles/theme";
import {RegularText} from "../styled/StyledText";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_SEARCH_HISTORY} from "../../graphql/Common/query";
import {DELETE_SEARCH_HISTORY} from "../../graphql/Common/mutation";
import {errorMessage} from "../../utils/commons";
import Loader from "./Loader";
import {toast} from "react-toastify";

const SearchInputBox = styled.div`
  height: 50px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-radius: 8px;
  margin-bottom: 20px;
`;
const Input = styled.input`
  flex: 1;
  margin-left: 6px;
`;
const DeleteIcon = styled.span`
  cursor: pointer;
`;
const HistoryBox = styled.div`
  height: 300px;
  overflow: scroll;
`;
const GrayText = styled(RegularText)`
  color: ${theme.colors.fontDarkGrayColor};
`;
const HistoryList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  cursor: pointer;
  
  ${({ $default }) => $default && css`
    padding-top: 0;
    cursor: initial;
  `};
`;

const SearchModal = ({
                         searchModal,
                         setSearchModal,
                         setSearchTerm,
                     }) => {
    const [input, setInput] = useState('');
    const inputRef = useRef();
    const deleteRefs = useRef([]);

    const {data, loading, refetch} = useQuery(SEE_SEARCH_HISTORY);
    const [deleteSearchHistory] = useMutation(DELETE_SEARCH_HISTORY);

    const handleClose = useCallback(() => {
        setInput('');
        setSearchModal(false);
    }, []);

    const handleDeleteHistory = useCallback(async id => {
        try {
            const { data } = await deleteSearchHistory({
                variables: {
                    shId: id
                }
            });

            if (data.deleteSearchHistory) {
                toast.info('이전검색어를 삭제했습니다.');
                await refetch();

            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    const handleClickHistory = useCallback((e, index, value) => {
        if (deleteRefs?.current[index]?.contains(e.target)) return;

        setInput(value);
        setSearchTerm(value);
        setSearchModal(false);
    }, [deleteRefs, setSearchTerm, setSearchModal]);

    const onKeyPress = useCallback(async e => {
        if (e.key === 'Enter') {
            setSearchTerm(input);
            handleClose();
            await refetch();
        }
    }, [input]);

    useEffect(() => {
        (async () => {
            if (searchModal)
                await refetch();
                inputRef?.current?.focus();
        })();
    }, [searchModal, inputRef]);

    return (
        <ModalBackground
            title='검색하기'
            height={430}
            visible={searchModal}
            onClose={handleClose}>
            <SearchInputBox>
                <IoSearchOutline fontSize={22}/>
                <Input
                    ref={inputRef}
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    onKeyPress={onKeyPress}
                    placeholder='검색어를 입력해주세요.'
                />
                <DeleteIcon onClick={() => setSearchTerm('')}>
                    <IoCloseOutline fontSize={20} color={theme.colors.fontLightGrayColor}/>
                </DeleteIcon>
            </SearchInputBox>
            {data?.seeSearchHistory?.length > 0 && (
                <HistoryList $default>
                    <GrayText>이전검색어</GrayText>
                    <GrayText>지우기</GrayText>
                </HistoryList>
            )}
            <HistoryBox>
                {loading
                    ? <Loader/>
                    : data?.seeSearchHistory?.map((history, index) => (
                        <HistoryList
                            key={`${history.sh_id}-history`}
                            onClick={e => handleClickHistory(e, index, history.sh_text)}>
                            <RegularText>
                                {history.sh_text}
                            </RegularText>
                            <DeleteIcon
                                ref={element => {
                                    if (element && deleteRefs?.current) {
                                        deleteRefs.current[index] = element;
                                    }
                                }}
                                onClick={() => handleDeleteHistory(history.sh_id)}>
                                <IoCloseOutline fontSize={20} color={theme.colors.fontLightGrayColor}/>
                            </DeleteIcon>
                        </HistoryList>
                ))}
            </HistoryBox>
        </ModalBackground>
    )
}

export default SearchModal;
