import React, {useCallback, useState} from 'react';
import {IoArrowBack} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import LogoutLogo from "../../../components/share/LogoutLogo";
import StyledInput from "../../../components/styled/StyledInput";
import theme from "../../../styles/theme";
import StyledButton from "../../../components/styled/StyledButton";
import * as F from '../findInfo.style';
import {useMutation} from "@apollo/client";
import {FIND_USER_PASSWORD} from "../../../graphql/Login/mutation";

const FindPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const [findUserPassword] = useMutation(FIND_USER_PASSWORD);

    const handleSendPassword = useCallback(async () => {
        if (email === '') {
            toast.error('이메일을 입력해주세요.');
            return;
        }

        try {
            const { data } = await findUserPassword({
                variables: {
                    email
                }
            });

            if (data?.findUserPassword) {
                toast.info('이메일로 임시 비밀번호가 발송되었습니다.');
                setEmail('');
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('비밀번호 찾기에 실패하였습니다.');
                    break;
                case 'err_01':
                    toast.error('존재하지 않거나 탈퇴된 계정입니다.');
                    break;
            }
        }
    }, [email]);

    // 발송 성공 : “이메일로 임시 비밀번호 발송이 완료되었습니다. 이메일을 확인해주세요.”
    // 발송 실패 : “등록된 이메일 주소가 없습니다. 다시 한번 확인해주세요.”

    return (
        <F.Wrapper>
            <LogoutLogo />
            <F.FindBox>
                <F.GuideLine>로그인 > 비밀번호 찾기</F.GuideLine>
                <F.Title>
                    <F.GoBack onClick={() => navigate('/login')}>
                        <IoArrowBack />
                    </F.GoBack>
                    비밀번호 찾기
                </F.Title>
                <F.BlueText>정보 입력</F.BlueText>
                <StyledInput
                    ASTERISK
                    title="이메일"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onKeyPress={async e => e.key === 'Enter' && handleSendPassword()}
                    placeholder="정확한 이메일 주소를 입력해주세요."
                />

                <StyledButton
                    title="임시 비밀번호 발송하기"
                    width={440}
                    margin="40px 0"
                    borderRadius={4}
                    bgColor={theme.colors.activeIndigo}
                    onClick={handleSendPassword}
                />
            </F.FindBox>
        </F.Wrapper>
    );
}

export default FindPassword;
