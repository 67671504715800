import React from 'react';
import {DefaultText, EmphasisText, GrayText, IconBox, TableCell, TableRow} from "./manager.style";
import {IoPersonOutline} from 'react-icons/io5';
import {autoHyphen} from "../../../utils/commons";
import StyledIconButton from "../../styled/StyledIconButton";

const ManagerTableRow = ({ user, handleUpdateUser, handleDeleteUser }) => {
    return (
        <TableRow>
            <TableCell
                $minWidth={42}
                $width={2}>
                <IconBox>
                    <IoPersonOutline fontSize={20} />
                </IconBox>
            </TableCell>
            <TableCell
                $column
                $minWidth={100}
                $width={10}>
                <EmphasisText>{user.user_name || ''}</EmphasisText>
                <DefaultText>{user.user_rank || ''}</DefaultText>
            </TableCell>
            <TableCell
                $minWidth={120}
                $width={12}>
                <DefaultText>{user.user_cellphone ? autoHyphen(user.user_cellphone) : ''}</DefaultText>
            </TableCell>
            <TableCell
                $minWidth={180}
                $width={14}>
                <DefaultText>{user.user_email || ''}</DefaultText>
            </TableCell>
            <TableCell
                $column
                $minWidth={112}
                $width={11}>
                <DefaultText>조직</DefaultText>
                <GrayText>{user.user_org || '정보미입력'}</GrayText>
            </TableCell>
            <TableCell
                $column
                $minWidth={112}
                $width={11}>
                <DefaultText>직무</DefaultText>
                <GrayText>{user.user_job || '정보미입력'}</GrayText>
            </TableCell>
            <TableCell
                $column
                $minWidth={180}
                $width={18}>
                {(user.user_address && user.user_detailAddress)
                    ? (
                        <>
                            <DefaultText>{user.user_address || '-'}</DefaultText>
                            <DefaultText>{user.user_detailAddress || '-'}</DefaultText>
                        </>
                    ) : (
                        <>
                            <DefaultText>주소</DefaultText>
                            <DefaultText>-</DefaultText>
                        </>
                    )
                }
            </TableCell>
            <TableCell
                $column
                $minWidth={160}
                $width={17}>
                <DefaultText>권한</DefaultText>
                <DefaultText>{user.user_rank || '-'}</DefaultText>
            </TableCell>
            <TableCell
                $minWidth={70}
                $width={5}
                $marginRight='0'>
                <StyledIconButton
                    UPDATE
                    topRightRadius={0}
                    bottomRightRadius={0}
                    margin='0 0 0 -1px'
                    onClick={() => handleUpdateUser(user)}
                />
                <StyledIconButton
                    DELETE
                    topLeftRadius={0}
                    bottomLeftRadius={0}
                    margin='0 0 0 -1px'
                    onClick={() => handleDeleteUser(user.user_id)}
                />
            </TableCell>
        </TableRow>
    );
}

export default ManagerTableRow;
