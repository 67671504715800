import React, {useCallback, useEffect} from "react";
import styled, {css} from "styled-components";
import {IoArrowBackOutline} from 'react-icons/io5';
import theme from "../../styles/theme";
import StyledButton from "../styled/StyledButton";
import {handleBodyScroll} from "../../utils/commons";
import {BoldText} from "../styled/StyledText";

const Wrapper = styled.div`
  width: 100vw;
  height: 0;
  top: 0;
  left: 0;
  z-index: 0;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  transition: .25s;
  background-color: ${theme.colors.whiteColor};

  ${({$visible}) => $visible && css`
    min-height: 100vh;
    opacity: 1;
    visibility: visible;
    z-index: 10;
  `};
`;
const Header = styled.div`
  min-width: 914px;
  width: 100%;
  padding: 30px 40px 10px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;
const ModalScrollWrapper = styled.div`
  margin: 0 auto;
  padding-bottom: 20px;
  height: calc(100% - 90px);
  overflow: scroll;
`;
const Contents = styled.div`
  max-width: 912px;
  margin: 0 auto;
`;
const GuideLine = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.grayBorder};
  margin: 20px auto;
`;
const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled(BoldText)`
  padding-bottom: 4px;
`;
const BackIcon = styled.div`
  cursor: pointer;
`;

const FullPageModalBackground = ({
                                     CONFIRM,
                                     buttonTitle,
                                     modalTitle,
                                     guideLine,
                                     buttonColor = theme.colors.activeBlue,
                                     visible,
                                     onClose,
                                     onClickButton,
                                     children,
                                 }) => {

    const handleClose = useCallback(() => {
        onClose();
        handleBodyScroll('initial');
    }, [onClose]);

    const preventGoBack = useCallback(() => {
        if (!window.confirm('페이지 이동 시 입력된 정보가 모두 사라잡니다.')) return;
        handleClose();
        window.history.pushState(null, "", window.location.href);
        // alert("새로고침 및 뒤로가기 시 입력된 정보가 사라집니다.");
    }, []);

    const preventClose = useCallback(e => {
        e.preventDefault();
        e.returnValue = ''; // Chrome 에서 동작하도록 deprecated
    }, []);

    useEffect(() => {
        if (visible && CONFIRM) {
            (() => {
                window.history.pushState(null, '', window.location.href);
                window.addEventListener('beforeunload', preventClose);
                window.addEventListener('popstate', preventGoBack);
            })();

            return () => {
                window.removeEventListener('beforeunload', preventClose);
                window.removeEventListener('popstate', preventGoBack);
            };
        }
    }, [visible, CONFIRM]);

    return (
        <Wrapper $visible={visible}>
            <Header>
                <StyledButton
                    CLOSE
                    title='나가기'
                    fontColor={theme.colors.fontGrayColor}
                    fontWeight={500}
                    fontFamily='AppleSDGothicNeoRegular'
                    border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                    bgColor={theme.colors.whiteColor}
                    onClick={handleClose}
                />
                {buttonTitle && (
                    <StyledButton
                        PENCIL
                        title={buttonTitle}
                        fontWeight={500}
                        fontFamily='AppleSDGothicNeoRegular'
                        bgColor={buttonColor}
                        onClick={onClickButton}
                    />
                )}
            </Header>
            <ModalScrollWrapper>
                <Contents>
                    <GuideLine>{guideLine}</GuideLine>
                    <TitleBox>
                        <BackIcon onClick={handleClose}>
                            <IoArrowBackOutline fontSize={26} style={{marginRight: 20}}/>
                        </BackIcon>
                        <Title>{modalTitle}</Title>
                    </TitleBox>
                    {children}
                </Contents>
            </ModalScrollWrapper>
        </Wrapper>
    )
}

export default FullPageModalBackground;
