import React, {useCallback, useContext, useEffect, useState} from 'react';
import RightSlideModal from "../../../layout/RightSlideModal";
import {SettingContext} from "../../../../pages/Setting/setting";
import {CategoryList, CategoryRow, DefaultText, DeleteIcon, EmphasisText, Wrapper} from "./setting.modal.style";
import StyledInput from "../../../styled/StyledInput";
import {useMutation, useQuery} from "@apollo/client";
import Loader from "../../../share/Loader";
import { IoCloseOutline } from 'react-icons/io5';
import {errorMessage} from "../../../../utils/commons";
import {CREATE_MEDICAL_SUBJECT, DELETE_MEDICAL_SUBJECT } from "../../../../graphql/Manager/mutation";
import {toast} from "react-toastify";
import theme from "../../../../styles/theme";
import {SEE_MEDICAL_SUBJECT} from "../../../../graphql/Common/query";

const MedicalSubjectSettingModal = () => {
    const { siteMap, handleCloseSiteMap } = useContext(SettingContext);

    const [input, setInput] = useState('');

    const {data, loading, refetch} = useQuery(SEE_MEDICAL_SUBJECT, {
        fetchPolicy: 'network-only'
    });
    const [createMedicalSubject] = useMutation(CREATE_MEDICAL_SUBJECT);
    const [deleteMedicalSubject] = useMutation(DELETE_MEDICAL_SUBJECT);

    const handleClose = useCallback(() => {
        handleCloseSiteMap();
        setInput('');
    }, []);

    const handleCreate = useCallback(async () => {
        if (input === '') {
            toast.info('대분류 항목을 입력해주세요.');
            return;
        }

        try {
            const { data } = await createMedicalSubject({
                variables: {
                    msName: input
                }
            });

            if (data.createMedicalSubject) {
                toast.info('대분류 항목을 추가했습니다.');
                await refetch();
                setInput('');
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('대분류 등록에 실패했습니다.');
                    break;
                case 'err_01':
                    toast.error('이미 등록된 명칭입니다.');
                    break;
            }
            ;
        }
    }, [input]);

    const handleDelete = useCallback(async id => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const { data } = await deleteMedicalSubject({
                variables: {
                    msId: id
                }
            });

            if (data.deleteMedicalSubject) {
                toast.info('대분류 항목을 삭제했습니다.');
                await refetch();
            }

        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    return (
        <RightSlideModal
            title='예약 진료 항목 설정'
            visible={siteMap === '예약 진료 대분류 설정'}
            onClose={handleClose}
        >
            <Wrapper>
                <EmphasisText $margin='0 0 22px'>대분류 설정</EmphasisText>

                <StyledInput
                    ROW
                    CATEGORY
                    title='대분류 항목'
                    height={38}
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    placeholder='대분류 항목을 입력하세요.'
                    onClick={handleCreate}
                    onKeyPress={e => e.key === 'Enter' && handleCreate()}
                />

                <CategoryList>
                    {loading
                        ? <Loader/>
                        : data?.seeMedicalSubject?.medicalSubjectList?.map((item, index) => (
                            <CategoryRow key={`${index}-${item.ms_id}-list`}>
                                <DefaultText>{item.ms_name || ''}</DefaultText>

                                <DeleteIcon>
                                    <IoCloseOutline
                                        color={theme.colors.fontLightGrayColor}
                                        fontSize={20}
                                        onClick={() => handleDelete(item.ms_id)}
                                    />
                                </DeleteIcon>
                            </CategoryRow>
                        ))
                    }
                </CategoryList>
            </Wrapper>
        </RightSlideModal>
    );
}

export default MedicalSubjectSettingModal;
