import React, {useCallback, useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import dayjs from 'dayjs';
import {darken, lighten} from 'polished';
import theme from '../../../styles/theme';
import {BoldText, RegularText} from '../../styled/StyledText';
import noUser from '../../../assets/images/noUser.png';
import StyledFlexBox from '../../styled/StyledFlexBox';
import {
    IoChatbubbleEllipsesOutline,
    IoChevronForward,
    IoEllipsisHorizontalSharp,
    IoTrashOutline
} from 'react-icons/io5';
import {BiPencil} from 'react-icons/bi';
import {timeCalculator} from "../../../utils/commons";
import {useReactiveVar} from "@apollo/client";
import {userVar} from "../../../store";
import {toast} from "react-toastify";

const TableRow = styled.li`
  position: relative;

  ${({$detail}) => $detail && css`
    &:hover {
      background-color: ${theme.colors.lightWhiteBgColor};
      cursor: pointer;
    }
  `};
`;
const NoticeBox = styled.div`
  padding: 0 0 20px;
  display: flex;

  ${({$detail}) => $detail && css`
    padding: 0 40px 20px;
    align-items: flex-start;
    border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  `};
`;
const ImageBox = styled.div`
  width: 42px;
  height: 42px;
  margin: 0 16px 0 0;
  border-radius: 8px;
  border: 1px solid ${({$borderColor}) => $borderColor ? $borderColor : theme.colors.ultraLightGrayBorder};
  overflow: hidden;

  ${({$detail}) => $detail && css`
    margin: 40px 16px 0 0;
  `};
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Title = styled(BoldText)`
  font-size: 16px;
  margin-bottom: 8px;
`;
const ContentBox = styled.div`
  width: 100%;
  margin-bottom: 40px;

  ${({$detail}) => $detail && css`
    padding-top: 40px;
  `};
`;
const GrayText = styled(RegularText)`
  color: ${theme.colors.fontGrayColor};
  margin-right: 20px;
`;
const Contents = styled(RegularText)`
  width: 100%;
  line-height: 1.4;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-top: 20px;
  overflow: hidden;
`;
const CommentsBox = styled.div`
  height: 34px;
  display: flex;
  padding: 8px 16px;
  margin-top: 12px;
  position: relative;
  left: -16px;
  border-radius: 8px;

  &:hover {
    background-color: ${theme.colors.ultraLightGrayBorder};
  }
`;
const HoverWrapper = styled.div`
  width: 80px;
  height: 42px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: -21px;
  right: 35%;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  background-color: ${theme.colors.whiteColor};
  transition: .25s;

  ${TableRow}:hover & {
    //z-index: 5;
    opacity: 1;
    visibility: visible;
  }
`;
const HoverItem = styled.div`
  height: 100%;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;
const HoverBox = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HoverIcon = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.ultraLightGrayBorder};
  }
`;
const CommentButton = styled.button`
  height: 36px;
  color: ${theme.colors.whiteColor};
  padding: 8px 12px 6px;
  position: absolute;
  //z-index: 0;
  visibility: hidden;
  opacity: 0;
  top: -40px;
  left: 0;
  border-radius: 8px;
  background-color: ${theme.colors.activeIndigo};
  transition: .25s;

  ${HoverBox}.comments:hover & {
    //z-index: 1;
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    background-color: ${lighten(0.1, theme.colors.activeIndigo)};
  }

  &:active {
    background-color: ${darken(0.1, theme.colors.activeIndigo)};
  }
`;
const ButtonBox = styled.div`
  width: 120px;
  padding: 4px;
  border-radius: 8px;
  position: absolute;
  //z-index: -10;
  visibility: hidden;
  opacity: 0;
  bottom: -88px;
  left: 0;
  background-color: ${theme.colors.whiteColor};
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  transition: .25s;

  ${HoverBox}.updateDelete:hover & {
    //z-index: 1;
    opacity: 1;
    visibility: visible;
  }
`;
const Icon = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconButton = styled.button`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  border-radius: 8px;

  &.delete {
    color: ${theme.colors.activeRed};
  }

  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }

  &:active {
    background-color: ${darken(0.1, theme.colors.whiteHoverColor)};
  }
`;
const IconButtonText = styled.span`
  margin-left: 6px;
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 4px 0;
  background-color: ${theme.colors.ultraLightGrayBorder};
`;

const NoticeList = ({
                        DETAIL,
                        HOVER,
                        id,
                        title = '',
                        creator = '',
                        creatorId,
                        creatorRank = '',
                        creatorImage = '',
                        createDate = new Date(),
                        content = '',
                        commentLength = 0,
                        recentlyCommentDate = new Date(),
                        handleOpenComment,
                        handleOpenConfirm,
                        setNoticeModal,
                        setSelectedId
                    }) => {
    const now = dayjs();
    const user = useReactiveVar(userVar);
    const hoverRef = useRef();
    const [timeText, setTimeText] = useState('');

    useEffect(() => {
        let duration = now.diff(recentlyCommentDate, 'second');
        setTimeText(timeCalculator(duration));
    }, [recentlyCommentDate, now]);

    const handleOpenDetail = useCallback(e => {
        if (!hoverRef?.current?.contains(e.target)) {
            handleOpenComment(id);
        }
    }, [hoverRef, id]);

    const handleUpdate = useCallback(() => {
        if (user.user_id === creatorId) {
            setNoticeModal(true);
            setSelectedId(id);
        } else {
            toast.error('작성자만 수정할 수 있습니다.');
        }
    }, [user, creatorId, id, setNoticeModal, setSelectedId]);

    const handleDelete = useCallback(() => {
        if (user.user_id === creatorId) {
            handleOpenConfirm(id)
        } else {
            toast.error('작성자만 삭제할 수 있습니다.');
        }
    }, [user, creatorId, id, handleOpenConfirm]);

    if (DETAIL) {
        return (
            <TableRow
                $detail={DETAIL}
                onClick={handleOpenDetail}>
                <NoticeBox $detail={DETAIL}>
                    <ImageBox $detail={DETAIL}>
                        <Image src={creatorImage} onError={e => e.target.src = noUser}/>
                    </ImageBox>
                    <StyledFlexBox flexDirection='column' alignItems='flex-start'>
                        <ContentBox $detail={DETAIL}>
                            <Title>{title}</Title>
                            <StyledFlexBox>
                                <GrayText>{creator} {creatorRank}</GrayText>
                                <GrayText>{dayjs(createDate).format('YYYY-MM-DD HH:mm')}</GrayText>
                            </StyledFlexBox>
                            <Contents>
                                {content}
                            </Contents>

                            {/* Hover */}
                            {HOVER && (
                                <HoverWrapper ref={hoverRef}>
                                    <HoverItem>
                                        <HoverBox className='comments'>
                                            <HoverIcon onClick={() => handleOpenComment(id)}>
                                                <IoChatbubbleEllipsesOutline fontSize={18}/>
                                                <CommentButton>댓글 추가하기</CommentButton>
                                            </HoverIcon>
                                        </HoverBox>
                                        <HoverBox className='updateDelete'>
                                            <HoverIcon>
                                                <IoEllipsisHorizontalSharp fontSize={18}/>
                                                <ButtonBox>
                                                    <IconButton onClick={handleUpdate}>
                                                        <Icon>
                                                            <BiPencil fontSize={18}/>
                                                        </Icon>
                                                        <IconButtonText>수정하기</IconButtonText>
                                                    </IconButton>
                                                    <Line/>
                                                    <IconButton className='delete' onClick={handleDelete}>
                                                        <Icon>
                                                            <IoTrashOutline fontSize={18}/>
                                                        </Icon>
                                                        <IconButtonText>삭제하기</IconButtonText>
                                                    </IconButton>
                                                </ButtonBox>
                                            </HoverIcon>
                                        </HoverBox>
                                    </HoverItem>
                                </HoverWrapper>
                            )}
                        </ContentBox>
                        {commentLength > 0 && (
                            <CommentsBox>
                                <BoldText $fontSize={16}>{commentLength}개의 댓글</BoldText>&nbsp;
                                <GrayText> · {timeText}</GrayText>
                                <IoChevronForward color={theme.colors.fontDarkGrayColor} style={{marginLeft: 100}}/>
                            </CommentsBox>
                        )}
                    </StyledFlexBox>
                </NoticeBox>
            </TableRow>
        );
    }

    return (
        <TableRow>
            <NoticeBox>
                <ImageBox $borderColor={theme.colors.activeBlue}>
                    <Image src={creatorImage} onError={e => e.target.src = noUser}/>
                </ImageBox>
                <StyledFlexBox flexDirection='column' alignItems='flex-start'>
                    <ContentBox>
                        <Title>{title}</Title>
                        <StyledFlexBox>
                            <GrayText>{creator} {creatorRank}</GrayText>
                            <GrayText>{dayjs(createDate).format('YYYY-MM-DD HH:mm')}</GrayText>
                        </StyledFlexBox>
                        <Contents>
                            {content}
                        </Contents>
                    </ContentBox>
                </StyledFlexBox>
            </NoticeBox>
        </TableRow>
    );
}

export default React.memo(NoticeList);
