import styled from "styled-components";
import theme from "../../../styles/theme";
import {RegularText} from "../../styled/StyledText";

export const SiteMapWrapper = styled.div`
  width: calc(100% / 4);
  min-height: 240px;
  padding: 24px 40px 50px;
  border-right: ${({ $borderRight }) => $borderRight ? $borderRight : `1px solid ${theme.colors.ultraLightGrayBorder}`};
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};

  &:first-child {
    padding: 24px 0 50px;
  }
`;
export const Title = styled.div`
  margin-bottom: 28px;
`;
export const SiteList = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SiteMapItem = styled.div`
    
`;
export const BlueText = styled(RegularText)`
  display: inline-block;
  color: ${theme.colors.activeBlue};
  margin: 12px 0;
  cursor: pointer;
`;
export const UnderLine = styled.div`
  width: 0;
  height: 1px;
  margin-top: 4px;
  transform: translateX(-2px);
  background-color: ${theme.colors.activeBlue};
  transition: .25s;


  ${BlueText}:hover & {
    width: calc(100% + 5px);
  }
`;
export const GrayText = styled(RegularText)`
  line-height: 1.4;
  white-space: pre-wrap;
  word-break: break-all;
  color: ${theme.colors.fontLightGrayColor};
`;
