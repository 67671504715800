import React from 'react';
import styled, {css} from "styled-components";
import theme from "../../styles/theme";
import {IoSearchOutline} from 'react-icons/io5';

const Wrapper = styled.div`
  width: ${({$width}) => $width}px;
  margin: ${({$margin}) => ($margin ? $margin : 0)};
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div`
  width: ${({$titleWidth}) => $titleWidth}px;
  padding-top: 12px;
`;
const AddressBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const AddressSearchInput = styled.div`
  width: 304px;
  height: 38px;
  color: ${theme.colors.fontLightGrayColor};
  display: flex;
  align-items: center;
  padding: 4px 12px;
  margin-bottom: 12px;
  border: 1px solid ${theme.colors.lightGrayBorder};
  border-radius: 4px;
  background-color: ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;
  
  ${({ $active }) => $active && css`
    color: ${theme.colors.blackColor};
    background-color: ${theme.colors.whiteColor};
  `};
`;
const AddressText = styled.span`
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const Input = styled.input`
  width: 100%;
  height: ${({$height}) => $height}px;
  padding: 16px 12px;
  border: ${({$border}) => $border};
  border-radius: 4px;
`;

const StyledPostCode = ({
                            width = 440,
                            titleWidth = 136,
                            title = '주소',
                            height = 38,
                            margin = '0',
                            border = `1px solid ${theme.colors.lightGrayBorder}`,
                            address,
                            value,
                            onChange = () => null,
                            setPostModal,
                        }) => {

    return (
        <Wrapper $width={width} $margin={margin}>
            <Title $titleWidth={titleWidth}>{title}</Title>
            <AddressBox>
                <AddressSearchInput
                    $active={!!address}
                    onClick={() => setPostModal(true)}>
                    {address
                        ? <AddressText>{address}</AddressText>
                        : (
                            <>
                                <IoSearchOutline fontSize={18} />&nbsp;
                                <AddressText>주소검색</AddressText>
                            </>
                        )}
                </AddressSearchInput>
                <Input
                    $height={height}
                    $border={border}
                    name='detailAddress'
                    value={value}
                    onChange={onChange}
                    placeholder='상세 주소를 입력해주세요.'
                />
            </AddressBox>
        </Wrapper>
    );
}

export default StyledPostCode;
