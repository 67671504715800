import {gql} from "@apollo/client";

export const SEE_USER_LIST = gql`
    query SeeUserList(
        $searchTerm: String
        $filter: String
        $take: Int
        $cursor: Int
    ) {
        seeUserList(
            searchTerm: $searchTerm
            filter: $filter
            take: $take
            cursor: $cursor
        ) {
            totalLength
            userList {
                user_id
                user_createdAt
                user_updatedAt
                user_creatorName
                user_creatorRank
                user_creatorId
                user_editorName
                user_editorRank
                user_editorId
                user_isDelete
                user_deleteDate
                userType
                user_name
                user_birthday
                user_cellphone
                user_email
                user_org
                user_rank
                user_job
                user_passwordInit
                user_pwUpdateDate
                user_address
                user_detailAddress
                user_img
                user_hnAlim
                user_pnAlim
                user_resAlim
                user_specialAlim
                user_rankPermission {
                    home
                    reservation
                    schedule
                    patient
                    did
                    insurance
                    cs
                    setting
                }
            }
        }
    }
`;
