import React, {createContext, useCallback, useEffect, useLayoutEffect, useState} from "react";
import dayjs from "dayjs";
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {toast} from "react-toastify";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/layout/PageHeader";
import StyledCalendar from "../../components/styled/StyledCalendar";
import StyledSelect from "../../components/styled/StyledSelect";
import StyledIconButton from "../../components/styled/StyledIconButton";
import theme from "../../styles/theme";
import {appointmentPlatformOption, filterChange, statusOption} from "./appointment.lib";
import AppointmentModal from "../../components/feature/Appointment/AppointmentModal";
import {errorMessage, handleBodyScroll} from "../../utils/commons";
import AppointmentDetailModal from "../../components/feature/Appointment/AppointmentDetailModal";
import AppointmentList from "../../components/feature/Appointment/AppointmentList";
import AppointmentCalendarMonth from "../../components/feature/Appointment/AppointmentCalendarMonth";
import AppointmentCalendarDetailModal from "../../components/feature/Appointment/AppointmentCalendarDetailModal";
import AppointmentCalendarWeek from "../../components/feature/Appointment/AppointmentCalendarWeek";
import SearchModal from "../../components/share/SearchModal";
import {
    SEE_BY_MONTH_RESERVATION_LIST,
    SEE_BY_WEEK_RESERVATION_LIST,
    SEE_MEDICAL_SUBJECT,
    SEE_RESERVATION_LIST
} from "../../graphql/Appointment/query";
import {SEE_DEFAULT_SCHEDULE} from "../../graphql/Common/query";
import {DELETE_RESERVATION, UPDATE_RESERVATION_STATUS} from "../../graphql/Appointment/mutation";
import {FilterBox, IconBox, SortBox, SortingBox, Wrapper} from "./appointment.styles";
import {doctorsVar, userVar} from "../../store";

export const AppointmentContext = createContext(null);

const Appointment = () => {
    const navigate = useNavigate();
    const user = useReactiveVar(userVar);
    const doctorsOption = useReactiveVar(doctorsVar);

    const [date, setDate] = useState(new Date());
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState({
        status: '전체',
        doctors: '전체',
        appointmentPlatform: '전체',
        visitConfirm: '표기',
        largeCategory: '전체',
    });
    const [viewType, setViewType] = useState('list');
    // 리스트
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);
    const [majorClassificationOption, setMajorClassificationOption] = useState(['전체']);
    const [selectedId, setSelectedId] = useState(null);
    // 달력
    const [calendarSelectedDate, setCalendarSelectedDate] = useState(null);
    const [timeSelect, setTimeSelect] = useState(null);
    // 주차 별 시간
    const [scheduleTime, setScheduleTime] = useState([{operationTime: []}]);
    // 모달
    const [searchModal, setSearchModal] = useState(false);
    const [appointmentModal, setAppointmentModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [calendarDetailModal, setCalendarDetailModal] = useState(false);

    // Query
    const {data: subjectData, loading: subjectLoading} = useQuery(SEE_MEDICAL_SUBJECT);
    const {data: scheduleData} = useQuery(SEE_DEFAULT_SCHEDULE);
    const {data: listData, loading: listLoading, refetch: listRefetch} = useQuery(SEE_RESERVATION_LIST, { // 목록 보기
        variables: {
            searchTerm: searchTerm,
            searchDate: date,
            status: filterChange(filter.status),
            doctorRoom: filter.doctors === '전체' ? 'total' : filter.doctors,
            resPlatform: filterChange(filter.appointmentPlatform),
            visitConfirm: filter.visitConfirm === '표기',
            largeCategory: filter.largeCategory === '전체' ? 'total' : filter.largeCategory,
            take: take,
            cursor: cursor,
        },
        fetchPolicy: 'network-only',
        pollInterval: 5000
    });

    const {data: monthData, loading: monthLoading, refetch: monthRefetch} = useQuery(SEE_BY_MONTH_RESERVATION_LIST, { // 월간 보기
        variables: {
            searchTerm: filter.searchTerm,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            status: filterChange(filter.status),
            doctorRoom: filter.doctors === '전체' ? 'total' : filter.doctors,
            resPlatform: filterChange(filter.appointmentPlatform),
            visitConfirm: filter.visitConfirm === '표기',
            largeCategory: filter.largeCategory === '전체' ? 'total' : filter.largeCategory,
        },
        fetchPolicy: 'network-only',
        pollInterval: 5000
    });
    const {data: weekData, loading: weekLoading, refetch: weekRefetch} = useQuery(SEE_BY_WEEK_RESERVATION_LIST, { // 주간 보기
        variables: {
            searchTerm: filter.searchTerm,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            date: date.getDate(),
            status: filterChange(filter.status),
            doctorRoom: filter.doctors === '전체' ? 'total' : filter.doctors,
            resPlatform: filterChange(filter.appointmentPlatform),
            visitConfirm: filter.visitConfirm === '표기',
            largeCategory: filter.largeCategory === '전체' ? 'total' : filter.largeCategory,
        },
        fetchPolicy: 'network-only',
        pollInterval: 5000
    });
    const [updateReservationStatus] = useMutation(UPDATE_RESERVATION_STATUS);
    const [deleteReservation] = useMutation(DELETE_RESERVATION);

    const handleAddAppointment = useCallback(() => setAppointmentModal(true), []); // 예약자 추가

    const handleChangeStatus = useCallback(async (reId, status, callback = () => null) => { // 예약 상태 변경
        try {
            const {data} = await updateReservationStatus({
                variables: {
                    reId: reId,
                    status: filterChange(status)
                }
            });

            if (data.updateReservationStatus) {
                toast.info('예약 상태를 변경했습니다.');
                await listRefetch();
                await monthRefetch();
                await weekRefetch();
                callback();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    const handleUpdateAppointment = useCallback((reId, callback = () => null) => { // 예약 수정
        window.scrollTo({top: 0});
        setSelectedId(reId);
        setAppointmentModal(true);
        callback();
    }, []);

    const handleDeleteAppointment = useCallback(async (reId, callBack = () => null) => { // 예약 삭제
        try {
            const {data} = await deleteReservation({
                variables: {
                    reId: reId
                }
            });

            if (data.deleteReservation) {
                await listRefetch();
                await monthRefetch();
                await weekRefetch();
                toast.info('예약 내역을 삭제했습니다.');
                callBack();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, []);

    const onChangeFilter = useCallback(e => { // filter onChange
        const {name, value} = e.target;
        setFilter({
            ...filter,
            [name]: value
        });
        setSearchTerm('');
    }, [filter]);

    const onClickPage = useCallback(page => { // 페이지
        setPage(page);
        if (page === 1) {
            setCursor(0);
        } else {
            setCursor((page - 1) * take);
        }
    }, [take]);

    const handlePrevMonth = useCallback(() => { // 이전 달 클릭
        if (viewType === 'calendarMonth') {
            const prevMonth = dayjs(date).subtract(1, 'month');
            setDate(new Date(prevMonth.format('YYYY-MM-DD')));
        } else {
            const prevMonth = dayjs(date).subtract(1, 'day');
            setDate(new Date(prevMonth.format('YYYY-MM-DD')));
        }
        setSearchTerm('');
    }, [date, viewType]);

    const handleNextMonth = useCallback(() => { // 다음 달 클릭
        if (viewType === 'calendarMonth') {
            const nextMonth = dayjs(date).add(1, 'month');
            setDate(new Date(nextMonth.format('YYYY-MM-DD')));
        } else {
            const nextMonth = dayjs(date).add(1, 'day');
            setDate(new Date(nextMonth.format('YYYY-MM-DD')));
        }
        setSearchTerm('');
    }, [date, viewType]);

    useLayoutEffect(() => { // 각 options 값 초기화
        if (!subjectLoading && subjectData) { // 대분류 option 값
            const tmpData = subjectData?.seeMedicalSubject?.medicalSubjectList.map(item => item.ms_name);
            setMajorClassificationOption(majorClassificationOption.concat(tmpData));
        }
    }, [subjectLoading, subjectData]);

    useEffect(() => { // 모달 open 시 body scroll control
        if (appointmentModal || detailModal || calendarDetailModal) {
            handleBodyScroll('hidden');
        } else {
            handleBodyScroll('initial');
        }
    }, [appointmentModal, detailModal, calendarDetailModal]);

    useEffect(() => { // 운영시간
        const tmpSchedule = scheduleData?.seeDefaultSchedule?.defaultScheduleList.map(data => {
            let operationTime = [];

            for (
                let i = (data.ds_startHour === 0 ? 7 : data.ds_startHour);
                (i <= (data.ds_endHour === 0 ? 22 : data.ds_endHour));
                i++
            ) {
                operationTime.push(i);
            }

            return {operationTime}
        });

        setScheduleTime(tmpSchedule);
    }, [scheduleData]);

    useEffect(() => {
        if (searchTerm.length > 0) {
            setCursor(0);
            setPage(1);
        }
    }, [searchTerm]);

    useLayoutEffect(() => {
        if (!user.user_rankPermission.reservation) {
            navigate('/home');
            toast.error('접근 권한이 없습니다.');
        }
    }, [user]);

    return (
        <AppointmentContext.Provider value={{
            date,
            doctorsOption, // 진료실 정보 옵션
            setDetailModal, // 상세보기 모달
            appointmentModal, // 추가 수정 모달
            setAppointmentModal, // 추가, 수정 모달
            selectedId, // 선택 리스트 id
            setSelectedId,
            listRefetch,
            monthRefetch,
            weekRefetch,
            calendarSelectedDate, // 달력 선택 일
            setCalendarDetailModal,
            timeSelect, // 달력 상세보기에서 시간 클릭
            setTimeSelect,
            handleChangeStatus, // 예약 상태 변경
            handleUpdateAppointment, // 예약 수정
            handleDeleteAppointment, // 예약 삭제
        }}>
            <Wrapper>
                <PageHeader
                    APPOINTMENT
                    title='예약목록'
                    guideLine='예약 > 예약 목록'
                    onClick={handleAddAppointment}>
                    <SortingBox>
                        <FilterBox>
                            <SortBox>
                                <StyledCalendar
                                    title={viewType !== 'calendarWeek' ? '기간 : ' : '시작일 : '}
                                    inputWidth={viewType === 'calendarMonth' ? 66 : 88}
                                    value={date}
                                    dateFormat={viewType === 'calendarMonth' ? 'yyyy-MM' : 'yyyy-MM-dd'}
                                    onChange={date => setDate(date)}
                                    handlePrevMonth={handlePrevMonth}
                                    handleNextMonth={handleNextMonth}
                                />
                                <StyledSelect
                                    NO_BORDER
                                    width={160}
                                    margin='0 20px'
                                    title='상태 : '
                                    name='status'
                                    value={filter.status}
                                    onChange={onChangeFilter}
                                    options={['전체', ...statusOption]}
                                />
                                <StyledSelect
                                    NO_BORDER
                                    width={160}
                                    margin='0 20px'
                                    title='진료실 : '
                                    name='doctors'
                                    value={filter.doctors}
                                    onChange={onChangeFilter}
                                    options={['전체', ...doctorsOption.map(room => room.dr_roomName)]}
                                />
                                <StyledSelect
                                    NO_BORDER
                                    width={180}
                                    margin='0 20px'
                                    title='예약매체 : '
                                    options={appointmentPlatformOption}
                                    name='appointmentPlatform'
                                    value={filter.appointmentPlatform}
                                    onChange={onChangeFilter}
                                />
                                <StyledSelect
                                    NO_BORDER
                                    width={150}
                                    margin='0 20px'
                                    title='내원확정 : '
                                    options={['표기', '미표기']}
                                    name='visitConfirm'
                                    value={filter.visitConfirm}
                                    onChange={onChangeFilter}
                                />
                                <StyledSelect
                                    NO_BORDER
                                    width={filter.largeCategory.length === 2 ? 150 : filter.largeCategory.length * 24}
                                    margin='0 20px'
                                    title='대분류 : '
                                    options={majorClassificationOption}
                                    name='largeCategory'
                                    value={filter.largeCategory}
                                    onChange={onChangeFilter}
                                />
                            </SortBox>
                            <IconBox>
                                <StyledIconButton // 리프레쉬
                                    REFRESH
                                    onClick={() => setSearchTerm('')}
                                />
                                <StyledIconButton // 검색
                                    SEARCH
                                    margin='0 12px'
                                    onClick={() => setSearchModal(true)}
                                />
                                <StyledIconButton // 리스트 보기
                                    LIST
                                    topRightRadius={0}
                                    bottomRightRadius={0}
                                    margin='0 0 0 -1px'
                                    bgColor={viewType === 'list' ? theme.colors.ultraLightGrayBorder : theme.colors.whiteColor}
                                    onClick={() => setViewType('list')}
                                />
                                {/*{(viewType === 'calendarWeek' || viewType === 'calendarMonth') && (*/}
                                <StyledIconButton // 달력 주간 보기
                                    CALENDAR_WEEK
                                    topLeftRadius={0}
                                    topRightRadius={0}
                                    bottomLeftRadius={0}
                                    bottomRightRadius={0}
                                    margin='0 0 0 -1px'
                                    bgColor={viewType === 'calendarWeek' ? theme.colors.ultraLightGrayBorder : theme.colors.whiteColor}
                                    onClick={() => setViewType('calendarWeek')}
                                />
                                {/* )}*/}
                                <StyledIconButton // 달력 월간 보기
                                    CALENDAR_MONTH
                                    topLeftRadius={0}
                                    bottomLeftRadius={0}
                                    margin='0 0 0 -1px'
                                    bgColor={viewType === 'calendarMonth' ? theme.colors.ultraLightGrayBorder : theme.colors.whiteColor}
                                    onClick={() => setViewType('calendarMonth')}
                                />
                            </IconBox>
                        </FilterBox>
                    </SortingBox>
                </PageHeader>

                {viewType === 'list' && ( // 예약 목록
                    <AppointmentList
                        date={date}
                        listLoading={listLoading}
                        listData={listData}
                        take={take}
                        setTake={setTake}
                        page={page}
                        onClickPage={onClickPage}
                    />)}

                {viewType === 'calendarWeek' && ( // 주별 예약 목록
                    <AppointmentCalendarWeek
                        weekLoading={weekLoading}
                        weekData={weekData?.seeByWeekReservationList}
                        scheduleTime={scheduleTime}
                        setCalendarSelectedDate={setCalendarSelectedDate}
                        setCalendarDetailModal={setCalendarDetailModal}
                    />)}

                {viewType === 'calendarMonth' && ( // 월별 예약 목록
                    <AppointmentCalendarMonth
                        monthLoading={monthLoading}
                        monthData={monthData?.seeByMonthReservationList}
                        setCalendarSelectedDate={setCalendarSelectedDate}
                        setCalendarDetailModal={setCalendarDetailModal}
                    />)}

                <SearchModal // 검색하기 모달
                    searchModal={searchModal}
                    setSearchModal={setSearchModal}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />

                <AppointmentModal // 추가, 수정 모달
                />

                <AppointmentDetailModal // 리스트 상세보기 모달
                    detailModal={detailModal}
                />

                <AppointmentCalendarDetailModal // 달력 상세보기 모달
                    scheduleTime={scheduleTime}
                    calendarDetailModal={calendarDetailModal}
                />
            </Wrapper>
        </AppointmentContext.Provider>
    );
}

export default Appointment;
