import {gql} from '@apollo/client';

export const USER_LOGIN = gql`
    mutation UserLogin($accountId: String!, $password: String!) {
        userLogin(accountId: $accountId, password: $password)
    }
`;

export const FIND_USER_ACCOUNT_ID = gql`
    mutation FindUserAccountId($hospitalName: String!, $cellphone: String) {
        findUserAccountId(hospitalName: $hospitalName, cellphone: $cellphone)
    }
`;

export const FIND_USER_PASSWORD = gql`
    mutation FindUserPassword($email: String!) {
        findUserPassword(email: $email)
    }
`;
