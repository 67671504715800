import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {useReactiveVar} from "@apollo/client";
import {IoMail, IoArrowForwardOutline} from 'react-icons/io5';
import {BiKey, BiSolidBell} from 'react-icons/bi';
import ModalBackground from "../../layout/ModalBackground";
import theme from "../../../styles/theme";
import {BoldText, RegularText} from "../../styled/StyledText";
import {userVar} from "../../../store";
import UpdateEmailModal from "./UpdateEmailModal";
import UpdatePasswordModal from "./UpdatePasswordModal";
import UpdateNotificationModal from "./UpdateNotificationModal";

const Contents = styled.div`
  width: calc(100% + 80px);
  height: 300px;
  position: relative;
  left: -40px;
`;
const TabButton = styled.div`
  width: 100%;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.ultraLightGrayBorder};
  cursor: pointer;

  &:nth-child(1) {
    border-top: 1px solid ${theme.colors.ultraLightGrayBorder};
  }

  &:hover {
    background-color: ${theme.colors.lightWhiteBgColor};
  }
`;
const ImageBox = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${({$bgColor}) => $bgColor};
`;
const InfoBox = styled.div`
  margin-left: 16px;
  flex: 1;
`;
const Title = styled(BoldText)`
  font-size: 16px;
  margin-bottom: 6px;
`;
const GrayText = styled(RegularText)`
  color: ${theme.colors.fontLightGrayColor};
`;
const ArrowBox = styled.div``;
const SettingSubPage = styled.div`
  width: 100%;
  height: 100%;
`;

const MySettingModal = ({
                            mySettingModal,
                            setMySettingModal
                        }) => {
    const user = useReactiveVar(userVar);
    const [updateEmail, setUpdateEmail] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [updateNotification, setUpdateNotification] = useState(false);

    const handleClose = useCallback(() => {
        setMySettingModal(false);
        setUpdateEmail(false);
        setUpdatePassword(false);
        setUpdateNotification(false);
    }, [setMySettingModal]);

    return (
        <ModalBackground
            CONFIRM={updateEmail || updatePassword}
            title='계정 및 환경설정'
            height={720}
            visible={mySettingModal}
            onClose={handleClose}
        >
            <Contents>
                <TabButton onClick={() => setUpdateEmail(true)}>
                    <ImageBox $bgColor={theme.colors.activeLightOrange}>
                        <IoMail fontSize={20} color={theme.colors.whiteColor}/>
                    </ImageBox>
                    <InfoBox>
                        <Title>이메일 변경</Title>
                        <GrayText>
                            {user.user_email}
                        </GrayText>
                    </InfoBox>
                    <ArrowBox>
                        <IoArrowForwardOutline fontSize={18} color={theme.colors.grayBorder}/>
                    </ArrowBox>
                </TabButton>
                <TabButton onClick={() => setUpdatePassword(true)}>
                    <ImageBox $bgColor={theme.colors.fontLightGrayColor}>
                        <BiKey fontSize={24} color={theme.colors.whiteColor}/>
                    </ImageBox>
                    <InfoBox>
                        <Title>비밀번호 변경</Title>
                        <GrayText>
                            6개월에 한번씩 비밀번호를 바꾸기를 권장해요.
                        </GrayText>
                    </InfoBox>
                    <ArrowBox>
                        <IoArrowForwardOutline fontSize={18} color={theme.colors.grayBorder}/>
                    </ArrowBox>
                </TabButton>
                <TabButton onClick={() => setUpdateNotification(true)}>
                    <ImageBox $bgColor={theme.colors.activeBlue}>
                        <BiSolidBell fontSize={20} color={theme.colors.whiteColor}/>
                    </ImageBox>
                    <InfoBox>
                        <Title>알림 설정</Title>
                        <GrayText>
                            알림을 자유롭게 설정할 수 있어요.
                        </GrayText>
                    </InfoBox>
                    <ArrowBox>
                        <IoArrowForwardOutline fontSize={18} color={theme.colors.grayBorder}/>
                    </ArrowBox>
                </TabButton>
            </Contents>
            <SettingSubPage>
                <UpdateEmailModal
                    updateEmail={updateEmail}
                    setUpdateEmail={setUpdateEmail}
                    onClose={handleClose}
                />
                <UpdatePasswordModal
                    updatePassword={updatePassword}
                    setUpdatePassword={setUpdatePassword}
                    onClose={handleClose}
                />
                <UpdateNotificationModal
                    updateNotification={updateNotification}
                    setUpdateNotification={setUpdateNotification}
                    onClose={handleClose}
                />
            </SettingSubPage>
        </ModalBackground>
    )
}

export default MySettingModal;
