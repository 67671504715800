import React from 'react';
import styled, {css} from 'styled-components';
import theme from '../../styles/theme';
import {RegularText} from "./StyledText";
import {darken} from "polished";

const Wrapper = styled.div`
  width: ${({$width}) => $width}px;
  margin: ${({$margin}) => ($margin ? $margin : 0)};

  ${({$ROW}) => $ROW && css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;
const ColumnTitle = styled(RegularText)`
  margin-bottom: 8px;
`;
const RowTitle = styled(RegularText)`
  width: ${({$titleWidth}) => $titleWidth}px !important;
`;
const ButtonInputBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
const Input = styled.input`
  width: 100%;
  height: ${({$height}) => $height}px;
  flex: 1;
  padding: 16px 12px;
  border: ${({$border}) => $border};
  border-radius: 4px;

  ${({$AUTH}) => $AUTH && css`
    width: calc(100% - 80px);
  `};
`;
const Asterisk = styled.span`
  color: ${theme.colors.activeRed};
  margin-left: 4px;
`;
const AuthButton = styled.button`
  width: 80px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightGrayBorder};
  
  &:hover {
    background-color: ${theme.colors.whiteHoverColor};
  }

  &:active {
    background-color: ${darken(0.1, theme.colors.whiteHoverColor)};
  }
`;

const StyledInput = ({
                         ROW,
                         ASTERISK,
                         AUTH,
                         CATEGORY,
                         width = 440,
                         height = 50,
                         titleWidth = 136,
                         margin,
                         border = `1px solid ${theme.colors.lightGrayBorder}`,
                         title = '',
                         name,
                         value,
                         type = 'text',
                         onChange,
                         onClick = () => null,
                         onClickSendAuthCode = () => null,
                         onKeyUp = () => null,
                         onKeyPress = () => null,
                         maxLength = 200,
                         disabled = false,
                         ref,
                         placeholder
                     }) => {

    if (ROW) {
        return (
            <Wrapper $ROW={ROW} $width={width} $margin={margin}>
                {title && (
                    <RowTitle $titleWidth={titleWidth}>
                        {title}
                        {ASTERISK && <Asterisk>*</Asterisk>}
                    </RowTitle>
                )}
                {(!AUTH && !CATEGORY) && (
                    <Input
                        $AUTH={AUTH}
                        $titleWidth={titleWidth}
                        $height={height}
                        $border={border}
                        name={name}
                        value={value}
                        type={type}
                        onChange={onChange}
                        onKeyUp={onKeyUp}
                        onKeyPress={onKeyPress}
                        maxLength={maxLength}
                        ref={ref}
                        disabled={disabled}
                        placeholder={placeholder}
                    />
                )}


                {AUTH && (
                    <ButtonInputBox>
                        <Input
                            $AUTH={AUTH}
                            $titleWidth={titleWidth}
                            $height={height}
                            $border={border}
                            name={name}
                            value={value}
                            type={type}
                            onChange={onChange}
                            onKeyUp={onKeyUp}
                            onKeyPress={onKeyPress}
                            maxLength={maxLength}
                            ref={ref}
                            disabled={disabled}
                            placeholder={placeholder}
                        />
                        <AuthButton onClick={onClickSendAuthCode}>
                            인증하기
                        </AuthButton>
                    </ButtonInputBox>
                )}

                {CATEGORY && (
                    <ButtonInputBox>
                        <Input
                            $AUTH={CATEGORY}
                            $titleWidth={titleWidth}
                            $height={height}
                            $border={border}
                            name={name}
                            value={value}
                            type={type}
                            onChange={onChange}
                            onKeyUp={onKeyUp}
                            onKeyPress={onKeyPress}
                            maxLength={maxLength}
                            ref={ref}
                            disabled={disabled}
                            placeholder={placeholder}
                        />
                        <AuthButton onClick={onClick}>
                            추가하기
                        </AuthButton>
                    </ButtonInputBox>
                )}
            </Wrapper>
        );
    }

    return (
        <Wrapper $width={width} $margin={margin}>
            {title && (
                <ColumnTitle>
                    {title}
                    {ASTERISK && <Asterisk>*</Asterisk>}
                </ColumnTitle>
            )}
            <Input
                $height={height}
                $border={border}
                name={name}
                value={value}
                type={type}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onKeyPress={onKeyPress}
                maxLength={maxLength}
                ref={ref}
                disabled={disabled}
                placeholder={placeholder}
            />
        </Wrapper>
    );
};

export default React.memo(StyledInput);
