import theme from "../../styles/theme";
import {toast} from "react-toastify";

export const createMonitorState = {
    name: '',
    monitorType: '가로모니터',
    mediaType: '영상',
    usingAppointmentInfo: '사용',
    transmissionMethod: '상시 노출',
    appointmentInfoLocation: '위',
    screenRatio: '1 : 1',
    // usingAd: '사용'
}

export const defaultState = {
    name: '',
    clinicDisplayMethod: '진료실명 - 의사명',
    standbyPersonDisplayMethod: '00 명',
    usingEmergencyPatient: '사용',
    emergencyPatientColor: '핑크색',
    usingHolders: '사용',
    holdersColor: '초록색',
    holdersFontSize: '크게',
    callerFontSize: '크게',
    usingCallPhrase: '사용',
    usingCallVoice: '사용'
}

export const screenState = {
    monitorType: '가로모니터',
    mediaType: '이미지',
    usingAppointmentInfo: '사용',
    transmissionMethod: '상시 노출',
    appointmentInfoLocation: '위',
    screenRatio: '1 : 1',
    patientInfo_1st: '사용안함',
    ratio_1st: '0 %',
    patientInfo_2nd: '사용안함',
    ratio_2nd: '0 %',
    patientInfo_3rd: '사용안함',
    ratio_3rd: '0 %',
    patientInfo_4th: '사용안함',
    ratio_4th: '0 %',
    // 이미지 일 경우
    appointmentInfoDisplayTime: '10 초',
    appointmentInfoDisplayCycle: '30 초',
    isNameMaking: false,
    isDoctorsHorizontal: false,
    usingMessage: 'true'
}

export const clinicState = {
    clinicIntegratedView: '미사용',
    clinicRoom: []
}

export const ratioOptions = ['0 %', '10 %', '20 %', '30 %', '40 %', '50 %', '60 %', '70 %', '80 %', '90 %', '100 %'];
export const patientInfoOrderOptions = ['사용안함', '수진자명', '진료실', '성별', '접수시간', '담당의', '대기상태', '차트번호', '생년월일', '나이', '접수메모'];

export const colorChange = color => {
    switch (color) {
        case '핑크색':
            return theme.colors.activePink;
        case '주황색':
            return theme.colors.activeLightOrange;
        case '노랑색':
            return theme.colors.activeYellow;
        case '초록색':
            return theme.colors.activeGreen;
        case '연두색':
            return theme.colors.activeYellowGreen;
        case '회색':
            return theme.colors.activeGray;
    }
}

export const filterChange = type => {
    switch (type) {
        case '전체':
            return 'total';
        case '영상':
            return 'video';
        case '이미지':
            return 'image';
        default:
            return 'total';
    }
}

// 예약 정보 위치
export const resLocationChange = location => {
    switch (location) {
        case 'up':
            return '위';
        case 'down':
            return '아래';
        case 'left':
            return '좌';
        case 'right':
            return '우';
        case '위':
            return 'up';
        case '아래':
            return 'down';
        case '좌':
            return 'left';
        case '우':
            return 'right';
        default:
            return 'up';
    }
}

// 모니터 종류
export const monitorTypeChange = type => {
    switch (type) {
        case '가로모니터':
            return 'horizontal';
        case '세로모니터':
            return 'vertical';
        case 'horizontal':
            return '가로모니터';
        case 'vertical':
            return '세로모니터';
        default:
            return 'horizontal';
    }
}

// 모니터 종류
export const mediaTypeChange = type => {
    switch (type) {
        case '영상':
            return 'video';
        case '이미지':
            return 'image';
        case 'video':
            return '영상';
        case 'image':
            return '이미지';
    }
}

export const booleanTypeChange = boolean => {
    switch (boolean) {
        case true:
            return '사용';
        case false:
            return '미사용';
        case '사용':
        case 'O':
            return true;
        case '미사용':
        case 'X':
            return false;
        default:
            return true;
    }
}

export const transmitMethodChange = method => {
    switch (method) {
        case 'always':
            return '상시 노출';
        case 'sometimes':
            return '일시 노출';
        case '상시 노출':
            return 'always';
        case '일시 노출':
            return 'sometimes';
    }
}

export const patientInfoTypeChange = type => {
    switch (type) {
        case '사용안함':
            return 'empty';
        case '수진자명':
            return 'patName';
        case '진료실':
            return 'doctorRoom';
        case '성별':
            return 'gender';
        case '접수시간':
            return 'time';
        case '담당의':
            return 'doctor';
        case '대기상태':
            return 'status';
        case '차트번호':
            return 'chartNumber';
        case '생년월일':
            return 'birthday';
        case '나이':
            return 'age';
        case '접수메모':
            return 'memo';

        case 'empty':
            return '사용안함';
        case 'patName':
            return '수진자명';
        case 'doctorRoom':
            return '진료실';
        case 'gender':
            return '성별';
        case 'time':
            return '접수시간';
        case 'doctor':
            return '담당의';
        case 'status':
            return '대기상태';
        case 'chartNumber':
            return '차트번호';
        case 'birthday':
            return '생년월일';
        case 'age':
            return '나이';
        case 'memo':
            return '접수메모';

    }
}

export const replacedAndParsedText = (text, replacedWord) => {
    return parseInt(text.replace(replacedWord, ''), 10);
}

export const screenRatioValidate = (
    patientInfo_1st,
    patientInfo_2nd,
    patientInfo_3rd,
    patientInfo_4th,
    ratio_1st,
    ratio_2nd,
    ratio_3rd,
    ratio_4th,
) => {
    let valid;

    if (
        patientInfo_1st === '사용안함' &&
        patientInfo_2nd === '사용안함' &&
        patientInfo_3rd === '사용안함' &&
        patientInfo_4th === '사용안함'
    ) {
        return;
    }
    if (
        replacedAndParsedText(ratio_1st, ' %') +
        replacedAndParsedText(ratio_2nd, ' %') +
        replacedAndParsedText(ratio_3rd, ' %') +
        replacedAndParsedText(ratio_4th, ' %')
        !== 100
    ) {
        // toast.error(`환자정보 표시 비율의 합을 "100%"로 설정해주세요.`);
        toast.error(() => (
            <div>
                환자정보 표시비율의 합을<br/>
                "100%"로 설정해주세요.
            </div>
        ));
        valid = true;
    }

    return valid;
}

export const fileSizeValidation = (files) => {
    const fileArray = Array.from(files);
    let totalSize = 0;
    let valid = false;

    for (let i = 0; i < fileArray.length; i++) {
        totalSize = totalSize += (fileArray[i].size || 0);
        if (totalSize > (1024 * 1024 * 10)) {
            valid = true;
        }
    }
    return valid;
}
