import React, {useCallback} from 'react';
import styled, {css} from 'styled-components';
import DatePicker from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import {IoChevronDownOutline} from 'react-icons/io5';
import theme from '../../styles/theme';
import {RegularText} from "./StyledText";
import {CustomHeader} from "./StyledCalendar";
import {birthFormatter} from "../../utils/commons";
import {toast} from "react-toastify";

const Wrapper = styled.div`
  width: ${({$width}) => $width}px;
  margin: ${({$margin}) => ($margin ? $margin : 0)};

  ${({$ROW}) => $ROW && css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: ${theme.colors.whiteColor};
    //font-weight: normal;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: ${theme.colors.activeBlue};
  }

  .react-datepicker__header {
    background-color: ${theme.colors.activeBlue};
  }

  .react-datepicker__day--selected {
    background-color: ${theme.colors.activeBlue};
  }

  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: ${theme.colors.whiteColor};
  }
`;
const ColumnTitle = styled(RegularText)`
  margin-bottom: 8px;
`;
const RowTitle = styled(RegularText)`
  width: ${({$titleWidth}) => $titleWidth}px;
`;
const BorderCalender = styled.div`
  height: ${({$height}) => $height}px;
  padding: 0 12px;
  flex: 1;
  position: relative;
  border: 1px solid ${theme.colors.lightGrayBorder};
  border-radius: 4px;

  input {
    width: 100%;
    height: 100%;
    font-family: 'AppleSDGothicNeoRegular';
    cursor: pointer;
  }

  ${({$ROW}) => $ROW && css`
    width: 100%;
  `};
`;
const Arrow = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0, -50%);
`;
const Asterisk = styled.span`
  color: ${theme.colors.activeRed};
  margin-left: 4px;
`;

const StyledBorderCalendar = ({
                                  ROW,
                                  ASTERISK,
                                  width = 440,
                                  height = 50,
                                  titleWidth = 136,
                                  margin,
                                  title,
                                  dateFormat = 'yyyy-MM-dd',
                                  value = new Date(),
                                  onChange
                              }) => {
    const onChangeRaw = useCallback((e) => {
        const { value } = e.target;

        if (!value) return;
        if (value?.length > 8) return;
        if (value?.length === 8) {
            const birthday = new Date(birthFormatter(value, 'YYYY-MM-DD'))

            if (isNaN(birthday.getTime()) === false) {
                onChange(birthday);
            } else {
                toast.error('입력하신 날짜는 존재하지 않습니다.');
            }
        }
    }, []);

    if (ROW) {
        return (
            <Wrapper
                $ROW={ROW}
                $width={width}
                $margin={margin}>
                <RowTitle $titleWidth={titleWidth}>
                    {title}
                    {ASTERISK && <Asterisk>*</Asterisk>}
                </RowTitle>
                <BorderCalender $ROW={ROW} $height={height}>
                    <DatePicker
                        dateFormat={dateFormat}
                        locale={ko}
                        selected={value}
                        onChange={onChange}
                        onChangeRaw={onChangeRaw}
                        renderCustomHeader={({
                                                 date,
                                                 changeYear,
                                                 changeMonth,
                                                 decreaseMonth,
                                                 increaseMonth,
                                                 prevMonthButtonDisabled,
                                                 nextMonthButtonDisabled,
                                             }) => (
                            <CustomHeader
                                date={date}
                                changeYear={changeYear}
                                changeMonth={changeMonth}
                                decreaseMonth={decreaseMonth}
                                increaseMonth={increaseMonth}
                                prevMonthButtonDisabled={prevMonthButtonDisabled}
                                nextMonthButtonDisabled={nextMonthButtonDisabled}
                            />
                        )}
                    />
                    <Arrow>
                        <IoChevronDownOutline color={theme.colors.fontLightGrayColor} fontSize={18}/>
                    </Arrow>
                </BorderCalender>
            </Wrapper>
        )
    }

    return (
        <Wrapper
            $margin={margin}
            $width={width}>
            <ColumnTitle>
                {title}
                {ASTERISK && <Asterisk>*</Asterisk>}
            </ColumnTitle>
            <BorderCalender $height={height}>
                <DatePicker
                    dateFormat={dateFormat}
                    locale={ko}
                    selected={value}
                    onChange={onChange}
                    onChangeRaw={onChangeRaw}
                />
                <Arrow onClick={() => {
                }}>
                    <IoChevronDownOutline color={theme.colors.fontLightGrayColor} fontSize={18}/>
                </Arrow>
            </BorderCalender>
        </Wrapper>
    );
};

export default React.memo(StyledBorderCalendar);
