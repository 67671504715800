import React, {createContext, useCallback, useState} from 'react';
import PageHeader from "../../components/layout/PageHeader";
import StyledCalendar from "../../components/styled/StyledCalendar";
import StyledSelect from "../../components/styled/StyledSelect";
import StyledIconButton from "../../components/styled/StyledIconButton";
import theme from "../../styles/theme";
import dayjs from "dayjs";
import {Wrapper, FilterBox, SortBox, SortingBox} from "./dayOff.style";
import DayOffList from "../../components/feature/HospitalDayOff/DayOffList";
import {useQuery} from "@apollo/client";
import {SEE_BY_MONTH_HOSPITAL_OFF_DAY, SEE_HOSPITAL_OFF_DAY} from "../../graphql/Setting/query";
import {dayOffTypeChange} from "./dayOff.lib";
import DayOffCalendarMonth from "../../components/feature/HospitalDayOff/DayOffCalendarMonth";
import DayOffModal from "../../components/feature/HospitalDayOff/DayOffModal";
import SearchModal from "../../components/share/SearchModal";

export const DayOffContext = createContext(null);

const DayOff = () => {
    const [date, setDate] = useState(new Date());
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('전체');
    const [viewType, setViewType] = useState('list');
    const [searchModal, setSearchModal] = useState(false);
    const [dayOffModal, setDayOffModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const { data, loading, refetch } = useQuery(SEE_HOSPITAL_OFF_DAY, {
        variables: {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            offType: dayOffTypeChange(filter),
            searchTerm: searchTerm
        },
        fetchPolicy: 'network-only'
    });
    const { data: monthData, loading: monthLoading, refetch: monthRefetch } = useQuery(SEE_BY_MONTH_HOSPITAL_OFF_DAY, {
        variables: {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            offType: dayOffTypeChange(filter),
            searchTerm: searchTerm
        },
        fetchPolicy: 'network-only'
    });

    const handlePrevMonth = useCallback(() => { // 이전 달 클릭
        const prevMonth = dayjs(date).subtract(1, 'month');
        setDate(new Date(prevMonth.format('YYYY-MM-DD')));
        setSearchTerm('');
    }, [date]);

    const handleNextMonth = useCallback(() => { // 다음 달 클릭
        const nextMonth = dayjs(date).add(1, 'month');
        setDate(new Date(nextMonth.format('YYYY-MM-DD')));
        setSearchTerm('');
    }, [date]);

    return (
        <DayOffContext.Provider value={{
            selectedData,
            setSelectedData,
            setDayOffModal,
            refetch,
            monthRefetch
        }}>
            <Wrapper>
                <PageHeader
                    GO_BACK
                    DAY_OFF
                    title='병원 전체 쉬는날 설정'
                    guideLine='설정 > 병원 휴무 설정'
                    onClick={() => setDayOffModal(true)}>
                    <SortingBox>
                        <FilterBox>
                            <SortBox>
                                <StyledCalendar
                                    title={viewType !== 'calendarWeek' ? '기간 : ' : '시작일 : '}
                                    inputWidth={viewType !== 'calendarWeek' ? 66 : 88}
                                    value={date}
                                    dateFormat={viewType !== 'calendarWeek' ? 'yyyy-MM' : 'yyyy-MM-dd'}
                                    onChange={date => setDate(date)}
                                    handlePrevMonth={handlePrevMonth}
                                    handleNextMonth={handleNextMonth}
                                />
                                <StyledSelect
                                    NO_BORDER
                                    width={160}
                                    margin='0 20px'
                                    title='구분 : '
                                    value={filter}
                                    onChange={e => setFilter(e.target.value)}
                                    options={['전체', '임시휴무', '고정휴무']}
                                />
                            </SortBox>
                            <SortBox>
                                <StyledIconButton // 검색
                                    SEARCH
                                    margin='0 12px'
                                    onClick={() => setSearchModal(true)}
                                />
                                <StyledIconButton // 리스트 보기
                                    LIST
                                    topRightRadius={0}
                                    bottomRightRadius={0}
                                    margin='0 0 0 -1px'
                                    bgColor={viewType === 'list' ? theme.colors.ultraLightGrayBorder : theme.colors.whiteColor}
                                    onClick={() => setViewType('list')}
                                />
                                <StyledIconButton // 달력 월간 보기
                                    CALENDAR_MONTH
                                    topLeftRadius={0}
                                    bottomLeftRadius={0}
                                    margin='0 0 0 -1px'
                                    bgColor={viewType === 'calendar' ? theme.colors.ultraLightGrayBorder : theme.colors.whiteColor}
                                    onClick={() => setViewType('calendar')}
                                />
                            </SortBox>
                        </FilterBox>
                    </SortingBox>
                </PageHeader>

                {viewType === 'list' && (
                    <DayOffList
                        loading={loading}
                        dayOffList={data?.seeHospitalOffday?.offdayList}
                    />
                )}

                {viewType === 'calendar' && (
                    <DayOffCalendarMonth
                        date={date}
                        loading={loading}
                        dayOffList={monthData?.seeByMonthHospitalOffday}
                    />
                )}

                <DayOffModal
                    dayOffModal={dayOffModal}
                    setDayOffModal={setDayOffModal}
                    refetch={refetch}
                    monthRefetch={monthRefetch}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                />

                <SearchModal // 검색하기 모달
                    searchModal={searchModal}
                    setSearchModal={setSearchModal}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Wrapper>
        </DayOffContext.Provider>
    );
}

export default DayOff;
