import React, {useCallback, useEffect, useState} from "react";
import ModalBackground from "../../layout/ModalBackground";
import styled from "styled-components";
import StyledInput from "../../styled/StyledInput";
import StyledTextarea from "../../styled/StyledTextarea";
import StyledFileUpload from "../../styled/StyledFileUpload";
import StyledButton from "../../styled/StyledButton";
import {toast} from "react-toastify";
import {errorMessage} from "../../../utils/commons";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {CREATE_HOSPITAL_NOTICE, UPDATE_HOSPITAL_NOTICE} from "../../../graphql/Home/mutation";
import PreviewFile from "../../share/PreviewFile";
import {useFileUpload} from "../../../hooks/useFileUpload";
import {SEE_HOSPITAL_NOTICE_DETAIL} from "../../../graphql/Home/query";
import theme from "../../../styles/theme";

const Contents = styled.div``;
const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 20px;
`;

const NoticeModal = ({selectedId, setSelectedId = () => null, noticeModal, setNoticeModal, noticeListRefetch}) => {
    const [inputs, setInputs] = useState({
        title: '',
        content: ''
    });
    const [deletedIds, setDeletedIds] = useState([]);
    const {fileList, setFileList, previewList, setPreviewList, onChangeFile} = useFileUpload();

    const {data, loading} = useQuery(SEE_HOSPITAL_NOTICE_DETAIL, {
        variables: {
            hnId: selectedId
        }
    });
    const [createHospitalNotice] = useMutation(CREATE_HOSPITAL_NOTICE);
    const [updateHospitalNotice] = useMutation(UPDATE_HOSPITAL_NOTICE);

    const onChange = useCallback(e => {
        const {name, value} = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const handleDeleteFile = useCallback(fileName => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        let tmpDeletedIds = [...deletedIds];

        if (previewList.find(file => file.hna_id)) {
            tmpDeletedIds.push(previewList.find(file => file.hna_id).hna_id);
            setDeletedIds(tmpDeletedIds);
        }

        setPreviewList(previewList.filter(file => file.name !== fileName));
        setFileList(fileList.filter(file => file.name !== fileName));
    }, [previewList, fileList]);

    const handleClose = useCallback(() => {
        setNoticeModal(false);
        setInputs({
            title: '',
            content: ''
        });
        setFileList([]);
        setPreviewList([]);
        setTimeout(() => setSelectedId(null), 100);
    }, [setSelectedId]);

    const handleUpdate = useCallback(async () => { // 공지사항 수정하기
        const {title, content} = inputs;
        if (title === '') {
            toast.error('제목을 입력해주세요.');
            return;
        }

        try {
            const {data} = await updateHospitalNotice({
                variables: {
                    hnId: selectedId,
                    title: title,
                    text: content,
                    updateAttached: fileList,
                    deleteAttached: deletedIds,
                }
            });

            if (data.updateHospitalNotice) {
                await noticeListRefetch();
                toast.info('공지사항을 수정했습니다.');
                handleClose();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [inputs, inputs, fileList, deletedIds]);

    const handleSave = useCallback(async () => { // 공지사항 작성하기
        const {title, content} = inputs;
        if (title === '') {
            toast.error('제목을 입력해주세요.');
            return;
        }

        try {
            const {data} = await createHospitalNotice({
                variables: {
                    title: title,
                    text: content,
                    noticeAttached: fileList
                }
            });

            if (data.createHospitalNotice) {
                await noticeListRefetch();
                toast.info('공지사항을 작성했습니다.');
                handleClose();
            }
        } catch (e) {
            errorMessage(e.message);
        }
    }, [inputs, inputs, fileList]);

    useEffect(() => {
        let tmpPreviewList = [];
        let tmpFileList = [];

        if (noticeModal && selectedId && !loading && data) {
            data?.seeHospitalNoticeDetail?.hnAttached?.forEach(file => {
                if (file) {
                    const fullName = file.hna_url.split('/')[3] || '';
                    const fileName = fullName.substr(14, fullName.length);
                    // const fileObj = new File([file.hna_url], fileName, {type: file?.han_fileType});

                    tmpPreviewList.push({
                        hna_id: file.hna_id,
                        name: fileName,
                        url: file.hna_url,
                        size: file.han_fileSize
                    });
                }
            });

            setInputs({
                title: data?.seeHospitalNoticeDetail?.hn_title,
                content: data?.seeHospitalNoticeDetail?.hn_text
            });

            setPreviewList(tmpPreviewList);
            setFileList(tmpFileList);
        }
    }, [noticeModal, selectedId, loading, data]);

    return (
        <ModalBackground
            CONFIRM
            title='공지사항 올리기'
            width={520}
            height={720}
            visible={noticeModal}
            onClose={handleClose}>
            <Contents>
                <StyledInput
                    ASTERISK
                    title='제목'
                    name='title'
                    value={inputs.title}
                    onChange={onChange}
                    placeholder='공지사항 제목을 입력해주세요.'
                />
                <StyledTextarea
                    title='내용'
                    margin='20px 0'
                    name='content'
                    value={inputs.content}
                    onChange={onChange}
                    placeholder='내용을 입력해주세요.'
                    maxLength={1000}
                />
                <StyledFileUpload
                    onChange={onChangeFile}
                    placeholder='이미지, PDF 파일 등을 첨부할 수 있어요.'
                />
                {previewList.length > 0 && (
                    <FileList>
                        {previewList.map((file, index) => (
                            <PreviewFile
                                DELETE
                                key={`${index}-file`}
                                file={file}
                                onDelete={handleDeleteFile}
                            />
                        ))}
                    </FileList>
                )}
                <StyledButton
                    width={440}
                    title={selectedId ? '수정하기' : '작성하기'}
                    bgColor={selectedId ? theme.colors.activeOrange : theme.colors.activeBlue}
                    margin='30px 0 0'
                    onClick={() => selectedId ? handleUpdate() :  handleSave()}
                />
            </Contents>
        </ModalBackground>
    )
}

export default NoticeModal;
