import React, {useCallback, useContext, useEffect, useState} from 'react';
import FullPageModalBackground from "../../../layout/FullPageModalBackground";
import theme from "../../../../styles/theme";
import {useMutation, useQuery} from "@apollo/client";
import {SEE_DEFAULT_SCHEDULE} from "../../../../graphql/Common/query";
import {EmphasisText, Hyphen, ScheduleList} from "./setting.modal.style";
import Loader from "../../../share/Loader";
import DayScheduleList from "./DayScheduleList";
import {RegularText} from "../../../styled/StyledText";
import StyledSelect from "../../../styled/StyledSelect";
import StyledFlexBox from "../../../styled/StyledFlexBox";
import {
    dayKorChange,
    hourOption,
    hourToInteger,
    minuteOption,
    minuteToInteger
} from "../../../../pages/Setting/setting.lib";
import StyledRadio from "../../../styled/StyledRadio";
import StyledButton from "../../../styled/StyledButton";
import {
    CREATE_DEFAULT_SCHEDULE,
    DELETE_DEFAULT_SCHEDULE,
    UPDATE_DEFAULT_SCHEDULE
} from "../../../../graphql/Setting/mutation";
import {toast} from "react-toastify";
import {SettingContext} from "../../../../pages/Setting/setting";
import {handleBodyScroll} from "../../../../utils/commons";
import {operationTimeValidator} from "../../../../lib/validator";

const HospitalOperationScheduleModal = () => {
    const { siteMap, handleCloseSiteMap } = useContext(SettingContext);
    const [inputs, setInputs] = useState({
        day: '월요일',
        startHour: '00시',
        startMin: '00분',
        endHour: '00시',
        endMin: '00분',
        isLunchTime: 'true',
        lunchStartHour: '00시',
        lunchStartMin: '00분',
        lunchEndHour: '00시',
        lunchEndMin: '00분',
    });
    const [selectedId, setSelectedId] = useState(null);

    const {data, loading, refetch} = useQuery(SEE_DEFAULT_SCHEDULE);
    const [createDefaultSchedule] = useMutation(CREATE_DEFAULT_SCHEDULE);
    const [updateDefaultSchedule] = useMutation(UPDATE_DEFAULT_SCHEDULE);
    const [deleteDefaultSchedule] = useMutation(DELETE_DEFAULT_SCHEDULE);

    const handleReset = useCallback(() => {
        setInputs({
            day: '월요일',
            startHour: '00시',
            startMin: '00분',
            endHour: '00시',
            endMin: '00분',
            isLunchTime: 'true',
            lunchStartHour: '00시',
            lunchStartMin: '00분',
            lunchEndHour: '00시',
            lunchEndMin: '00분',
        });
    }, []);

    const handleClose = useCallback(() => {
        handleCloseSiteMap();
        handleReset();
        setSelectedId(null);
    }, []);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const onChangeRadio = useCallback((e) => {
        const { value } = e.target;

        if (selectedId === parseInt(value, 10)) {
            setSelectedId(null);
            e.target.checked = false;
            return;
        }
        setSelectedId(parseInt(value, 10));
        e.target.checked = false;
    }, [selectedId]);

    const handleDeleteDefaultSchedule = useCallback(async id => {
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        try {
            const {data} = await deleteDefaultSchedule({
                variables: {
                    dsId: id
                }
            });

            if (data.deleteDefaultSchedule) {
                toast.info('일정을 삭제했습니다.');
                await refetch();
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    const handleCreateDefaultSchedule = useCallback(async () => {
        if (operationTimeValidator(
            inputs.startHour,
            inputs.startMin,
            inputs.endHour,
            inputs.endMin,
            inputs.lunchStartHour,
            inputs.lunchStartMin,
            inputs.lunchEndHour,
            inputs.lunchEndMin,
            inputs.isLunchTime
        )) return;

        try {
            const {data} = await createDefaultSchedule({
                variables: {
                    dsDay: dayKorChange(inputs.day),
                    dsStartHour: hourToInteger(inputs.startHour),
                    dsStartMin: minuteToInteger(inputs.startMin),
                    dsEndHour: hourToInteger(inputs.endHour),
                    dsEndMin: minuteToInteger(inputs.endMin),
                    dsLunchBreak: inputs.isLunchTime === 'true',
                    dsLbStartHour: hourToInteger(inputs.lunchStartHour),
                    dsLbStartMin: minuteToInteger(inputs.lunchStartMin),
                    dsLbEndHour: hourToInteger(inputs.lunchEndHour),
                    dsLbEndMin: minuteToInteger(inputs.lunchEndMin),
                }
            });

            if (data.createDefaultSchedule) {
                toast.info('스케줄을 추가했습니다.');
                await refetch();
                handleReset();
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('데이터 처리에 실패했습니다.\n잠시 후 다시 시도해주세요.');
                    break;
                case 'err_01':
                    toast.error('해당 요일에 등록된 일정이 있습니다.');
                    break;
            }
        }
    }, [inputs]);

    const handleUpdateDefaultSchedule = useCallback(async () => {
        if (operationTimeValidator(
            inputs.startHour,
            inputs.startMin,
            inputs.endHour,
            inputs.endMin,
            inputs.lunchStartHour,
            inputs.lunchStartMin,
            inputs.lunchEndHour,
            inputs.lunchEndMin,
            inputs.isLunchTime
        )) return;

        try {
            const { data } = await updateDefaultSchedule({
               variables: {
                   dsId: selectedId,
                   dsDay: dayKorChange(inputs.day),
                   dsStartHour: hourToInteger(inputs.startHour),
                   dsStartMin: minuteToInteger(inputs.startMin),
                   dsEndHour: hourToInteger(inputs.endHour),
                   dsEndMin: minuteToInteger(inputs.endMin),
                   dsLunchBreak: inputs.isLunchTime === 'true',
                   dsLbStartHour: hourToInteger(inputs.lunchStartHour),
                   dsLbStartMin: minuteToInteger(inputs.lunchEndMin),
                   dsLbEndHour: hourToInteger(inputs.lunchEndHour),
                   dsLbEndMin: minuteToInteger(inputs.lunchEndMin),
               }
            });

            if (data?.updateDefaultSchedule) {
                toast.info('스케줄을 수정했습니다.');
                await refetch();
                handleReset();
                setSelectedId(null);
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('데이터 처리에 실패했습니다.\n잠시 후 다시 시도해주세요.');
                    break;
                case 'err_01':
                    toast.error('해당 요일에 등록된 일정이 있습니다.');
                    break;
            }
        }
    }, [selectedId, inputs]);

    useEffect(() => {
        if (selectedId) {
            const findData = data?.seeDefaultSchedule?.defaultScheduleList?.find(schedule => schedule.ds_id === selectedId);
            if (!findData) return;

            setInputs({
                day: `${dayKorChange(findData?.ds_day)}요일`,
                startHour: `${String(findData?.ds_startHour).padStart(2, '0')}시`,
                startMin: `${String(findData?.ds_startMin).padStart(2, '0')}분`,
                endHour: `${String(findData?.ds_endHour).padStart(2, '0')}시`,
                endMin: `${String(findData?.ds_endMin).padStart(2, '0')}분`,
                isLunchTime: findData?.ds_lunchBreak ? 'true' : 'false',
                lunchStartHour: `${String(findData?.ds_lbStartHour).padStart(2, '0')}시`,
                lunchStartMin: `${String(findData?.ds_lbStartMin).padStart(2, '0')}분`,
                lunchEndHour: `${String(findData?.ds_lbEndHour).padStart(2, '0')}시`,
                lunchEndMin: `${String(findData?.ds_lbEndMin).padStart(2, '0')}분`,
            });
        } else {
            handleReset();
        }
    }, [data, selectedId]);

    return (
        <FullPageModalBackground
            // buttonTitle='병원 운영 스케줄 등록'
            guideLine='설정 > 병원 운영 스케줄 등록'
            modalTitle='기본 영업 시간 설정'
            visible={siteMap === '운영일 설정'}
            onClose={handleClose}
            // onClickButton={handleSave}
            buttonColor={theme.colors.activeBlue}>

            <EmphasisText $margin='30px 0 10px'>설정 스케줄</EmphasisText>
            <ScheduleList>
                {loading
                    ? <Loader/>
                    : data?.seeDefaultSchedule?.defaultScheduleList?.map((list, index) => (
                        <DayScheduleList
                            key={`${list?.ds_id}-${index}-day-list`}
                            id={list?.ds_id}
                            day={list?.ds_day}
                            startHour={list?.ds_startHour}
                            startMin={list?.ds_startMin}
                            endHour={list?.ds_endHour}
                            endMin={list?.ds_endMin}
                            lunchBreak={list?.ds_lunchBreak}
                            lunchStartHour={list?.ds_lbStartHour}
                            lunchStartMin={list?.ds_lbStartMin}
                            lunchEndHour={list?.ds_lbEndHour}
                            lunchEndMin={list?.ds_lbEndMin}
                            handleDeleteSchedule={handleDeleteDefaultSchedule}
                            selectedId={selectedId}
                            onChangeRadio={onChangeRadio}
                        />
                    ))}
            </ScheduleList>
            <RegularText $fontColor={theme.colors.activeBlue} $margin='30px 0 20px'>요일별 운영시간</RegularText>
            <StyledSelect
                title='요일'
                width={440}
                borderRadius={4}
                name='day'
                value={inputs.day}
                onChange={onChange}
                options={['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일']}
            />

            <StyledFlexBox margin='20px 0'>
                <StyledSelect
                    title='시작시간'
                    width={205}
                    borderRadius={4}
                    name='startHour'
                    value={inputs.startHour}
                    onChange={onChange}
                    options={hourOption}
                />
                <Hyphen>-</Hyphen>
                <StyledSelect
                    width={205}
                    borderRadius={4}
                    margin='0 30px 0 0'
                    name='startMin'
                    value={inputs.startMin}
                    onChange={onChange}
                    options={minuteOption}
                />

                <StyledSelect
                    title='종료시간'
                    width={205}
                    borderRadius={4}
                    name='endHour'
                    value={inputs.endHour}
                    onChange={onChange}
                    options={hourOption}
                />
                <Hyphen>-</Hyphen>
                <StyledSelect
                    width={205}
                    borderRadius={4}
                    name='endMin'
                    value={inputs.endMin}
                    onChange={onChange}
                    options={minuteOption}
                />
            </StyledFlexBox>
            <RegularText $margin='20px 0'>점심시간 휴진 사용</RegularText>
            <StyledFlexBox margin='0 0 20px'>
                <StyledRadio
                    label='사용'
                    name='isLunchTime'
                    value='true'
                    checked={inputs.isLunchTime}
                    onChange={onChange}
                />
                <StyledRadio
                    margin='0 0 0 20px'
                    label='미사용'
                    name='isLunchTime'
                    value='false'
                    checked={inputs.isLunchTime}
                    onChange={onChange}
                />
            </StyledFlexBox>
            <StyledFlexBox margin='20px 0'>
                <StyledSelect
                    title='시작시간'
                    width={205}
                    borderRadius={4}
                    name='lunchStartHour'
                    value={inputs.lunchStartHour}
                    onChange={onChange}
                    // options={hourOption}
                    options={['10시', '11시', '12시', '13시', '14시', '15시']}
                    disabled={inputs.isLunchTime === 'false'}
                />
                <Hyphen>-</Hyphen>
                <StyledSelect
                    width={205}
                    borderRadius={4}
                    margin='0 30px 0 0'
                    name='lunchStartMin'
                    value={inputs.lunchStartMin}
                    onChange={onChange}
                    options={minuteOption}
                    disabled={inputs.isLunchTime === 'false'}
                />

                <StyledSelect
                    title='종료시간'
                    width={205}
                    borderRadius={4}
                    name='lunchEndHour'
                    value={inputs.lunchEndHour}
                    onChange={onChange}
                    options={['10시', '11시', '12시', '13시', '14시', '15시']}
                    // options={hourOption}
                    disabled={inputs.isLunchTime === 'false'}
                />
                <Hyphen>-</Hyphen>
                <StyledSelect
                    width={205}
                    borderRadius={4}
                    name='lunchEndMin'
                    value={inputs.lunchEndMin}
                    onChange={onChange}
                    options={minuteOption}
                    disabled={inputs.isLunchTime === 'false'}
                />
            </StyledFlexBox>
            <StyledButton
                title={`${selectedId ? '수정' : '추가'}하기`}
                width={214}
                margin='30px 0 200px'
                bgColor={selectedId ? theme.colors.activeOrange : theme.colors.activeBlue}
                onClick={selectedId ? handleUpdateDefaultSchedule : handleCreateDefaultSchedule}
            />
        </FullPageModalBackground>
    );
}

export default HospitalOperationScheduleModal;
