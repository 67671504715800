import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {useLazyQuery, useMutation, useReactiveVar} from "@apollo/client";
import {IoInformationCircleOutline} from 'react-icons/io5';
import {toast} from "react-toastify";
import RightSlideModal from "../../layout/RightSlideModal";
import StyledSelect from "../../styled/StyledSelect";
import {doctorsVar} from "../../../store";
import StyledRangeCalendar from "../../styled/StyledRangeCalendar";
import StyledTextarea from "../../styled/StyledTextarea";
import StyledFileUpload from "../../styled/StyledFileUpload";
import PreviewFile from "../../share/PreviewFile";
import {useFileUpload} from "../../../hooks/useFileUpload";
import {
    Contents,
    DoubleInputBox,
    DoubleInputTitle,
    SubTitle,
    InputBox,
    Input,
    OrangeText,
    RowFlexBox, EmptyTitle, FileList, ButtonBox, InputForm
} from "./schedule.style";
import StyledButton from "../../styled/StyledButton";
import theme from "../../../styles/theme";
import {CREATE_SPECIAL_SCHEDULE, UPDATE_SPECIAL_SCHEDULE} from "../../../graphql/Schedule/mutation";
import {errorMessage} from "../../../utils/commons";
import StyledCalendar from "../../styled/StyledCalendar";
import {specialScheduleValidate} from "../../../pages/Schedule/schedule.lib";
import {SEE_SPECIAL_SCHEDULE_DETAIL} from "../../../graphql/Schedule/query";
import LoaderBackground from "../../share/LoaderBackground";
import Loader from "../../share/Loader";
import dayjs from "dayjs";

const ScheduleModal = ({title, scheduleModal, setScheduleModal, selectedId, setSelectedId = () => null, refetch, dayRefetch, offDateList}) => {
    const doctorsOption = useReactiveVar(doctorsVar);
    const {fileList, setFileList, previewList, setPreviewList, onChangeFile} = useFileUpload();
    const [inputs, setInputs] = useState({
        doctors: doctorsOption[0]?.dr_roomName,
        type: '휴무 시간 추가',
        adjustmentType: '휴무 진행',
        adjustmentStartTime: '',
        adjustmentEndTime: '',
        memo: '',
    });
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [detailData, setDetailData] = useState(null);
    const [deletedFileId, setDeletedFileId] = useState([]);
    const [processLoading, setProcessLoading] = useState(false);

    const [seeSpecialScheduleDetail, { data, loading }] = useLazyQuery(SEE_SPECIAL_SCHEDULE_DETAIL, {
        variables: {
            ssId: selectedId,
        },
        fetchPolicy: 'network-only'
    });

    const [createSpecialSchedule] = useMutation(CREATE_SPECIAL_SCHEDULE);
    const [updateSpecialSchedule] = useMutation(UPDATE_SPECIAL_SCHEDULE);

    const onChange = useCallback(e => { // input onChange
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const onChangeDate = useCallback(dates => { // 달력 onChange
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
    }, []);

    const onKeyUpTimeInput = useCallback(e => { // 조정 시간 콜론 삽입 및 제거
        const { key, target: { name, value } } = e;

        if (key !== 'Backspace' && value.length > 2 && value.length <= 5 && !value.includes(':')) {
            setInputs({
                ...inputs,
                [name]: value.replace(/(.{2})(.{2})/, '$1:$2')
            });
        }

        if (key === 'Backspace' && value.length === 3) {
            setInputs({
                ...inputs,
                [name]: value.slice(0, 2)
            });
        }
    }, [inputs]);

    const handleDeleteFile = useCallback(fileName => { // 파일 삭제
        if (!window.confirm('정말 삭제하시겠습니까?')) return;

        const deletedId = previewList.find(file => file.name === fileName).fileId;
        let tmpDeletedId = [...deletedFileId];
        tmpDeletedId.push(deletedId);

        setPreviewList(previewList.filter(file => file.name !== fileName));
        setFileList(fileList.filter(file => file.name !== fileName));
        setDeletedFileId(tmpDeletedId);
    }, [previewList, fileList, deletedFileId]);

    const handleClose = useCallback(() => { // 모달 닫기
        setScheduleModal(false);
        setInputs({
            doctors: doctorsOption[0]?.dr_roomName,
            type: '휴무 시간 추가',
            adjustmentType: '시간 단위 조정',
            adjustmentStartTime: '',
            adjustmentEndTime: '',
            memo: '',
        });
        setStartDate(null);
        setEndDate(null);
        setFileList([]);
        setPreviewList([]);
        setSelectedId(null);
        setDetailData(null);
        setDeletedFileId([]);
    }, [doctorsOption]);

    const handleSave = useCallback(async () => {
        if (specialScheduleValidate((startDate || selectedDate), endDate, inputs)) {
            return;
        }

        const drId = doctorsOption.find(data => data?.dr_roomName === inputs.doctors)?.dr_id || 0;

        setProcessLoading(true);
        try {
            const { data } = await createSpecialSchedule({
                variables: {
                    drId: drId,
                    type: inputs.type === '휴무 시간 추가' ? 'offDay' : 'schedule',
                    startDate: inputs.type === '휴무 시간 추가' ? selectedDate : startDate,
                    endDate: inputs.type === '휴무 시간 추가' ? '' : endDate,
                    subDoctorUsed: inputs.adjustmentType === '대체 의사 진행',
                    startTime: inputs.adjustmentStartTime,
                    endTime: inputs.adjustmentEndTime,
                    memo: inputs.memo,
                    attached: fileList
                }
            });

            if (data.createSpecialSchedule) {
                handleClose();
                setProcessLoading(false);
                toast.info('진료실 일정을 추가했습니다.');
                await refetch();
                await dayRefetch();
            }
        } catch (e) {
            switch (e.message) {
                case 'err_00':
                    toast.error('특별일정 추가에 실패했습니다.');
                    break;
            }
        } finally {
            setProcessLoading(false);
        }
    }, [doctorsOption, startDate, selectedDate, endDate, inputs, fileList, refetch, dayRefetch]);

    const handleUpdate = useCallback(async () => {
        if (specialScheduleValidate((startDate || selectedDate), endDate, inputs)) {
            return;
        }

        const drId = doctorsOption.find(data => data?.dr_roomName === inputs.doctors)?.dr_id || 0;

        setProcessLoading(true);
        try {
            const { data } = await updateSpecialSchedule({
                variables: {
                    ssId: detailData?.ss_id,
                    drId: drId,
                    type: inputs.type === '휴무 시간 추가' ? 'offDay' : 'schedule',
                    startDate: inputs.type === '휴무 시간 추가' ? selectedDate : startDate,
                    endDate: inputs.type === '휴무 시간 추가' ? '' : endDate,
                    startTime: inputs.adjustmentStartTime,
                    endTime: inputs.adjustmentEndTime,
                    subDoctorUsed: inputs.adjustmentType === '대체 의사 진행',
                    memo: inputs.memo,
                    attached: fileList,
                    deleteAttachedIds: deletedFileId,
                }
            });

            if (data.updateSpecialSchedule) {
                handleClose();
                setProcessLoading(false);
                toast.info('특별 일정을 수정했습니다.');
                await refetch();
                await dayRefetch();
            }
        } catch (e) {
            errorMessage(e.message);
        } finally {
            setProcessLoading(false);
        }
    }, [detailData, doctorsOption, startDate, selectedDate, endDate, inputs, fileList, deletedFileId, handleClose]);

    useLayoutEffect(() => {
        if (!loading && data) {
            const detailData = data?.seeSpecialScheduleDetail;
            const tmpPreviewList = detailData?.specialScheduleAttactheds?.map(file => {
                if (!file) return [];

                const splitUrl = file.sa_url.split('/');
                let fileName = splitUrl[splitUrl.length - 1];
                fileName = fileName.slice(14, fileName.length);

                return {
                    name: fileName || '',
                    url: file.sa_url,
                    size: file.sa_fileSize,
                    fileId: file.sa_id
                }
            });

            setDetailData(detailData);
            setInputs({
                doctors: detailData?.ss_doctorRoomName,
                type: detailData?.ss_type === 'offDay' ? '휴무 시간 추가' : '일정 추가',
                adjustmentType: detailData?.ss_subDoctorUsed ? '대체 의사 진행' : '휴무 진행',
                adjustmentStartTime: detailData?.ss_startTime,
                adjustmentEndTime: detailData?.ss_endTime,
                memo: detailData?.ss_memo,
            });
            setPreviewList(tmpPreviewList);

            if (detailData?.ss_type === 'offDay') {
                setSelectedDate(new Date(detailData?.ss_startDate));
            } else {
                setStartDate(new Date(detailData?.ss_startDate));
                setEndDate(new Date(detailData?.ss_endDate));
            }
        }
    }, [loading, data]);

    useEffect(() => {
        (async () => {
            if (scheduleModal && selectedId) {
                await seeSpecialScheduleDetail();
            }
        })();
    }, [selectedId, scheduleModal]);

    useEffect(() => {
        if (doctorsOption.length > 0) {
            setInputs({
                ...inputs,
                doctors: doctorsOption[0]?.dr_roomName
            });
        }
    }, [doctorsOption]);

    return (
        <RightSlideModal
            title={title}
            visible={scheduleModal}
            onClose={handleClose}>
            <Contents>
                {processLoading && (
                    <LoaderBackground>
                        <Loader />
                    </LoaderBackground>
                )}
                <InputForm>
                    <StyledSelect
                        width={440}
                        borderRadius={8}
                        name='doctors'
                        value={inputs.doctors}
                        onChange={onChange}
                        options={doctorsOption.map(room => room.dr_roomName)}
                    />
                    <SubTitle>조정일정</SubTitle>
                    <StyledSelect
                        ROW
                        title='구분'
                        width={440}
                        height={38}
                        borderRadius={8}
                        name='type'
                        value={inputs.type}
                        onChange={onChange}
                        options={['휴무 시간 추가', '일정 추가']}
                    />
                    {inputs.type === '휴무 시간 추가'
                        ? <StyledCalendar
                            BORDER
                            inputWidth={200}
                            fontColor={theme.colors.blackColor}
                            margin='12px 0'
                            title='조정일'
                            value={selectedDate}
                            onChange={value => setSelectedDate(value)}
                            excludeDates={offDateList}
                        />
                        : <StyledRangeCalendar
                            margin='12px 0'
                            id='date-range-picker-wrapper'
                            inputId='date-range-picker'
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onChangeDate}
                            excludeDates={offDateList}
                        />
                    }
                    <StyledSelect
                        ROW
                        title='조정 구분'
                        width={440}
                        height={38}
                        borderRadius={8}
                        name='adjustmentType'
                        value={inputs.adjustmentType}
                        onChange={onChange}
                        options={['휴무 진행', '대체 의사 진행']}
                    />
                    <DoubleInputBox>
                        <DoubleInputTitle>조정시간</DoubleInputTitle>
                        <InputBox>
                            <Input
                                name='adjustmentStartTime'
                                maxLength={5}
                                value={inputs.adjustmentStartTime}
                                onChange={onChange}
                                onKeyUp={onKeyUpTimeInput}
                                placeholder='00:00'
                            />
                            -
                            <Input
                                name='adjustmentEndTime'
                                maxLength={5}
                                value={inputs.adjustmentEndTime}
                                onChange={onChange}
                                onKeyUp={onKeyUpTimeInput}
                                placeholder='00:00'
                            />
                        </InputBox>
                    </DoubleInputBox>
                    <RowFlexBox>
                        <EmptyTitle/>
                        <OrangeText>
                            <IoInformationCircleOutline fontSize={22} style={{marginRight: 8}}/>
                            운영시간에서 조정 휴무 시간이 제외됩니다.
                        </OrangeText>
                    </RowFlexBox>

                    <StyledTextarea
                        ROW
                        height={122}
                        margin='0 0 14px'
                        title='메모'
                        name='memo'
                        value={inputs.memo}
                        onChange={onChange}
                        placeholder='사유를 입력해주세요.'
                    />

                    <RowFlexBox>
                        <EmptyTitle/>
                        <StyledFileUpload
                            title=''
                            onChange={onChangeFile}
                            placeholder='이미지, PDF 등을 첨부할 수 있어요.'
                        />
                    </RowFlexBox>

                    <RowFlexBox>
                        <EmptyTitle/>
                        {previewList.length > 0 && (
                            <FileList>
                                {previewList.map((file, index) => (
                                    <PreviewFile
                                        DELETE
                                        key={`${index}-file`}
                                        file={file}
                                        onDelete={handleDeleteFile}
                                    />
                                ))}
                            </FileList>
                        )}
                    </RowFlexBox>
                </InputForm>
                <ButtonBox>
                    <StyledButton
                        PENCIL
                        title='진료실 일정 변경'
                        width={214}
                        onClick={selectedId ? handleUpdate : handleSave}
                    />
                    <StyledButton
                        CLOSE
                        title='닫기'
                        width={214}
                        fontColor={theme.colors.blackColor}
                        border={`1px solid ${theme.colors.ultraLightGrayBorder}`}
                        bgColor={theme.colors.whiteColor}
                        onClick={handleClose}
                    />
                </ButtonBox>
            </Contents>
        </RightSlideModal>
    )
}

export default ScheduleModal;
