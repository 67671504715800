import React from 'react';
import styled, {css} from 'styled-components';
import theme from '../../styles/theme';
import {RegularText} from "./StyledText";

const Wrapper = styled.div`
  width: ${({$width}) => $width}px;
  margin: ${({$margin}) => ($margin ? $margin : 0)};

  ${({$ROW}) => $ROW && css`
    display: flex;
    justify-content: space-between;
  `};
`;
const RowTitle = styled(RegularText)`
  width: ${({$titleWidth}) => $titleWidth}px;
  padding-top: 8px;
`;
const ColumnTitle = styled(RegularText)`
  margin-bottom: 8px;
`;
const Textarea = styled.textarea`
  width: 100%;
  height: ${({$height}) => $height}px;
  white-space: pre-wrap;
  word-break: break-all;
  padding: 12px 12px;
  border: ${({$border}) => $border};
  border-radius: 4px;

  &:disabled {
    background-color: ${theme.colors.whiteColor};
  }

  ${({$ROW}) => $ROW && css`
    width: ${({ $titleWidth }) => `calc(100% - ${$titleWidth}px)`};
  `};
`;
const Asterisk = styled.span`
  color: ${theme.colors.activeRed};
`;

const StyledTextarea = ({
                            ROW,
                            ASTERISK,
                            width = 440,
                            height = 280,
                            margin,
                            titleWidth = 136,
                            border = `1px solid ${theme.colors.lightGrayBorder}`,
                            title = '',
                            name,
                            value,
                            type = 'text',
                            onChange,
                            onKeyUp,
                            maxLength = 200,
                            disabled = false,
                            ref,
                            placeholder
                        }) => {
    if (ROW) {
        return (
            <Wrapper $ROW={ROW} $width={width} $margin={margin}>
                {title && (
                    <RowTitle $titleWidth={titleWidth}>
                        {title}
                        {ASTERISK && <Asterisk>*</Asterisk>}
                    </RowTitle>
                )}
                <Textarea
                    $ROW={ROW}
                    $titleWidth={titleWidth}
                    $height={height}
                    $border={border}
                    name={name}
                    value={value}
                    type={type}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    maxLength={maxLength}
                    ref={ref}
                    disabled={disabled}
                    placeholder={placeholder}
                />
            </Wrapper>
        );
    }


    return (
        <Wrapper $width={width} $margin={margin}>
            {title && (
                <ColumnTitle>
                    {title}
                    {ASTERISK && <Asterisk>*</Asterisk>}
                </ColumnTitle>
            )}
            <Textarea
                $height={height}
                $border={border}
                name={name}
                value={value}
                type={type}
                onChange={onChange}
                onKeyUp={onKeyUp}
                maxLength={maxLength}
                ref={ref}
                disabled={disabled}
                placeholder={placeholder}
            />
        </Wrapper>
    );
};

export default React.memo(StyledTextarea);
