import React, {useCallback, useRef} from "react";
import {DefaultText, EmphasisText, InlineEmphasisText, TableCell, TableRow} from "./patient.style";
import {autoHyphen, handleBodyScroll} from "../../../utils/commons";
import dayjs from "dayjs";
import StyledIconButton from "../../styled/StyledIconButton";

const PatientTableRow = ({ list, setSelectedId, setPatientModal, setPatientAppointmentModal }) => {
    const buttonRef = useRef(null);

    const handleOpenDetail = useCallback(e => { // 예약 상세보기
        if (!buttonRef?.current?.contains(e.target)) {
            setPatientModal(true);
            setSelectedId(list?.pati_id);
            handleBodyScroll('hidden');
        }
    }, [list, buttonRef]);

    const handlePatientAppointment = useCallback(() => {
        setSelectedId(list?.pati_id);
        handleBodyScroll('hidden');
        setPatientAppointmentModal(true);
    }, [list]);

    return (
        <TableRow onClick={handleOpenDetail}>
            <TableCell
                $minWidth={76}
                $width={8}>
                총&nbsp;
                <InlineEmphasisText>{list?.visitCount || 0}회&nbsp;</InlineEmphasisText>
                방문
            </TableCell>
            <TableCell
                $column
                $minWidth={86}
                $width={10}>
                <EmphasisText>{list?.pati_name || ''}</EmphasisText>
                <DefaultText>{list?.pati_rrn || ''}</DefaultText>
            </TableCell>
            <TableCell
                $minWidth={86}
                $width={10}>
                {autoHyphen(list?.pati_cellphone) || ''}
            </TableCell>
            <TableCell
                $column
                $minWidth={100}
                $width={12}>
                <DefaultText>최근 내원일</DefaultText>
                <DefaultText>{dayjs(list?.recentlyVisitDate).format('YYYY-MM-DD')}</DefaultText>
            </TableCell>
            <TableCell
                $column
                $minWidth={140}
                $width={16}
                $marginRight={100}>
                <DefaultText>차트번호</DefaultText>
                <DefaultText>{list?.pati_chartNumber || ''}</DefaultText>
            </TableCell>
            <TableCell
                $minWidth={180}
                $width={42}
                $marginRight={200}>
                {list?.recentlyVisitMemo || ''}
            </TableCell>
            <TableCell
                ref={buttonRef}
                $minWidth={34}
                $width={2}
                $marginRight='0'>
                <StyledIconButton
                    CALENDAR
                    margin='0 0 0 -1px'
                    onClick={handlePatientAppointment}
                />
            </TableCell>
        </TableRow>
    );
}

export default PatientTableRow;
