import React, {useCallback, useState} from "react";
import ModalBackground from "../../../layout/ModalBackground";
import {errorMessage, handleBodyScroll} from "../../../../utils/commons";
import {RegularText} from "../../../styled/StyledText";
import StyledFlexBox from "../../../styled/StyledFlexBox";
import StyledRadio from "../../../styled/StyledRadio";
import {BlueText, FileList} from "../service.style";
import StyledInput from "../../../styled/StyledInput";
import StyledTextarea from "../../../styled/StyledTextarea";
import StyledFileUpload from "../../../styled/StyledFileUpload";
import {useFileUpload} from "../../../../hooks/useFileUpload";
import PreviewFile from "../../../share/PreviewFile";
import StyledButton from "../../../styled/StyledButton";
import {useMutation} from "@apollo/client";
import {CREATE_ONE_ON_ONE} from "../../../../graphql/Service/mutation";
import {toast} from "react-toastify";

const QnaModal = ({
                      qnaModal,
                      setQnaModal,
                      qnaRefetch = () => null
                  }) => {
    const [inputs, setInputs] = useState({
        isOpen: 'open',
        title: '',
        content: ''
    });
    const {fileList, setFileList, previewList, setPreviewList, onChangeFile} = useFileUpload();

    const [createOneOnOne] = useMutation(CREATE_ONE_ON_ONE);

    const handleClose = useCallback(() => {
        setInputs({
            isOpen: 'open',
            title: '',
            content: ''
        });
        setFileList([]);
        setPreviewList([]);
        setQnaModal(false);
        handleBodyScroll('initial');
    }, []);

    const handleSaveQna = useCallback(async () => {
        try {
            const { data } = await createOneOnOne({
                variables: {
                    oneqTitle: inputs.title,
                    oneqText: inputs.content,
                    oneqPublicPrivate: inputs.isOpen === 'open',
                    attached: fileList,
                }
            });

            if (data.createOneOnOne) {
                await qnaRefetch();
                toast.info('일대일 문의를 등록했습니다.');
                handleClose();
            }
        } catch (e) {
            ;
            errorMessage(e.message);
        }
    }, [inputs, fileList]);

    const handleDeleteFile = useCallback(fileName => {
        // if (!window.confirm('정말 삭제하시겠습니까?')) return;
        setPreviewList(previewList.filter(file => file.name !== fileName));
        setFileList(fileList.filter(file => file.name !== fileName));
    }, [previewList, fileList]);

    const onChange = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });
        if (name === 'isOpen') e.target.checked = false;
    }, [inputs]);

    return (
        <ModalBackground
            title='일대일 문의하기'
            width={520}
            height={892}
            visible={qnaModal}
            onClose={handleClose}>

            <RegularText>공개 여부</RegularText>
            <StyledFlexBox margin='20px 0 0'>
                <StyledRadio
                    label='공개'
                    name='isOpen'
                    checked={inputs.isOpen}
                    value='open'
                    onChange={onChange}
                />
                <StyledRadio
                    label='비공개'
                    margin='0 0 0 20px'
                    name='isOpen'
                    value='private'
                    checked={inputs.isOpen}
                    onChange={onChange}
                />
            </StyledFlexBox>
            <BlueText>댓글을 제외한 내 문의 내역이 플랫폼에 공개됩니다.</BlueText>
            <StyledInput
                ASTERISK
                title='제목'
                name='title'
                value={inputs.title}
                onChange={onChange}
                placeholder='제목을 입력해주세요.'
            />
            <StyledTextarea
                ASTERISK
                margin='20px 0'
                title='내용'
                name='content'
                value={inputs.content}
                onChange={onChange}
                maxLength={1000}
                placeholder='내용을 입력해주세요.'
            />
            <StyledFileUpload
                title=''
                onChange={onChangeFile}
                placeholder='이미지, PDF 파일 등을 첨부할 수 있어요.'
            />
            <FileList>
                {previewList.length > 0 &&
                previewList.map((file, index) => (
                    <PreviewFile
                        DELETE
                        key={`${index}-file`}
                        file={file}
                        onDelete={handleDeleteFile}
                    />))}
            </FileList>

            <StyledButton
                width={440}
                title='작성하기'
                onClick={handleSaveQna}
            />
        </ModalBackground>
    )
}

export default QnaModal;
