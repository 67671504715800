import React, {useCallback} from "react";
import {FaqTableRow as TableRow, FaqTableCell, GrayText, CountBox} from '../service.style';
import {RegularText} from "../../../styled/StyledText";
import dayjs from "dayjs";
import {IoEyeSharp, IoHeartSharp} from 'react-icons/io5';
import {handleBodyScroll} from "../../../../utils/commons";

const FaqTableRow = ({
                         faq,
                         setFaqDetailModal,
                         setSelectedId
                     }) => {
    const handleOpenDetail = useCallback(() => {
        setFaqDetailModal(true);
        setSelectedId(faq.faq_id);
        handleBodyScroll('hidden');
    }, [faq]);

    return (
        <TableRow onClick={handleOpenDetail}>
            <FaqTableCell
                $column
                $minWidth={586}
                $width={90}>
                <RegularText>
                    {faq?.faq_question}
                </RegularText>
                <GrayText>
                    {faq?.faq_createdAt
                        ? dayjs(faq?.faq_createdAt).format('YYYY-MM-DD HH:mm')
                        : '-'
                    }
                </GrayText>
            </FaqTableCell>
            <FaqTableCell
                $minWidth={150}
                $width={10}
                $marginRight='0'>
                <CountBox>
                    <GrayText>
                        {faq?.faq_likeCount.toLocaleString() || 0}
                    </GrayText>
                    <IoHeartSharp style={{marginLeft: 10}}/>
                </CountBox>
                <CountBox>
                    <GrayText>
                        {faq?.faq_viewCount.toLocaleString() || 0}
                    </GrayText>
                    <IoEyeSharp style={{marginLeft: 10}}/>
                </CountBox>
            </FaqTableCell>
        </TableRow>
    )
}

export default FaqTableRow;
