import React from 'react';
import styled from 'styled-components';
import theme from "../../styles/theme";
import { getCalendarDays } from '../../hooks/useCalendar';
import dayjs from "dayjs";
import {useQuery} from "@apollo/client";
import {SEE_BY_MONTH_SCHEDULE} from "../../graphql/Home/query";
import Loader from "./Loader";
import {scheduleStateChange} from "../../pages/Home/Main/main.lib";
import {RegularText} from "../styled/StyledText";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Table = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-top: none;
`;
const TableFixedColumn = styled.div`
  width: 238px;
`;
const FixedRow = styled.div`
  height: ${({ $height }) => $height ? $height : 84}px;
  padding: 20px;
  border-top: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-right: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-bottom: none;
  border-left: none;
  
  &:first-child {
    padding: 12px 20px;
  }
`;
const TableScrollBox = styled.div`
  width: calc(100% - 238px);
  margin-bottom: -10px;
  overflow-x: scroll;
  
  &::-webkit-scrollbar {
    height: 10px;

    display: block;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${theme.colors.activeIndigo}; /* 스크롤바의 색상 */
  }
  -ms-overflow-style: auto; /* 인터넷 익스플로러 */
  scrollbar-width: block; /* 파이어폭스 */
`;
const DayBox = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
`;
const Day = styled.div`
  width: 62px;
  height: 40px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-left: none;
  border-bottom: none;

  &:last-child {
    border-right: none;
  }
`;

const ScheduleList = styled.div`
  width: 100%;
`;
const DaySchedule = styled.div`
  height: 84px;
  display: flex;
`;
const Schedule = styled.div`
  width: 62px;
  height: 100%;
  padding-top: 20px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${theme.colors.ultraLightGrayBorder};
  border-left: none;
  border-bottom: none;
  background-color: ${({ $bgColor }) => $bgColor ? $bgColor : theme.colors.whiteColor};
  
  &:last-child {
    border-right: none;
  }
`;
const Text = styled(RegularText)`
  color: ${({ $fontColor }) => $fontColor ? $fontColor : theme.colors.blackColor};
  margin-bottom: 6px;
`;

const OperationMonthSchedule = ({ date, sortType = 'desc', searchTerm = '' }) => {
    const days = getCalendarDays(date);

    const { data, loading } = useQuery(SEE_BY_MONTH_SCHEDULE, {
       variables: {
           year: dayjs(date).get('year'),
           month: dayjs(date).get('month') + 1,
           searchTerm: searchTerm,
           orderBy: sortType
       }
    });

    if (loading) return <Loader />;

    return (
        <Wrapper>
            <Table>
                <TableFixedColumn>
                    <FixedRow $height={40}>
                        {dayjs(date).format('YYYY-MM-DD')}
                    </FixedRow>
                    {data?.seeByMonthSchedule.map((schedule, index) => {
                        return (
                            <FixedRow key={`${index}-doctors`}>
                                {schedule.title}
                            </FixedRow>
                        )
                    })}
                </TableFixedColumn>

                <TableScrollBox>
                    <DayBox>
                        {days.map(day => (
                            <Day key={`${day}-day`}>
                                {dayjs(day).get('date')}
                            </Day>
                        ))}
                    </DayBox>
                    <ScheduleList>
                        {data?.seeByMonthSchedule.map((schedules, index) => {
                            return (
                                <DaySchedule key={`${index}-schedule`}>
                                    {schedules.byDateInfo.map((day) => {
                                        return (
                                            <Schedule
                                                key={`${day.date}-day`}
                                                $bgColor={day.offInfo === 'off' && theme.colors.ultraLightGrayBorder}
                                            >
                                                <Text>
                                                    {day.offInfo !== 'off' ? '근무' : '휴무'}
                                                </Text>
                                                {day.offInfo === 'subDoctor' && (
                                                    <Text $fontColor={theme.colors.activeOrange}>
                                                        {scheduleStateChange(day.offInfo)}
                                                    </Text>
                                                )}
                                            </Schedule>
                                        )
                                    })}
                                </DaySchedule>
                            )
                        })}
                    </ScheduleList>
                </TableScrollBox>
            </Table>
        </Wrapper>
    )
}

export default OperationMonthSchedule;
