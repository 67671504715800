import styled, { keyframes } from 'styled-components';
import theme from '../../styles/theme';
import { BoldText, MediumText, RegularText } from '../../components/styled/StyledText';

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  min-width: 1320px;
  width: 100vw;
  //max-width: 1920px;
  height: 100vh;
  display: flex;
  position: relative;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
export const WhiteSection = styled.section`
  width: 45%;
  max-width: 830px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const LoginTitle = styled(BoldText)`
  font-size: 32px;
`;
export const LoginSubTitle = styled(MediumText)`
  margin: 34px 0 68px;
`;
export const BlueSection = styled.section`
  min-width: 55%;
  width: calc(100vw - 830px);
  height: 100%;
  padding-top: 120px;
  padding-left: 62px;
  position: relative;
  border-radius: 20px;
  background-color: ${theme.colors.activeBlue};
  overflow: hidden;
`;
export const IntroMainText = styled(BoldText)`
  color: ${theme.colors.whiteColor};
  line-height: 1.6;
  letter-spacing: 0.5px;
`;
export const IntroSubText = styled(RegularText)`
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${theme.colors.whiteColor};
  margin: 38px 0 60px;
`;
export const PlatformImage = styled.div`
  min-width: 500px;
  width: 45vw;
  max-width: 1080px;
  border-radius: 18px;
  overflow: hidden;
  //right: -266px;
`;
export const LoginButton = styled.button`
  width: 340px;
  height: 50px;
  font-family: 'AppleSDGothicNeoBold';
  color: ${theme.colors.whiteColor};
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: ${theme.colors.activeIndigo};
`;
export const LoaderBox = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Loader = styled.div`
  width: 26px;
  height: 26px;
  border: 3px solid ${theme.colors.whiteColor};
  border-top: 3px solid ${theme.colors.activeBlue};
  border-radius: 50%;
  animation: ${Spin} 1.3s linear infinite;
`;
export const GrayText = styled(MediumText)`
  color: ${theme.colors.fontGrayColor};
  cursor: pointer;
  transition: .25s;

  &:hover {
    color: ${theme.colors.blackColor};
  }
`;
export const Line = styled.div`
  width: 1px;
  height: 14px;
  margin: 0 12px;
  background-color: ${theme.colors.fontGrayColor};
`;
