import React from 'react';
import {CountTextBox, DayOffTableBody, InlineEmphasisText, Wrapper} from "./dayOff.style";
import {RegularText} from "../../styled/StyledText";
import Loader from "../../share/Loader";
import DayOffTableRow from "./DayOffTableRow";

const DayOffList = ({ loading, dayOffList = [] }) => {
    return (
        <Wrapper>
            <CountTextBox>
                <RegularText>총 휴무일 :&nbsp;</RegularText>
                <InlineEmphasisText>{dayOffList.length || 0} 일</InlineEmphasisText>
            </CountTextBox>
            <DayOffTableBody>
                {loading
                    ? <Loader />
                    : dayOffList.map((list, index) => (
                        <DayOffTableRow
                            key={`${list?.ho_id}-${index}-day-off-list`}
                            list={list}
                        />
                    ))
                }
            </DayOffTableBody>
        </Wrapper>
    );
}

export default DayOffList;
