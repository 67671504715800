import {toast} from "react-toastify";

export const dayOffTypeChange = type => {
    switch (type) {
        case '전체':
            return 'total';
        case '임시휴무':
            return 'temp';
        case '고정휴무':
            return 'fix';

        case 'temp':
            return '임시휴무';
        case 'fix':
            return '고정휴무';

        case 'none':
            return '반복없음';
        case 'week':
            return '매주반복';
        case 'month':
            return '매월반복';

        case '반복없음':
            return 'none';
        case '매주반복':
            return 'week';
        case '매월반복':
            return 'month';
    }
}

export const createDayOffValidateCheck = (startDate, endDate, inputs) => {
    const { startHour, startMin, endHour, endMin } = inputs;
    let valid = false;

    let startTime = `${startHour.replace('시', '')}:${startMin.replace('분', '')}`;
    let endTime = `${endHour.replace('시', '')}:${endMin.replace('분', '')}`;

    if (!startDate || !endDate) {
        toast.error('조정일을 선택해주세요.');
        valid = true;
    } else if (startTime >= endTime) {
        toast.error('시작시간은 종료시간보다 빠른 시간으로 설정해주세요.');
        valid = true;
    }

    return valid;
}
