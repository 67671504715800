import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {FilterBox, SortBox, PatientList} from "./patient.style";
import PageHeader from "../../components/layout/PageHeader";
import StyledRangeCalendar from "../../components/styled/StyledRangeCalendar";
import StyledIconButton from "../../components/styled/StyledIconButton";
import SearchModal from "../../components/share/SearchModal";
import {useQuery, useReactiveVar} from "@apollo/client";
import {SEE_PATIENTS} from "../../graphql/Patient/query";
import {Loader} from "../Login/login.styles";
import PatientTableRow from "../../components/feature/Patient/PatientTableRow";
import PageNation from "../../components/layout/PageNation";
import PatientModal from "../../components/feature/Patient/PatientModal";
import PatientAppointmentModal from "../../components/feature/Patient/PatientAppointModal";
import {useNavigate} from "react-router-dom";
import {userVar} from "../../store";
import {toast} from "react-toastify";

const Patient = () => {
    const navigate = useNavigate();
    const user = useReactiveVar(userVar);
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const [searchTerm, setSearchTerm] = useState('');
    const [searchModal, setSearchModal] = useState(false);
    const [sortType, setSortType] = useState('asc');
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);
    const [selectedId, setSelectedId] = useState(null);
    const [patientModal, setPatientModal] = useState(false);
    const [patientAppointmentModal, setPatientAppointmentModal] = useState(false);

    const { data, loading } = useQuery(SEE_PATIENTS, {
        variables: {
            searchTerm: searchTerm,
            searchStartDate: startDate,
            searchEndDate: endDate,
            orderBy: sortType,
            take: take,
            cursor: cursor,
        },
        fetchPolicy: 'network-only'
    });

    const handleSort = useCallback(() => {
        if (sortType === 'desc') {
            setSortType('asc');
        } else {
            setSortType('desc');
        }
    }, [sortType]);

    const onClickPage = useCallback(page => { // 페이지
        setPage(page);
        if (page === 1) {
            setCursor(0);
        } else {
            setCursor((page - 1) * take);
        }
    }, [take]);

    const onChangeDate = useCallback(dates => { // 달력 onChange
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
    }, []);

    useEffect(() => {
        if (searchTerm.length > 0) {
            setCursor(0);
            setPage(1);
        }
    }, [searchTerm]);

    useLayoutEffect(() => {
        if (!user.user_rankPermission.patient) {
            navigate('/home');
            toast.error('접근 권한이 없습니다.');
        }
    }, [user]);

    return (
        <>
            <PageHeader
                title='환자정보'
                guideLine='환자 > 환자 목록'
                tabButton={['운영 스케줄', '특별 일정']}>
                <FilterBox>
                    <StyledRangeCalendar
                        DEFAULT
                        title='방문일 :'
                        id='patient-date-picker'
                        titleWidth={68}
                        height={24}
                        border='none'
                        borderRadius={0}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={onChangeDate}
                    />
                    <SortBox>
                        <StyledIconButton // 리프레쉬
                            REFRESH
                            onClick={() => setSearchTerm('')}
                        />
                        <StyledIconButton // 검색
                            SEARCH
                            margin='0 12px'
                            onClick={() => setSearchModal(true)}
                        />
                        <StyledIconButton // 리스트 보기
                            SORT
                            onClick={handleSort}
                        />
                    </SortBox>
                </FilterBox>
            </PageHeader>

            <PatientList>
                {loading
                    ? <Loader />
                    : data?.seePatients?.patientList?.map((list, index) => (
                        <PatientTableRow
                            key={`${index}-${list.pati_id}-patient-list`}
                            list={list}
                            setSelectedId={setSelectedId}
                            setPatientModal={setPatientModal}
                            setPatientAppointmentModal={setPatientAppointmentModal}
                        />
                    ))
                }
            </PatientList>
            <PageNation
                totalLength={data?.seePatients?.totalLength}
                take={take}
                page={page}
                setTake={setTake}
                onClickPage={onClickPage}
                takeOption={[10, 20, 30]}
            />

            <SearchModal // 검색하기 모달
                searchModal={searchModal}
                setSearchModal={setSearchModal}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />

            <PatientModal
                patientModal={patientModal}
                setPatientModal={setPatientModal}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
            />

            <PatientAppointmentModal
                selectedId={selectedId}
                patientAppointmentModal={patientAppointmentModal}
                setPatientAppointmentModal={setPatientAppointmentModal}
            />
        </>
    )
}

export default Patient;
