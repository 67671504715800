import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
import {getCookie} from "../utils/cookie";
import ApolloLinkTimeout from 'apollo-link-timeout';
import { toast } from 'react-toastify';
import {onError} from "@apollo/client/link/error";

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
                // console.info(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            }
        );
    if (networkError) {
        // toast.error('네트워크 접속이 불가합니다.\n관리자에게 문의해주세요.');
        // console.info(`[Network error]: ${networkError}`);
    }
});

const timeoutLink = new ApolloLinkTimeout(30000);

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_SERVER_URL,
    headers: {
        Authorization: `Bearer ${getCookie('mediplatform_token')}`,
        'Apollo-Require-Preflight': 'true'
    },
});

const link = ApolloLink.from([
    errorLink,
    timeoutLink,
    uploadLink
])

export default new ApolloClient({
    cache: new InMemoryCache(),
    link: link,
});

